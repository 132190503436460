import React, { Component } from "react";
import {
  decodeComputedString,
  isNullOrUndefined,
  parseProxyUrl,
  toLocaleDate
} from "../../App/utils";

import {
  WidgetCloseButton,
  Button,
  Input,
  Label,
  Fieldset,
  LayerListFormItemGroup,
  LayerListFormItem,
  LayerListButton,
  LayerListFormItemContainer,
  ButtonLabel,
  Heading3,
  P
} from "@agBoxUiKit/core/components";

import {
  WidgetWrapper,
  WidgetContainer,
  WidgetPanelWrapper,
  WidgetPanelContainer,
  WidgetLoaderWrapper,
  WidgetLoaderContainer,
  WidgetHeading
} from "@agBoxUiKit/core/layout";
import { Loader, Icon } from "@agBoxUiKit/core";
import { defaultTheme } from "@agBoxUiKit";
import {
  CUSTOM_IMAGERY_LAYER_TYPE,
  DEFAULT_BASEMAP,
  LABEL_ON,
  LABEL_OFF
} from "@agBoxSource/constants";
import { Accordion } from "../../Components";
import moment from "moment";
import {
  LegendItem,
  LegendItemImage,
  LegendItemLabel,
  LegendStepColor,
  LegendWrapper,
  LegendItemImageWrapper
} from "@agBoxUiKit/plugin/components";

class LayerControllerWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPanel: false,
      legendPanelsOpen: []
    };
  }

  componentDidMount() {
    this.loadImageryDates();
  }

  componentDidUpdate(prevProps) {
    const { propId, selectedOrg, removeImageryTileLayers } = this.props;
    if (
      prevProps.propId !== propId ||
      (!prevProps.selectedOrg && selectedOrg)
    ) {
      if (prevProps.propId) removeImageryTileLayers(prevProps.propId);
      this.loadImageryDates();
    }
  }

  loadImageryDates = async () => {
    const { propId, selectedOrg, loadImageryDates } = this.props;
    if (!propId || !selectedOrg) return;
    loadImageryDates(selectedOrg.orgId, propId);
  };

  handleTogglePanel = () => {
    const { openWidget, closeWidget } = this.props;
    if (this.showPanel()) closeWidget();
    else openWidget("layers");
  };

  showPanel = () => {
    const { activeWidget } = this.props;
    return activeWidget === "layers";
  };

  communityLayerAccordion = () => {
    const { selectedPropertyGroup, selectedProperty, labels } = this.props;
    const communityLayers = this.communityLayersList();
    if (
      (!selectedPropertyGroup && !selectedProperty) ||
      communityLayers.length === 0
    ) {
      return null;
    }
    return {
      headerLabel: labels["COMMUNITY_LAYERS_ACCORDION_TITLE_LABEL"],
      list: communityLayers,
      bodyContent: this.layerListContent,
      key: this.layerKey
    };
  };

  layerKey = (item) =>
    item
      ? !isNullOrUndefined(item.id)
        ? item.id
        : `${item.title}-layer-item`
      : "layer-item";

  layerListItemTitle = (title, hasError) =>
    hasError
      ? `${title} (${this.props.labels["LAYER_FAILED_TO_LOAD_LABEL"]})`
      : title;

  layerIsVisible = (item) => {
    const layer = this.findLayer(item);
    return layer ? layer.visible : false;
  };

  legendIsActive = (id) => {
    const { legendPanelsOpen } = this.state;
    return legendPanelsOpen.includes(id);
  };

  toggleLegendPanelOpen = (e) => {
    const { legendPanelsOpen } = this.state;
    const id = e.currentTarget.dataset.id;
    this.setState({
      legendPanelsOpen: this.legendIsActive(id)
        ? legendPanelsOpen.filter((item) => item !== id)
        : [...legendPanelsOpen, id]
    });
  };

  getLegendImage = ({ icon, label, color }) => {
    const {
      labels: { LEGEND_ITEM_LABEL }
    } = this.props;
    const legendLabel = decodeComputedString(LEGEND_ITEM_LABEL, {
      label
    });
    let image = null;
    if (icon) {
      image = <LegendItemImage src={parseProxyUrl(icon)} alt={legendLabel} />;
    } else if (color)
      image = <LegendStepColor color={color} title={legendLabel} />;
    return <LegendItemImageWrapper>{image}</LegendItemImageWrapper>;
  };

  getLayerLegendContent = (layerItem) => {
    const { legend, id } = layerItem;
    const {
      labels: { BLOCKS_GRADIENT_LEGEND_TITLE }
    } = this.props;
    if (!legend || !legend.length) return null;
    return (
      <LegendWrapper id={`legend-panel-${id}`}>
        <Heading3>{BLOCKS_GRADIENT_LEGEND_TITLE}</Heading3>
        {legend.map((legendItem, i) => (
          <LegendItem key={legendItem.label || `legend-item-${id}-${i}`}>
            {this.getLegendImage(legendItem)}
            {legendItem.label && (
              <LegendItemLabel>{legendItem.label}</LegendItemLabel>
            )}
          </LegendItem>
        ))}
      </LegendWrapper>
    );
  };

  getLegendButton = (item) => {
    const { legend, id, title } = item;
    if (!legend || !legend.length) return null;
    const {
      labels: { LEGEND_EXPAND_LABEL, LEGEND_CLOSE_LABEL }
    } = this.props;
    const hasError = this.layerHasError(item);
    const visible = this.layerIsVisible(item);
    const legendIsOpen = this.legendIsActive(id) && visible;
    const legendButtonColor = legendIsOpen
      ? defaultTheme.agBlue
      : defaultTheme.agGray;
    const legendLabel = decodeComputedString(
      legendIsOpen ? LEGEND_CLOSE_LABEL : LEGEND_EXPAND_LABEL,
      { title }
    );
    return (
      <LayerListButton
        disabled={!visible || hasError}
        id={`legend-panel-${id}-controller`}
        aria-controls={`legend-panel-${id}`}
        onClick={this.toggleLegendPanelOpen}
        data-id={id}
        aria-expanded={legendIsOpen ? "true" : "false"}
        title={legendLabel}
      >
        <Icon
          type="layer-legend"
          iconHeight="1.5em"
          iconWidth="1.5em"
          iconColor={legendButtonColor}
        />
        <ButtonLabel type="sr-only">{legendLabel}</ButtonLabel>
      </LayerListButton>
    );
  };

  getAllGroupLayersLabelsVisible = (groupLayer) => {
    const { items = [] } = groupLayer.layers;
    return items.every((subLayer) => {
      if (subLayer.type === "group") {
        return subLayer.layers.items.every(
          (featureLayer) => featureLayer.labelsVisible
        );
      }
      return subLayer.labelsVisible;
    });
  };

  getAllGroupLayersLabelsInvisible = (groupLayer) => {
    const { items = [] } = groupLayer.layers;
    return items.every((subLayer) => {
      if (subLayer.type === "group") {
        return subLayer.layers.items.every(
          (featureLayer) => !featureLayer.labelsVisible
        );
      }
      return !subLayer.labelsVisible;
    });
  };

  layerLabelIsVisible = (item) => {
    const layer = this.findLayer(item);
    return layer?.labelsVisible;
  };

  hasShowLabelsProperty = (item) => {
    if (item.hasOwnProperty("showLabels") && item.showLabels === true) {
      return true;
    }
    return false;
  };

  layerListContent = (item) => {
    const { id, title, toggleLabels } = item;
    const hasError = this.layerHasError(item);
    const visible = this.layerIsVisible(item);
    const legendIsOpen = this.legendIsActive(id) && visible;
    const {
      labels: { SHOW_LABELS_LABEL, HIDE_LABELS_LABEL }
    } = this.props;

    if (item.subLayers && item.subLayers.length > 0) {
      return (
        <LayerListFormItemGroup>
          {item.subLayers.map((sublayer) => {
            const { title: subLayerTitle, id: subLayerId } = sublayer;
            const subLayerVisible = this.sublayerIsVisible(item, subLayerId);

            return (
              <LayerListFormItem appearsdisabled={hasError}>
                <Label htmlFor={subLayerId}>
                  <LayerListFormItemContainer
                    appearsdisabled={!subLayerVisible || hasError}
                    disabled={hasError}
                  >
                    {this.layerListItemTitle(subLayerTitle, hasError)}
                  </LayerListFormItemContainer>
                  <LayerListFormItemContainer disabled={hasError}>
                    {this.getLegendButton(item)}
                    <Input
                      type={"checkbox"}
                      checked={subLayerVisible}
                      id={subLayerId}
                      onChange={() =>
                        this.handleToggleSublayerVisibility(item, subLayerId)
                      }
                      disabled={hasError}
                    />
                  </LayerListFormItemContainer>
                </Label>
                {legendIsOpen && this.getLayerLegendContent(item)}
              </LayerListFormItem>
            );
          })}
        </LayerListFormItemGroup>
      );
    }

    return (
      <LayerListFormItem appearsdisabled={hasError}>
        <Label htmlFor={id}>
          <LayerListFormItemContainer
            appearsdisabled={!visible || hasError}
            disabled={hasError}
          >
            {this.layerListItemTitle(title, hasError)}
          </LayerListFormItemContainer>
          <LayerListFormItemContainer disabled={hasError}>
            {toggleLabels && (
              <LayerListButton
                onClick={() => this.handleToggleLayerLabels(item)}
                disabled={!visible || hasError}
              >
                Aa
              </LayerListButton>
            )}
            {this.getLegendButton(item)}
            {this.hasShowLabelsProperty(item) && (
              <LayerListButton
                type="button"
                title={decodeComputedString(
                  this.layerLabelIsVisible(item)
                    ? HIDE_LABELS_LABEL
                    : SHOW_LABELS_LABEL,
                  { title }
                )}
                onClick={() => this.handleToggleLayerLabels(item)}
                disabled={!visible || hasError}
              >
                <Icon
                  type={this.layerLabelIsVisible(item) ? LABEL_ON : LABEL_OFF}
                  data-name={"Icon"}
                  iconColor={defaultTheme.agBlack}
                  iconHeight={"1.5em"}
                  iconWidth={"1.5em"}
                />
              </LayerListButton>
            )}
            <Input
              type={"checkbox"}
              checked={this.layerIsVisible(item)}
              id={id}
              onChange={() => this.handleToggleLayerVisibility(item)}
              disabled={hasError}
            />
          </LayerListFormItemContainer>
        </Label>
        {legendIsOpen && this.getLayerLegendContent(item)}
      </LayerListFormItem>
    );
  };

  layerHasError = (item) => {
    const layer = this.findLayer(item);
    if (!layer) return true;
    const { loadStatus } = layer;
    return loadStatus === "failed";
  };

  handleToggleLayerLabels = (item) => {
    const layer = this.findLayer(item);
    if (!layer) return;
    layer.labelsVisible = !layer.labelsVisible;
    this.forceUpdate();
  };

  handleToggleLayerVisibility = (layerItem) => {
    if (!layerItem) return;
    const layer = this.findLayer(layerItem);
    layer.visible = !layer.visible;
    this.forceUpdate();
  };

  handleToggleSublayerVisibility = (layerItem, subLayerId) => {
    if (!layerItem || !subLayerId) return;

    const layer = this.findLayer(layerItem);
    if (!layer.visible) layer.visible = true;

    const { showLabels, toggleLabels, subLayers } = layerItem;

    const subLayer = subLayers
      ? layer.sublayers.items.find((subLayer) => subLayer.id === subLayerId)
      : null;

    subLayer.visible = !subLayer.visible;
    if (showLabels && !toggleLabels && !layer.labelsVisible) {
      layer.labelsVisible = true;
    }
    this.forceUpdate();
  };

  findLayer = (layerItem) => {
    const { webMap } = this.props;
    if (!webMap) return false;
    const { items } = webMap.layers;
    const { type, url, id } = layerItem;
    const matchingLayer = items.find((layer) => {
      if (type === CUSTOM_IMAGERY_LAYER_TYPE)
        return layer.title && layer.title.includes(id);
      return (
        layer.url === url ||
        layer.urlTemplate === url ||
        (!isNullOrUndefined(layer.layerId) &&
          `${layer.url}/${layer.layerId}` === url)
      );
    });
    return matchingLayer;
  };

  communityLayersList = () => {
    const { selectedPropertyGroup, selectedProperty, selectedOrg } = this.props;

    if (!selectedOrg) return [];
    const { preferences } = selectedOrg;
    if (!preferences) return [];
    const { layers } = preferences;
    if (!layers || layers.length === 0) return [];

    const selectedRole =
      selectedProperty !== false
        ? selectedProperty.roleId
        : selectedPropertyGroup !== false
        ? selectedPropertyGroup.roleId
        : false;

    return layers
      .filter((layer) => layer.type && layer.type === "community")
      .filter((layer) => {
        if (selectedRole === false || (layer.roles && layer.roles.length === 0))
          return true;

        return layer.roles.includes(selectedRole);
      });
  };

  getAccordions = () => {
    const customImagery = this.customImageryAccordion();
    const communityLayers = this.communityLayerAccordion();
    const basemap = this.baseMapAccordion();
    if (!customImagery && !communityLayers && !basemap) return null;
    return {
      customImagery,
      communityLayers,
      basemap
    };
  };

  basemapItemKey = (item) => {
    return `${item.key}-basemap-list-item`;
  };

  baseMapAccordion = () => {
    const basemapOptions = this.getBaseMapOptions();
    if (basemapOptions.length < 2) return null;
    return {
      headerLabel: "Basemap",
      list: basemapOptions,
      bodyContent: this.baseMapBodyContent,
      key: this.basemapItemKey
    };
  };

  getBaseMapOptions = () => {
    const { selectedOrg } = this.props;
    if (!selectedOrg || !selectedOrg.preferences) return DEFAULT_BASEMAP;
    const { layers } = selectedOrg.preferences;
    const basemaps = [
      ...(layers
        ? layers.filter((layer) => layer.type && layer.type === "baseMap")
        : [])
    ].reduce((result, option) => {
      if (result.find((item) => item.id === option.id)) return result;
      return [...result, option];
    }, []);
    return basemaps.length ? basemaps : DEFAULT_BASEMAP;
  };

  baseMapBodyContent = (item) => (
    <Fieldset>{this.baseMapButtons(item)}</Fieldset>
  );

  baseMapButtons = (item) => {
    return (
      <Label htmlFor={`${item.id}-basemap-option`} key={item.id}>
        <Input
          type="radio"
          name="basemap-layer-controller"
          id={`${item.id}-basemap-option`}
          checked={this.basemapIsChecked(item) ? true : false}
          onChange={(e) => this.handleUpdateBasemapOption(item)}
          disabled={this.webMapLoading()}
        />
        <span>{item.title}</span>
      </Label>
    );
  };

  handleUpdateBasemapOption = (option) => {
    const isChecked = this.basemapIsChecked(option);
    if (isChecked) return;
    const { setBasemap } = this.props;
    setBasemap(option);
    this.forceUpdate();
  };

  webMapLoading = () => {
    const { webMapLoading } = this.props;
    return webMapLoading;
  };

  activeBasemap = () => {
    const { basemapId } = this.props;
    return basemapId;
  };

  basemapIsChecked = (basemap) => {
    const activeBasemap = this.activeBasemap();
    return basemap.id === activeBasemap;
  };

  customImageryLayerInfo = () => {
    const { selectedOrg } = this.props;
    if (
      !selectedOrg ||
      !selectedOrg.preferences ||
      !selectedOrg.preferences.layers
    )
      return null;
    const { layers } = selectedOrg.preferences;
    const customImageryLayer = layers.find(
      (layer) => layer?.type === CUSTOM_IMAGERY_LAYER_TYPE
    );
    return customImageryLayer ? customImageryLayer : null;
  };

  customImageryDates = () => {
    const { getImageryDatesForProperty, propId } = this.props;
    if (!propId) return [];
    const imageryResults = getImageryDatesForProperty(propId);
    return imageryResults && imageryResults.items
      ? imageryResults.items.map(({ surveyedDate }) => ({
          id: surveyedDate,
          title: toLocaleDate(surveyedDate),
          type: CUSTOM_IMAGERY_LAYER_TYPE
        }))
      : [];
  };

  imageryBodyContent = (item) => {
    const {
      propId,
      getImageryLoadingForProperty,
      labels: { LOADING_CUSTOM_IMAGERY_LABEL, NO_CUSTOM_IMAGERY_LABEL }
    } = this.props;
    if (!propId) return null;
    const loading = getImageryLoadingForProperty(propId);
    if (loading) return <Loader loadingText={LOADING_CUSTOM_IMAGERY_LABEL} />;
    else {
      if (item.title === NO_CUSTOM_IMAGERY_LABEL)
        return <P>{NO_CUSTOM_IMAGERY_LABEL}</P>;
      else return this.layerListContent(item);
    }
  };

  customImageryAccordion = () => {
    const { propId } = this.props;
    const customImageryLayerInfo = this.customImageryLayerInfo();
    if (!customImageryLayerInfo || !propId) return null;
    const { title } = customImageryLayerInfo;
    const {
      labels: { CUSTOM_IMAGERY_TITLE_LABEL, NO_CUSTOM_IMAGERY_LABEL }
    } = this.props;
    const dates = this.customImageryDates();
    return {
      headerLabel: title ? title : CUSTOM_IMAGERY_TITLE_LABEL,
      list:
        dates && dates.length
          ? dates
          : [
              {
                title: NO_CUSTOM_IMAGERY_LABEL
              }
            ],
      bodyContent: this.imageryBodyContent,
      key: this.layerKey
    };
  };

  sublayerIsVisible = (item, subLayerId) => {
    const layer = this.findLayer(item);
    if (!layer) return false;

    const subLayer = layer.sublayers
      ? layer.sublayers.items.find((subLayer) => subLayer.id === subLayerId)
      : null;
    return subLayer ? subLayer.visible : false;
  };

  render() {
    const {
      labels: { LAYERS_WIDGET_HEADING_LABEL }
    } = this.props;
    if (!this.getAccordions()) return null;
    return (
      <WidgetWrapper
        data-name={"WidgetWrapper"}
        type="layers"
        disabled={this.props.disabled}
      >
        <WidgetContainer data-name={"WidgetContainer"}>
          <Button
            onClick={this.handleTogglePanel}
            title="Layers"
            type="button"
            disabled={this.props.disabled}
          >
            <Icon
              type="layer-list"
              data-name={"Icon"}
              iconColor={defaultTheme.agWhite}
              iconHeight={"2em"}
              iconWidth={"2em"}
              tooltip="Layers"
            />
          </Button>

          {this.showPanel() ? (
            <WidgetPanelWrapper
              type="layer-controller"
              data-name={"WidgetPanelWrapper"}
            >
              {this.webMapLoading() ? (
                <WidgetLoaderWrapper data-name={"WidgetLoaderWrapper"}>
                  <WidgetLoaderContainer data-name={"WidgetLoaderContainer"}>
                    <Loader />
                  </WidgetLoaderContainer>
                </WidgetLoaderWrapper>
              ) : null}
              <WidgetPanelContainer
                type="layers"
                data-name={"WidgetPanelContainer"}
              >
                <WidgetHeading>{LAYERS_WIDGET_HEADING_LABEL}</WidgetHeading>
                <WidgetCloseButton
                  type="button"
                  onClick={this.handleTogglePanel}
                  data-name={"WidgetCloseButton"}
                  title="Close widget"
                >
                  <Icon
                    type="close"
                    data-name={"Icon"}
                    iconColor={defaultTheme.agBlack}
                    bgHeight={"1.8em"}
                    bgWidth={"1.8em"}
                    iconHeight={"0.8em"}
                    iconWidth={"0.8em"}
                  />
                </WidgetCloseButton>
                <hr />

                <Accordion accordions={this.getAccordions()} />
              </WidgetPanelContainer>
            </WidgetPanelWrapper>
          ) : null}
        </WidgetContainer>
      </WidgetWrapper>
    );
  }
}

export default LayerControllerWidget;
