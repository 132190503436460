import React, { Component, Suspense } from "react";
import { NotificationManager } from "react-notifications";
import {
  roundNumber,
  isValidJSON,
  escapeJSONNewLine,
  decodeComputedString,
  getUrlSearchParamByKey,
  setMultipleUrlSearchParamByKeys,
  maxTileSize,
  toLocale,
  sentenceCase,
  getApplicableItems,
  getFormattedDefaultValue,
  getExplicitValidationRulesForField,
  getSoftValidationRulesForField,
  isValid,
  getSession,
  storeSession,
  projectGeometries,
  convertQueryToFormData,
  isNullOrUndefined,
  sortMethod,
  hexToRgba
} from "../App/utils";
import {
  TITLE_FIELD,
  PLUGIN_SIZE_REG,
  POINTER_MOVE_EVENT,
  CLICK_EVENT,
  DEFAULT_FEATURE_HIGHLIGHT,
  PLUGIN_LEFT,
  GEOMETRY_TYPE_POINT,
  UNIT_METERS,
  TEMP_LAYER_PREFIX,
  GEOMETRY_TYPE_LINE,
  GEOMETRY_TYPE_POLY,
  DEFAULT_HIGHLIGHT_SYMBOL,
  CROP_SELECTION_SYMBOL,
  CROP_MERGE_SELECTED_SYMBOL,
  CROP_POINT_EVENT_SELECTION_SYMBOL,
  CROP_BLOCK_SELECTABLE_SYMBOL,
  ABORT_ERROR_NAME,
  FEATURE_HIGHLIGHT_EXCLUDEDEFFECT,
  FEATURE_HIGHLIGHT_BASEPOLYEFFECT,
  FEATURE_HIGHLIGHT_NON_BASEPOLYEFFECT,
  SUPPORT_EMAIL,
  DEFAULT_UNIT_HECTARES,
  URL_DELIMITER_ORG,
  URL_DELIMITER_PROP,
  URL_DELIMITER_PROP_GROUP,
  QUERY_EXPRESSION,
  DELETEDDATE_QUERY_EXPRESSION,
  FEATURE_TEXT_SEARCH,
  DEFAULT_PAGINATION_OPTIONS,
  FEATURE_DETAILS_URL_DELIMITER,
  PLUGIN_SIZE_FULL,
  AGBOX_API_URL,
  AGBOX_API_KEY,
  DATE_FILTER_TYPE_SINGLE,
  DATE_FILTER_TYPE_RANGE,
  ALL_PAGINATION_OPTION,
  PROPERTY_DISPLAY_SUFFIX,
  SEARCH_TYPE_URL_PARAM,
  SEARCH_TEXT_URL_PARAM,
  PAGE_PARAM,
  LIMIT_PARAM,
  UNIT_HECTARES,
  WRITE_PERMISSION,
  FULL_PERMISSION,
  CUSTOM_IMAGERY_LAYER_TYPE,
  CUSTOM_DATA_FIELD,
  EXPIRY_DATE_FIELD,
  DELETED_DATE_FIELD,
  GEOMETRY_SERVICE_URL,
  GENERIC_LABEL_SYMBOL,
  LAYER_PERMISSIONS_ERROR_CODE,
  MERGE_FEATURES_URL_DELIMITER,
  SPLIT_FEATURES_URL_DELIMITER,
  FEATURES_OVERVIEW_URL_DELIMITER,
  CATEGORY_FIELD,
  SET_WORKFLOW_STATE_MESSAGE_TYPE,
  GET_WORKFLOW_STATE_MESSAGE_TYPE,
  DEFAULT_PAGINATION_STRING,
  DEFAULT_PAGINATION_VALUE
} from "../constants";
import {
  PluginWrapper,
  PackagePluginsWrapper
} from "@agBoxUiKit/plugin/layout";
import moment from "moment";
import shortid from "shortid";
import { defaultTheme } from "@agBoxUiKit";
import {
  NotificationErrorLink,
  NotificationMessageText
} from "@agBoxUiKit/core/components";
import { Loader, Modal } from "@agBoxUiKit/core";
import PanelControlButton from "@agBoxUiKit/plugin/components/PanelControlButton/PanelControlButton";
import agBoxApiRequests from "../apis/agbox/requests";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { labelPoints } from "@arcgis/core/rest/geometryService";
import {
  buffer,
  intersect,
  intersects,
  planarArea,
  planarLength,
  simplify,
  union
} from "@arcgis/core/geometry/geometryEngine";
import Polygon from "@arcgis/core/geometry/Polygon";
import { debounce, isAbortError } from "@arcgis/core/core/promiseUtils";
import { preferences } from "../mockData/mockData";
import Polyline from "@arcgis/core/geometry/Polyline";
import Point from "@arcgis/core/geometry/Point";
const requests = agBoxApiRequests(AGBOX_API_URL, AGBOX_API_KEY);
/** This renders all workflows when they are selected, and contains all common workflow methods */

export default class Workflows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowPanelOpen: true,
      interactiveLayers: [],
      mapViewMouseMoveHandler: null,
      mapViewMouseClickHandler: null,
      printLayerViewHandler: null,
      featureHighlights: {},
      pluginSize: PLUGIN_SIZE_REG,
      showModal: true,
      hasWarningNotification: false,
      hasInfoNotification: false,
      highlights3d: {},
      featureError: null,
      isSearchingFeatures: false,
      workflowReady: false,
      onWorkflowReady: false
    };
    this.promise = null;
    this.controller = new AbortController();
    this.cacheTimeout = null;
  }

  componentWillUnmount() {
    this.controller.abort();
    const { mapViewMouseClickHandler, mapViewMouseMoveHandler } = this.state;
    if (mapViewMouseClickHandler) mapViewMouseClickHandler.remove();
    if (mapViewMouseMoveHandler) mapViewMouseMoveHandler.remove();
    this.unselectWorkflow();
    this.removeMessageListener();
  }

  componentDidMount() {
    this.selectWorkflow();
    this.setupMessageListener();
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
    const { errorWorkflowPlugins } = this.props;
    errorWorkflowPlugins(error);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.workflowId !== this.props.workflowId &&
      this.props.workflowId
    ) {
      this.unselectWorkflow();
      this.selectWorkflow();
    }

    if (
      (!prevProps.selectedWorkflow && this.props.selectedWorkflow) ||
      (prevProps.selectedWorkflow &&
        this.props.selectedWorkflow &&
        prevProps.selectedWorkflow.workflowId !==
          this.props.selectedWorkflow.workflowId)
    ) {
      this.setupMapView();
    }
    if (
      !prevState.workflowReady &&
      this.state.workflowReady &&
      this.state.onWorkflowReady
    )
      this.onListViewReady(this.state.onWorkflowReady);
  }

  setupMessageListener = () => {
    window.addEventListener("message", this.handleMessage, false);
  };

  handleMessage = (event) => {
    const { updateWorkflowState, getWorkflowStateValue } = this.props;
    if (!event.origin.includes(window.location.origin))
      throw new Error(this.getLanguageLabel("ERROR_UNAUTHORIZED_MESSAGE"));
    else if (!event.data || !isValidJSON(event.data)) return;
    const messageData = JSON.parse(event.data);
    const { messageType, data, key } = messageData;
    if (messageType === SET_WORKFLOW_STATE_MESSAGE_TYPE) {
      updateWorkflowState(data);
    } else if (messageType === GET_WORKFLOW_STATE_MESSAGE_TYPE) {
      getWorkflowStateValue(key);
    }
  };

  removeMessageListener = () => {
    window.removeEventListener("message", this.handleMessage);
  };
  /**
   * Called in render. Checks if a workflow is selected in redux state.
   * If a workflow is in the URL but has not been selected, sends off the action to select the workflow.
   * If selected, returns the selected workflow object
   * @public
   */
  hasSelectedWorkflow = () => {
    const { selectedWorkflow } = this.props;

    return selectedWorkflow ? true : false;
  };

  /**
   * Sets pointer move and click events on the mapView and saves them to state
   * @public
   */
  setupMapView = () => {
    let { mapViewMouseClickHandler, mapViewMouseMoveHandler } = this.state;
    const { mapView } = this.props;
    const debouncedMouseMove = debounce((event) => {
      return this.handleMouseMoveEvent(event);
    });
    if (!mapViewMouseClickHandler) {
      mapViewMouseMoveHandler = mapView.on(POINTER_MOVE_EVENT, (event) => {
        debouncedMouseMove(event).catch(function (err) {
          if (!isAbortError(err)) {
            throw err;
          }
        });
      });
    }
    if (!mapViewMouseClickHandler) {
      mapViewMouseClickHandler = mapView.on(
        CLICK_EVENT,
        this.handleMouseClickEvent
      );
    }
    this.setState({
      mapViewMouseMoveHandler,
      mapViewMouseClickHandler
    });
    const { workflowState } = this.props;
    if (workflowState) {
      const { featureHighlight } = workflowState;
      mapView.highlightOptions = featureHighlight
        ? featureHighlight
        : DEFAULT_FEATURE_HIGHLIGHT;
    }
  };

  /**
   * Called on pointer move on mapView. Checks for features to highlight and if any sends these to highlight method. If an event is already in progress, cancels this and replaces with current event.
   * @public
   */
  handleMouseMoveEvent = (event) => {
    if (
      (event.buttons > 0 && event.native.pressure > 0) ||
      !this.state.interactiveLayers ||
      Object.keys(this.state.interactiveLayers).length === 0
    )
      return;
    const { mapView } = this.props;
    return mapView.hitTest(event).then((hit) => {
      const { featureHighlights } = this.state;
      const { results } = hit;
      if (results.length === 0 && featureHighlights) {
        this.handleRemoveHighlights();
        return this.setState({
          featureHighlights: {}
        });
      }
      return this.handleUpdateFeatureHighlights(results);
    });
  };

  /**
   * Called on click of mapView. Checks for features in click target and calls click handler method
   * @public
   */
  handleMouseClickEvent = async (event) => {
    const { interactiveLayers } = this.state;
    if (Object.keys(interactiveLayers).length === 0) return;
    const { mapView } = this.props;
    const { results } = await mapView.hitTest(event);
    const features = results.filter((feature) => {
      return (
        feature.layer &&
        feature.layer.title &&
        interactiveLayers[feature.layer.title]
      );
    });
    this.handleFeaturesClicked(features);
  };

  /**
   * Checks passed features against interactive layers in state. If any of the features belong to an interactive layer, calls the interactive layer's onClick method, passing in the feature
   * @param {array} features
   * @public
   */
  handleFeaturesClicked = (features) => {
    const { interactiveLayers } = this.state;
    features.forEach((feature, i) => {
      const interactiveLayer = interactiveLayers[feature.layer.title];
      const { onClick, returnTopFeatureOnly } = interactiveLayer;
      if (onClick && (!returnTopFeatureOnly || i === features.length - 1))
        onClick(feature.graphic);
    });
  };

  /**
   * Checks features against interactiveLayers in state, and if the features match the interactiveLayers, updates the layerView effect to highlight the features and dim the non-highlighted features. If not, removes highlight.
   * If feature belongs to a GraphicsLayer, manually handles highlight by changing symbol.
   * @param {array} features
   * @public
   */
  handleUpdateFeatureHighlights = async (features) => {
    const { mapView, getLayerName, objectIdField, has3D } = this.props;
    const { interactiveLayers, featureHighlights } = this.state;
    const interactiveFeatures = features.filter((feature) => {
      if (feature.layer === null) return false;

      const interactiveLayerTitles = Object.keys(interactiveLayers);
      return interactiveLayerTitles.indexOf(feature.layer.title) !== -1;
    });

    if (interactiveFeatures.length === 0) {
      await this.handleRemoveHighlights();
      return this.setState({
        featureHighlights: {}
      });
    }

    const keyedLayers = interactiveFeatures.reduce(
      (h, obj) =>
        Object.assign(h, {
          [obj.layer.title]: (h[obj.layer.title] || []).concat(obj)
        }),
      {}
    );

    Object.keys(keyedLayers).map(async (key) => {
      let items = keyedLayers[key];
      const { layer } = items[0];
      const layerView = await mapView.whenLayerView(layer);
      if (!layerView) return;

      if (layer && layer.type !== "graphics") {
        if (has3D) {
          this.handle3DHighlightItems(items, layerView);
        } else {
          const where = items
            .map((item) => {
              const preferredObjectIdField = layer.objectIdField
                ? layer.objectIdField
                : objectIdField;
              return `${preferredObjectIdField} = ${item.attributes[preferredObjectIdField]}`;
            })
            .join(" OR ");
          if (
            layerView.featureEffect &&
            where === layerView.featureEffect.filter.where
          )
            return;
          layerView.featureEffect = {
            includedEffect:
              layer.title === getLayerName("basePoly")
                ? FEATURE_HIGHLIGHT_BASEPOLYEFFECT
                : FEATURE_HIGHLIGHT_NON_BASEPOLYEFFECT,
            filter: {
              where
            },
            excludedLabelsVisible: layer.title.includes("base") ? false : true,
            excludedEffect: FEATURE_HIGHLIGHT_EXCLUDEDEFFECT
          };
        }
      } else {
        if (featureHighlights[key]) {
          await this.handleRemoveSingleLayerHighlights(featureHighlights[key]);
        }
        keyedLayers[key] = items.map((item) => {
          return this.highlightGraphicsLayerItem(item);
        });
      }
      return keyedLayers[key];
    });

    this.setState({ featureHighlights: keyedLayers });
  };

  handle3DHighlightItems = (items, layerView) => {
    const { highlights3d } = this.state;
    const {
      layer: { title }
    } = layerView;
    this.handleRemoveAll3dHighlights();
    const highlight = layerView.highlight(items);
    this.setState({
      highlights3d: {
        ...highlights3d,
        [title]: highlight
      }
    });
  };

  handleRemove3DHighlight = (layerView) => {
    const { highlights3d } = this.state;
    const {
      layer: { title }
    } = layerView;
    if (highlights3d[title] && highlights3d[title].remove)
      highlights3d[title].remove();
    this.setState({
      highlights3d: {
        ...highlights3d,
        [title]: null
      }
    });
  };

  handleRemoveAll3dHighlights = () => {
    const { highlights3d } = this.state;
    const newHighlights3d = Object.keys(highlights3d).reduce((result, key) => {
      const item = highlights3d[key];
      if (item && item.remove) item.remove();
      return {
        ...result,
        [key]: null
      };
    }, {});
    this.setState({
      highlights3d: newHighlights3d
    });
  };

  /**
   * Returns GraphicsLayer feature to its original symbology from highlight state
   * @param {object} feature
   * @param { object} originalSymbol
   * @public
   */
  removeHighlightFromGraphicsLayerItem = (feature, originalSymbol) => {
    feature.symbol = originalSymbol;
  };

  /**
   * Updates the graphic with the highlight symbol.
   * Returns an object with the graphic's original symbol, layer, attributes, and a remove highlight function
   * @param {object} feature
   * @public
   */
  highlightGraphicsLayerItem = (feature) => {
    const originalSymbol = feature.symbol;
    feature.symbol = DEFAULT_HIGHLIGHT_SYMBOL;
    return {
      layer: feature.layer,
      attributes: feature.attributes,
      symbol: originalSymbol,
      remove: () =>
        this.removeHighlightFromGraphicsLayerItem(feature, originalSymbol)
    };
  };

  /**
   * Updates the highlighted features' layers, removing each layer's highlight effect
   * @public
   */
  handleRemoveHighlights = () => {
    const { featureHighlights } = this.state;
    if (!featureHighlights) return;
    const { mapView, webMap, has3D } = this.props;
    Object.keys(featureHighlights).forEach(async (key) => {
      const items = featureHighlights[key];
      if (!items || items.length === 0) return;
      if (!items) delete featureHighlights[key];
      const { layer } = items[0];
      if (layer.type === "graphics") {
        items.forEach((item) => {
          if (item.remove) {
            item.remove();
          }
        });
      } else {
        const actualLayer = webMap.layers.items.find(
          (item) => item.title === layer.title
        );
        if (!actualLayer) return;
        const layerView = await mapView.whenLayerView(actualLayer);
        if (has3D) {
          this.handleRemove3DHighlight(layerView);
        } else layerView.featureEffect = null;
      }
    });
  };

  /**end mouse events methods */

  /**
   * Checks workflowState for plugins, and returns any loadablePlugins from props that match the name and containerPosition and are enabled.
   * This is called in render to determine which workflow view to render
   * @param {string} containerPosition
   * @public
   */
  getPluginsForContainer = (containerPosition) => {
    const { loadablePlugins } = this.props;
    if (!loadablePlugins) return [];
    return loadablePlugins.filter((plugin) => {
      if (!plugin.enabled || plugin.position !== containerPosition)
        return false;
      return true;
    });
  };

  /**
   * Returns workflowSize from props if set. If not set, returns PLUGIN_SIZE_REG
   * @public
   */
  getWorkflowSize = () => {
    const { workflowSize } = this.props;
    return workflowSize ? workflowSize : PLUGIN_SIZE_REG;
  };

  /**
   * Checks loadable plugins against workflowState plugins.
   * Returns whether any left plugins are available
   * @public
   */
  whenLeftPlugins = () => {
    return this.getPluginsForContainer(PLUGIN_LEFT).length ? true : false;
  };

  /**
   * Returns an HTML node for an error modal, including the message and a link to email support regarding the issue. Email content is provided to the mailto link, referencing the error message.
   * @param {string} message
   * @publichandleErrorPlugins
   */
  getErrorModalBody = (message) => {
    const {
      selectedOrganisation,
      selectedProperty,
      selectedWorkflow,
      selectedPropertyGroup,
      propId,
      groupId
    } = this.props;
    const messages = Array.isArray(message) ? message : [message];
    return (
      <div>
        <div>
          {messages.map((text) => (
            <p key={text}>{text}</p>
          ))}
          <br />
          <a
            href={`mailto:${this.supportEmail()}?subject=${this.encodeString(
              this.getLanguageLabel("AGBOX_ERROR_LABEL")
            )}&body=${this.encodeString(
              this.getLanguageLabel("AGBOX_HAS_FOLLOWING_ERROR_LABEL")
            )}:%0A${this.encodeString(
              `${this.getLanguageLabel("MESSAGE_LABEL")}: ${messages.join(
                ", "
              )} '${selectedWorkflow.workflowId}'`
            )}%0A%0A${
              selectedOrganisation
                ? this.encodeString(
                    `${this.getLanguageLabel("ORGANISATION_LABEL")}: ${
                      selectedOrganisation.title
                    }`
                  )
                : ""
            }%0A${
              selectedProperty
                ? this.encodeString(
                    `${this.getLanguageLabel("PROPERTY_LABEL")}: ${
                      selectedProperty.title
                    } (${propId})`
                  )
                : selectedPropertyGroup
                ? this.encodeString(
                    `${this.getLanguageLabel("PROPERTY_GROUP_LABEL")}: ${
                      selectedPropertyGroup.title
                    } (${groupId})`
                  )
                : ""
            }`}
          >
            {this.getLanguageLabel("ERRORS_CONTACT_ADMIN_LABEL")}
          </a>
        </div>
      </div>
    );
  };
  /**
   * Handles deselecting a workflow. Calls deselectWorkflow action.
   * @public
   */
  unselectWorkflow = () => {
    const { deselectWorkflow, abortRequest, resetVisibleLayers, mapView } =
      this.props;
    this.setState({
      showModal: false,
      isSearchingFeatures: false,
      workflowReady: false,
      onWorkflowReady: null
    });
    this.clearLabelGraphics();
    deselectWorkflow();
    abortRequest();
    resetVisibleLayers();
    if (mapView.graphics.items && mapView.graphics.items.length)
      mapView.graphics.removeAll();
  };

  closeErrorModal = () => {
    this.setState({
      showModal: false
    });
    this.unselectWorkflow();
  };

  /**
   * Checks for errors in plugins and returns an error modal
   * @public
   */
  handleErrorPlugins = () => {
    const { showModal } = this.state;
    const {
      selectedOrganisation,
      selectedProperty,
      selectedPropertyGroup,
      orgId,
      propId,
      groupId
    } = this.props;
    const propUrl = selectedProperty
      ? URL_DELIMITER_PROP
      : selectedPropertyGroup
      ? URL_DELIMITER_PROP_GROUP
      : false;
    const propertyId = propId ? propId : groupId ? groupId : false;
    const propTitle = selectedProperty
      ? selectedProperty.title
      : selectedPropertyGroup
      ? selectedPropertyGroup.title
      : false;

    const url = propUrl
      ? `/${URL_DELIMITER_ORG}/${orgId}/${propUrl}/${propertyId}`
      : `/${URL_DELIMITER_ORG}/${orgId}`;

    const title = propUrl
      ? "ERRORS_CHANGE_WORKFLOW_LABEL"
      : "ERRORS_BACK_BUTTON_LABEL";
    return (
      <Modal
        isOpen={showModal}
        title={this.getLanguageLabel("ERRORS_MODAL_HEADING_LABEL")}
        body={this.getErrorModalBody("Unable to open the selected workflow")}
        showCloseButton={false}
        primaryButtonTitle={this.getLanguageLabel(title)}
        primaryButtonAction={this.closeErrorModal}
        primaryButtonLink={{
          url,
          docTitle: propTitle ? propTitle : selectedOrganisation.title
        }}
      />
    );
  };
  /**
   * @public
   * @param {object} [layer] - the layer to query
   * @param {object} [query] - the query options
   * @param {object} [signal] - the signal
   * @param {boolean} [useFetch]  - whether to hit the url directly or use esri layer queries. Usually esri layer queries are used, because most workflows don't need extra functionality. However occasionally we want to hit the url directly because we want to use a query param that isn't a standard esri query option, for example orderByDistance. If we tried to use these non-standard params on the esri layer queries they would just be ignored by the layer query, so we have to hit the endpoint directly instead. Bear in mind that because the query is not being done through the esri feature layer, the results that are returned don't have some of the esri feature properties, such as clone.
   */

  fetchLayerFeatures = async (layer, query = {}, signal, useFetch = false) => {
    try {
      const {
        user: { token },
        mapView
      } = this.props;
      const { url, layerId } = layer;
      //if url is not set on the layer this means it is a client-side feature layer, so we have to use esri layer queries.
      //If useFetch is false, use the esri layer queries.
      if (!url || (!useFetch && isNullOrUndefined(query.returnCentroidsOnly)))
        return await layer.queryFeatures(query, {
          signal
        });
      const response = await fetch(`${url}/${layerId}/query`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
          "x-api-key": AGBOX_API_KEY
        },
        method: "POST",
        body: convertQueryToFormData(query, false, layer.geometryType),
        redirect: "follow",
        signal
      });
      const result = await response.json();
      return {
        ...result,
        features: result.features
          ? result.features.map((feature) => {
              //because the results don't come from the standard esri layer query, we have to set a couple of things the workflow might be expecting.
              feature.layer = layer;
              // here we set the geometry as esri standard format so the all the usual esri geometry properties are available.
              if (feature.geometry.rings) {
                feature.geometry = new Polygon({
                  rings: feature.geometry.rings,
                  spatialReference: mapView.spatialReference
                });
              } else if (feature.geometry.paths) {
                feature.geometry = new Polyline({
                  paths: feature.geometry.rings,
                  spatialReference: mapView.spatialReference
                });
              } else {
                feature.geometry = new Point({
                  ...feature.geometry,
                  spatialReference: mapView.spatialReference
                });
              }
              return feature;
            })
          : []
      };
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      if (e.name === "AbortError") return new Error(ABORT_ERROR_NAME);
    }
  };
  /**
   * @public
   * @param {object} [layer] - the layer to query
   * @param {object} [queryOptions] - the query options
   * @param {object} [signal] - the abort signal
   * @param {boolean} [useFetch] - whether to directly hit the layer url or not
   */

  paginateLargeQueries = async (
    layer,
    queryOptions,
    signal,
    useFetch = false
  ) => {
    //check how many features there are going to be with this query
    const count = await layer.queryFeatureCount(queryOptions, { signal });
    let batchesCount = 1;
    const size = maxTileSize(layer.geometryType, queryOptions);
    if (count > size) {
      batchesCount = count / size;
    }
    const features = [];
    //break into batches
    const batchSize = batchesCount < 10 ? batchesCount : 10;
    //loop through each batch, query the layer and then return all the results in an array
    for (let i = 0; i < batchesCount; i += batchSize) {
      const queries = [];
      for (let j = 0; j < batchSize; j++) {
        if (i + j >= batchesCount) break;
        let query = { ...queryOptions };
        query.start = (i + j) * size;
        query.num = size;
        queries.push(query);
      }

      const results = await Promise.allSettled(
        queries.map((query) =>
          this.fetchLayerFeatures(layer, query, signal, useFetch)
        )
      );

      for (const result of results) {
        if (result.status === "rejected") throw new Error(result.reason);
        if (result.status === "fulfilled" && result.value?.features) {
          features.push(...result.value.features);
        }
      }
    }
    return features;
  };

  getErrorMessage = (error, layerTitle) => {
    if (error.details && error.details.raw) {
      const { message, code } = JSON.parse(error.details.raw);
      if (code === LAYER_PERMISSIONS_ERROR_CODE && layerTitle)
        return this.getLanguageLabel("ERROR_LAYER_PERMISSIONS_READS_LABEL", {
          layerTitle
        });
      return message;
    }

    return error.message;
  };

  /**
   * If disableCache has not been set as true, sets this as true, cancels timeout if present.
   * Gets the layer from the webmap using the layerTitle param
   * Queries the layer using the queryOptions and signal params
   * Sets a timeout for resetting disableCache as false
   *
   * Returns features from queryResults
   * If error, shows notification
   *
   * @param {string} layerTitle
   * @param {object} queryOptions
   * @param {object} signal
   * @param {boolean} useFetch
   * @public
   */
  queryFeaturesByLayerTitle = async (
    layerTitle,
    queryOptions,
    signal,
    useFetch = false
  ) => {
    const { shouldDisableCache, disableCache, objectIdField } = this.props;
    try {
      if (!shouldDisableCache) {
        disableCache(true);
      }
      if (this.cacheTimeout) {
        clearTimeout(this.cacheTimeout);
        this.cacheTimeout = null;
      }
      if (!layerTitle) return [];
      const layer = this.getLayerByTitle(layerTitle);
      if (!layer) return [];
      const { outFields, returnGeometry } = queryOptions;
      const updatedOutfields = outFields
        ? outFields === "*" ||
          outFields === ["*"] ||
          outFields
            .map((outField) => outField.toLowerCase())
            .indexOf(objectIdField.toLowerCase()) !== -1
          ? outFields
          : [...outFields, objectIdField]
        : "*";
      const query = {
        ...queryOptions,
        returnGeometry: !isNullOrUndefined(returnGeometry)
          ? returnGeometry
          : true,
        outFields: updatedOutfields
      };
      const features = await this.queryLayerForFeatures(
        layer,
        query,
        signal,
        useFetch
      );
      return features;
    } catch (e) {
      if (isNullOrUndefined(e)) return;
      if (process.env.NODE_ENV === "development") console.log(e);
      if (e && e.name && e.name === ABORT_ERROR_NAME) {
        return ABORT_ERROR_NAME;
      }
      const { getLayerName } = this.props;

      this.errorNotification(
        this.getErrorMessage(e, getLayerName(layerTitle)),
        `${this.getLanguageLabel(
          "NOTIFICATION_ERROR_LABEL_QUERY_LAYER"
        )} '${getLayerName(layerTitle)}'`
      );
    } finally {
      this.cacheTimeout = setTimeout(() => {
        disableCache(false);
      }, 5000);
    }
  };
  /**
   * This method queries the layer for a feature and returns all results. It is used in feature details views usually. It handles errors.
   * @param {object} layerTitle - the layer title to query
   * @param {number} objectId - the objectId of the feature we want to query
   * @param {object} queryOptions - the other query options
   * @param {object} signal - the abort signal
   * @param {boolean} useFetch  - whether to hit the url directly or use built-in esri layer queries
   */

  queryFeatureDetails = async (
    layerTitle,
    objectId,
    queryOptions,
    signal,
    useFetch = false
  ) => {
    const { shouldDisableCache, disableCache, objectIdField } = this.props;
    try {
      //handles caching on the client side. This is quite old and potentially doesn't do anything anymore.
      if (!shouldDisableCache) {
        disableCache(true);
      }
      if (this.cacheTimeout) {
        clearTimeout(this.cacheTimeout);
        this.cacheTimeout = null;
      }
      // throw an error if params are not present
      if (!layerTitle || !objectId)
        throw new Error(
          this.getLanguageLabel("FEATURE_DETAILS_LOADING_ERROR_LABEL")
        );
      const layer = this.getLayerByTitle(layerTitle);
      //throw an error if layer doesn't exist
      if (!layer)
        throw new Error(
          this.getLanguageLabel("FEATURE_DETAILS_LOADING_ERROR_LABEL")
        );
      const numericObjectId = Number(objectId);
      //throw an error if objectId is not numeric, and therefore is invalid
      if (isNaN(numericObjectId))
        throw new Error(
          this.getLanguageLabel("FEATURE_DETAILS_LOADING_ERROR_LABEL")
        );
      // create the query and handle outfields to include 'objectId' if not included
      const { outFields } = queryOptions;
      const updatedOutfields = outFields
        ? outFields === "*" ||
          outFields === ["*"] ||
          outFields
            .map((outField) => outField.toLowerCase())
            .indexOf(objectIdField.toLowerCase()) !== -1
          ? outFields
          : [...outFields, objectIdField]
        : "*";
      const query = {
        returnGeometry: true,
        ...queryOptions,
        outFields: updatedOutfields,
        start: 0,
        num: 1
      };
      // query for the features
      const features = await this.queryLayerForFeatures(
        layer,
        query,
        signal,
        useFetch
      );
      //if not features found, throw an error
      if (!features || features.length === 0)
        throw new Error(
          this.getLanguageLabel("FEATURE_DETAILS_LOADING_ERROR_LABEL")
        );
      //else return the features
      this.loadNextFeatures(objectId, layerTitle, signal);
      return features;
    } catch (e) {
      if (isNullOrUndefined(e) == undefined) return;
      if ((e && e.name && e.name === ABORT_ERROR_NAME) || signal.aborted) {
        return;
      }
      this.setState({
        featureError: this.getErrorMessage(e, layerTitle)
      });
    }
  };

  featureErrorModal = () => {
    const { featureError } = this.state;
    if (!featureError) return null;

    const backUrl =
      window.location.pathname.indexOf(FEATURE_DETAILS_URL_DELIMITER) > -1
        ? window.location.pathname.split(FEATURE_DETAILS_URL_DELIMITER)[0]
        : window.location.pathname.split(FEATURES_OVERVIEW_URL_DELIMITER)[0];

    const {
      selectedWorkflow: { title }
    } = this.props;
    return (
      <Modal
        body={this.getErrorModalBody(featureError)}
        isOpen={true}
        title={this.getLanguageLabel("ERROR_LOADING_FEATURE_LABEL")}
        primaryButtonTitle={this.getLanguageLabel(
          "FEATURE_DETAILS_LOADING_ERROR_BUTTON_LABEL"
        )}
        primaryButtonLink={{
          url: backUrl,
          docTitle: title
        }}
        primaryButtonAction={this.closeFeatureError}
        showCloseButton={false}
      />
    );
  };

  closeFeatureError = () => {
    this.setState({
      featureError: null
    });
  };

  /**
   * @public
   * This method handles the layer queries
   * @param {object} layer - the layer to query - see esri FeatureLayer for available properties
   * @param {object} query - query object. Includes things like where, outFields, geometry etc
   * @param {object} signal - abort signal for the query
   * @param {boolean} [useFetch] - this determines whether to directly hit the layer url or use the built in esri layer queries
   */
  queryLayerForFeatures = async (layer, query, signal, useFetch = false) => {
    //wait until the layer is fully loaded
    return await layer.when(
      async () => {
        //if the query is a small one, e.g. is paginated and the limit is <= the max size for the layer geometry type, or geometry is not being returned, then call fetchlayerFeatures and return the result
        if (
          query.start ||
          (query.num && query.num <= maxTileSize(layer.geometryType, query)) ||
          !query.returnGeometry
        ) {
          const results = await this.fetchLayerFeatures(
            layer,
            query,
            signal,
            useFetch
          );
          if (results.error)
            throw new Error(results.message ? results.message : results.error);

          return results.features;
        }
        /** otherwise need to fetch the features in batches, by calling paginateLargeQueries */
        return await this.paginateLargeQueries(layer, query, signal, useFetch);
      },
      (e) => {
        throw e;
      }
    );
  };

  handleRemoveExpiryDateFromAttributes = (results) => {
    return results.map((result) => {
      if (result.attributes.expiryDate) {
        result.attributes.expiryDate = null;
      }
      return result;
    });
  };

  handleResetExpiredSymbology = () => {
    const { renderers, getLayerName, workflowState } = this.props;
    if (!renderers || !workflowState || !workflowState.listLayerTitles) return;
    const { listLayerTitles } = workflowState;
    if (!listLayerTitles) return;
    listLayerTitles.forEach((layerTitle) => {
      const layerName = getLayerName(layerTitle);
      const renderer = renderers[layerName];
      if (!renderer) return;
      const layer = this.getLayerByTitle(layerName);
      if (!layer) return;
      const { valueExpression } = renderer;
      if (layer.renderer.valueExpression) {
        layer.renderer.valueExpression = valueExpression;
      }
    });
  };

  handleChangeExpiredSymbology = () => {
    const { listLayerTitles } = this.props.workflowState;
    listLayerTitles.forEach((layerTitle) => {
      const layer = this.getLayerByTitle(layerTitle);
      if (!layer) return;
      const { renderer } = layer;
      if (!renderer) return;
      const { valueExpression } = renderer;
      if (!valueExpression) return;
      const expiredExpExists = valueExpression.search("expired");
      if (expiredExpExists === -1) return;
      const section = valueExpression.split(",");
      const attrExp = section[1];
      const removedSpaced = attrExp.replace(" ", "");
      renderer.valueExpression = removedSpaced;
    });
  };

  getListPage = () => {
    const {
      workflowState: { ignorePagination, listPage },
      location
    } = this.props;
    const urlPage = getUrlSearchParamByKey(PAGE_PARAM, location);
    if (ignorePagination) return 1;
    else if (urlPage) return Number(urlPage);
    else if (listPage) return listPage;
    else return 1;
  };

  /**
   * Clears the feature list, requeries for features beginning at page 1
   */
  handleStartLoadingFeatures = async () => {
    const selectedSearchType = this.getSelectedSearchType();
    const { clearFeatureListItems, updateWorkflowState } = this.props;
    const { listQueryOptions, workflowAbortController = {} } =
      this.props.workflowState;
    const options = listQueryOptions ? { ...listQueryOptions } : {};
    const listPage = this.getListPage();
    const abortSignal =
      (workflowAbortController && workflowAbortController.signal) || null;
    clearFeatureListItems();
    updateWorkflowState({
      nextFeatures: null
    });
    if (
      selectedSearchType &&
      selectedSearchType !== FEATURE_TEXT_SEARCH &&
      !Object.keys(options).every((layerTitle) => options[layerTitle].geometry)
    )
      return;
    if (abortSignal && abortSignal.aborted) return;

    await this.handlePagination({
      page: !isNullOrUndefined(listPage) ? listPage : 1
    });
  };

  /**
   * Returns true if the layer has a field by the name of the passed field name and false if not
   * @public
   * @param {string} field
   * @param {object} layer
   */
  layerFieldExists = (field, layer) => {
    if (!layer || !layer.fields) return false;
    return layer.fields.find(
      (layerField) => layerField.alias === field || layerField.name === field
    )
      ? true
      : false;
  };

  /**
   * Creates a date string in YYYY-MM-DD HH:mm:ss format to use for the eventDate search string. Calculates the first second of the saved activeDateSearchValue.
   * @public
   */
  createEventSearchDate = () => {
    const { activeDateSearchValue } = this.props.workflowState;
    const eventDate = moment(activeDateSearchValue)
      .hour(0)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD HH:mm:ss");
    return eventDate;
  };

  /**
   * Creates a date string in YYYY-MM-DD HH:mm:ss format to use for the expiryDate search string. Calculates the last second of the saved activeDateSearchValue.
   * @public
   */
  createExpirySearchDate = () => {
    const { activeDateSearchValue } = this.props.workflowState;
    const expiryDate = moment(activeDateSearchValue)
      .hour(23)
      .minute(59)
      .second(59)
      .format("YYYY-MM-DD HH:mm:ss");
    return expiryDate;
  };

  /**
   * Compares today's date with either the saved date value or the passed date value and returns true if they are different
   * @public
   * @param {object} [value]
   */
  filterDateIsNotToday = (value) => {
    if (!this.props.workflowState) return false;
    const { activeDateSearchValue } = this.props.workflowState;
    if (!value && !activeDateSearchValue) return false;
    const dateValue = value ? value : activeDateSearchValue;
    const today = moment(new Date()).format("DD/MM/YYYY");
    const formattedDateFilterValue = moment(dateValue).format("DD/MM/YYYY");
    return today !== formattedDateFilterValue;
  };

  /**
   * Generates a sql search string for the date filter
   * @public
   */
  createDateQueryExpression = () => {
    const eventDate = this.createEventSearchDate();
    const expiryDate = this.createExpirySearchDate();
    const queryExpression = `eventDate <= '${eventDate}' AND (expiryDate IS NULL OR expiryDate >= '${expiryDate}')`;
    return queryExpression;
  };

  /**
   * Generates a sql search string using the passed parameters
   * @public
   * @param {string[]} searchFields
   * @param {object} layer
   * @param {string} value
   */
  handleCreateTextQueryString = (searchFields, layer, value) => {
    if (!searchFields || value === null || value === undefined || !layer)
      return "";
    const where = searchFields
      .reduce((result, searchField) => {
        const isClientSide = layer.url ? false : true;
        const layerFieldExists = this.layerFieldExists(searchField, layer);
        let fieldName = layerFieldExists ? searchField : CUSTOM_DATA_FIELD;
        if (!isClientSide) {
          fieldName = `"${fieldName}"`;
        }
        if (
          !layerFieldExists &&
          !this.layerFieldExists(CUSTOM_DATA_FIELD, layer)
        )
          return result;
        const exp = layerFieldExists
          ? `lower(${fieldName}) LIKE '%${value.toLowerCase()}%'`
          : `lower(${fieldName}) LIKE '%${searchField.toLowerCase()}%' AND lower(${fieldName}) LIKE '%${value.toLowerCase()}%'`;
        return [...result, exp];
      }, [])

      .join(" OR ");
    return where;
  };

  getQueryExpression = (
    layerTitle,
    queryOptions = {},
    searchParams,
    filters,
    geometry
  ) => {
    const where = queryOptions.where || "";
    if (!this.props.workflowState) return "";
    const { searchFields } = this.props.workflowState;
    const layer = this.getLayerByTitle(layerTitle);
    const textSearch = this.getSearchText();
    const filterExpiredFeatures = this.getFilterExpiredOption();
    const nonGeometryFilters = filters
      ? filters.filter(
          (item) =>
            item.filterType !== "geometry" && item.filterType !== "spatial"
        )
      : [];
    const filterExp =
      nonGeometryFilters && nonGeometryFilters.length > 0
        ? this.createFilterExpression(nonGeometryFilters, layer)
        : null;
    let basicExp = this.layerFieldExists(DELETED_DATE_FIELD, layer)
      ? DELETEDDATE_QUERY_EXPRESSION
      : "1=1";
    if (
      (!textSearch || filterExpiredFeatures) &&
      !geometry &&
      this.layerFieldExists(EXPIRY_DATE_FIELD, layer)
    ) {
      basicExp = QUERY_EXPRESSION;
    }
    let expression = where && where !== QUERY_EXPRESSION ? where : basicExp;
    if (textSearch) {
      expression = `(${this.handleCreateTextQueryString(
        searchFields,
        layer,
        textSearch
      )}) AND ${basicExp}`;
    }
    if (filterExp) {
      expression = `(${expression}) AND ${filterExp}`;
    }
    if (this.filterDateIsNotToday()) {
      expression = `(${expression}) AND ${this.createDateQueryExpression()}`;
    }
    if (!searchParams) return expression;
    return `(${expression}) AND ${searchParams}`;
  };

  getQueryGeometry = async (queryOptions = {}, filters = [], signal = null) => {
    const { updateWorkflowState, workflowState } = this.props;
    const { geometry } = queryOptions;
    if (!workflowState) return null;
    const { filteringOptions, spatialFilterGeometry } = workflowState;
    const spatialLayerFilters = filters.filter(
      (filter) => filter.filterType === "spatial"
    );

    if (!geometry && !spatialLayerFilters.length) return null;
    else if (geometry && !spatialLayerFilters.length) return geometry;
    else {
      const existingFilterGeometries = spatialFilterGeometry
        ? spatialFilterGeometry
        : {};

      const filterFeatureResults = await Promise.all(
        spatialLayerFilters.map(async (filter) => {
          const { field, value } = filter;
          const filterInfo = filteringOptions.spatialLayers.find(
            (filterItem) => filterItem.field === filter.field
          );
          if (
            existingFilterGeometries[field] &&
            existingFilterGeometries[field][value]
          ) {
            return existingFilterGeometries[field][value];
          }
          if (!filterInfo || !filterInfo.layer) return null;
          const { layer, query } = filterInfo;
          const filterFeatures = await this.queryFeaturesByLayerTitle(
            layer,
            {
              where: `${field} = '${value}'${query ? ` AND ${query}` : ""}`,
              outFields: ["objectId"]
            },
            signal
          );
          const filterGeometry =
            filterFeatures && filterFeatures[0]
              ? filterFeatures[0].geometry
              : null;
          const fieldFilterGeometry = existingFilterGeometries[field]
            ? { ...existingFilterGeometries[field], [value]: filterGeometry }
            : {
                [value]: filterGeometry
              };
          existingFilterGeometries[field] = fieldFilterGeometry;
          return filterGeometry;
        })
      ).then((results) => [].concat(...results.filter((result) => result)));

      updateWorkflowState({
        spatialFilterGeometry: existingFilterGeometries
      });
      if (!filterFeatureResults.length) return geometry ? geometry : null;
      const filterGeometry = union(filterFeatureResults);
      if (!geometry) return simplify(filterGeometry);
      const overlaps = intersects(filterGeometry, geometry);
      if (!overlaps)
        throw new Error(
          this.getLanguageLabel("FILTER_GEOMETRY_OVERLAP_ERROR_LABEL")
        );
      else return simplify(intersect(filterGeometry, geometry));
    }
  };

  /**
   * Updates the layer query options and resets pagination, then calls method to query for features
   * @public
   * @param {object} [geometry]
   * @param {function} [onComplete]
   */
  handleUpdateSearchQueries = (geometry, onComplete) => {
    const { listQueryOptions, originalQueryInfo } = this.props.workflowState;
    this.handleResetAbortController();
    this.clearLabelGraphics();
    const savedQueryInfo = originalQueryInfo
      ? originalQueryInfo
      : listQueryOptions;
    const { updateWorkflowState } = this.props;
    const newListQueryOptions = Object.keys({
      ...listQueryOptions
    }).reduce((result, layerTitle) => {
      result[layerTitle] = {
        ...listQueryOptions[layerTitle],
        geometry
      };
      return result;
    }, {});
    updateWorkflowState(
      {
        listQueryOptions: newListQueryOptions,
        listTotal: 0,
        originalQueryInfo: savedQueryInfo,
        listPage: 1
      },
      () => this.handleSearchFeatures(onComplete)
    );
  };
  /**
   *
   * @param {function} [onComplete]
   * loads features and sets state.isSearchingFeatures
   * calls onComplete if passed in as a param
   */

  handleSearchFeatures = async (onComplete) => {
    try {
      this.setIsSearchingFeatures(true);
      await this.handleStartLoadingFeatures();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    } finally {
      this.setIsSearchingFeatures(false);
      if (onComplete) onComplete();
    }
  };

  /**
   * Resets the pagination settings and the query options for each layer, and calls method to reset the feature list
   * @public
   */
  resetSearchQuery = (onComplete) => {
    this.setIsSearchingFeatures(true);
    const { listQueryOptions, listTotal, filters } = this.props.workflowState;
    const selectedSearchType = this.getSelectedSearchType();
    const { updateWorkflowState, clearFeatureListItems } = this.props;
    const newListQueryOptions = Object.keys(listQueryOptions).reduce(
      (result, layerTitle) => {
        result[layerTitle] = {
          ...listQueryOptions[layerTitle],
          geometry: null
        };
        return result;
      },
      {}
    );
    let newFilters = filters;
    if (
      filters &&
      filters.length > 0 &&
      filters.find(
        (filter) => filter.field === "expiryDate" && filter.value === null
      )
    ) {
      newFilters = filters.filter(
        (item) => item.field !== "expiryDate" && item.value !== null
      );
    }
    this.handleResetAbortController();
    this.clearLabelGraphics();
    updateWorkflowState(
      {
        existingSearchGraphic: null,
        listQueryOptions: newListQueryOptions,
        listTotal: selectedSearchType === FEATURE_TEXT_SEARCH ? listTotal : 0,
        filters: newFilters
      },
      onComplete
    );
    this.setSearchUrlParams("", selectedSearchType, 1);
    if (selectedSearchType !== FEATURE_TEXT_SEARCH) {
      clearFeatureListItems();
    }
    this.setIsSearchingFeatures(false);
  };

  /**
   * Calls queryFeaturesByLayerTitle method for each layerTitle.
   * Adds returned features to redux state featureListItems
   * Returns all feature results, and handles zoom to features
   * @param {string[]} [layerTitles]
   * @param {object} [queryOptions]
   * @param {object} [signal]
   * @public
   */
  loadFeatures = async (
    layerTitles,
    queryOptions,
    signal,
    addToList = true,
    sortMethod = null,
    limit = null,
    page = 1
  ) => {
    const { clearFeatureListItems, addFeatureListItems } = this.props;
    if (addToList) {
      clearFeatureListItems();
    }
    const {
      listQueryOptions,
      workflowAbortController = {},
      activeListLayers,
      filters,
      searchParams,
      useFetch = []
    } = this.props.workflowState;

    const layers = layerTitles || activeListLayers || false;
    if (!layers)
      throw new Error(this.getLanguageLabel("LOAD_FEATURES_NO_LAYERS_MSG"));
    const options = queryOptions
      ? { ...queryOptions }
      : listQueryOptions
      ? { ...listQueryOptions }
      : {};
    const abortSignal = signal || workflowAbortController.signal || null;
    const listLimit = limit || this.featureListLimit();
    const counts = await this.getListFeatureCounts(
      layers,
      options,
      abortSignal
    );
    const listLayerQueryPageInfo = this.calculatePageOffsets(
      layers,
      listLimit,
      counts,
      page
    );

    let results = [];
    for (const layerTitle of layers) {
      if (counts[layerTitle] === 0) continue;
      const actualLayer = this.getLayerByTitle(layerTitle);
      if (!actualLayer) continue;
      const listOptions = options[layerTitle] ? options[layerTitle] : {};
      if (addToList) {
        const paginationInfo = listLayerQueryPageInfo[layerTitle];
        let remainingLimit =
          !isNullOrUndefined(listLimit) && listLimit - results.length;
        if (
          !isNullOrUndefined(listLimit) &&
          paginationInfo &&
          !isNullOrUndefined(paginationInfo.num)
        ) {
          const { start, num } = paginationInfo;
          if (remainingLimit < 1) break;
          if (num === 0) continue;
          listOptions.num = remainingLimit;
          listOptions.start = start;
        }
      }
      await actualLayer.when(async () => {
        let geometry;
        try {
          geometry = await this.getQueryGeometry(
            listOptions,
            filters,
            abortSignal
          );
        } catch (e) {
          if (e.name && e.name !== ABORT_ERROR_NAME) {
            this.errorNotification(
              e.message,
              this.getLanguageLabel("FILTER_GEOMETRY_ERROR_TITLE")
            );
            return [];
          }
        }
        const where = this.getQueryExpression(
          layerTitle,
          listOptions,
          searchParams ? searchParams[layerTitle] : null,
          filters,
          geometry
        );
        const searchResults = await this.queryFeaturesByLayerTitle(
          layerTitle,
          {
            ...listOptions,
            where,
            geometry
          },
          abortSignal,
          useFetch.includes(layerTitle)
        );

        const features =
          addToList && this.filterDateIsNotToday()
            ? this.handleRemoveExpiryDateFromAttributes(searchResults)
            : searchResults;
        if (abortSignal && abortSignal.aborted) return;
        const sortedFeatures = sortMethod
          ? features.sort(sortMethod)
          : features;
        results = [...results, ...sortedFeatures];
        if (addToList) {
          addFeatureListItems(sortedFeatures, abortSignal);
        }
      });
    }

    if (addToList) {
      const hasGeometry = Object.keys(options).find(
        (key) => options[key].geometry
      );

      const resultsToZoomTo = hasGeometry
        ? [
            ...results,
            {
              geometry: options[hasGeometry].geometry
            }
          ]
        : results;

      this.zoomToFeatures(resultsToZoomTo);

      if (this.filterDateIsNotToday()) {
        this.handleChangeExpiredSymbology();
      } else {
        this.handleResetExpiredSymbology();
      }
    }
    return results;
  };
  /**
   * Calls layer.queryFeatureCounts method for each layerTitle.
   * Returns sum of counts
   * @param {string[]} [layerTitles]
   * @param {object} [queryOptions]
   * @param {object} [signal]
   * @public
   */
  getListFeatureCounts = async (layerTitles, queryOptions, signal) => {
    const { updateWorkflowState } = this.props;
    const {
      listQueryOptions,
      workflowAbortController,
      listLayerTitles,
      activeListLayers,
      filters,
      searchParams
    } = this.props.workflowState;

    const layers = layerTitles
      ? layerTitles
      : listLayerTitles
      ? listLayerTitles
      : false;
    const abortSignal = signal
      ? signal
      : workflowAbortController
      ? workflowAbortController.signal
      : null;
    let listCountsByLayer = {};
    if (!layers) return false;
    const options = queryOptions
      ? { ...queryOptions }
      : listQueryOptions
      ? { ...listQueryOptions }
      : {};
    const totals = await Promise.all(
      layers.map(async (layerTitle) => {
        const layer = this.getLayerByTitle(layerTitle);
        let count = 0;
        if (layer) {
          await layer.when(async () => {
            let geometry = null;
            try {
              geometry = await this.getQueryGeometry(
                options[layerTitle],
                filters,
                abortSignal
              );
            } catch (e) {
              return (count = 0);
            }
            const where = this.getQueryExpression(
              layerTitle,
              options[layerTitle],
              searchParams ? searchParams[layerTitle] : null,
              filters,
              geometry
            );
            const layerOptions = options[layerTitle]
              ? {
                  ...options[layerTitle],
                  where
                }
              : {
                  where
                };
            const query = {
              ...layerOptions,
              num: null,
              start: null,
              returnGeometry: false,
              where,
              geometry
            };
            try {
              count = await layer.queryFeatureCount(query, {
                signal: abortSignal
              });
            } catch (e) {
              if (e.name === ABORT_ERROR_NAME) {
                count = 0;
                return;
              }
              throw new Error(this.getErrorMessage(e, layerTitle));
            }
          });
        }
        listCountsByLayer[layerTitle] = count;
        return count;
      })
    ).catch((e) => {
      if (e.name !== ABORT_ERROR_NAME) {
        this.errorNotification(
          e.message,
          this.getLanguageLabel("ERROR_LAYER_QUERY_TITLE_LABEL")
        );
      }
      throw e;
    });
    const count = totals.reduce((a, b) => a + b, 0);
    const filteredListTotal =
      listLayerTitles && listLayerTitles.length > 0
        ? listLayerTitles.reduce((result, layer) => {
            if (activeListLayers.find((item) => item === layer)) {
              return result + listCountsByLayer[layer];
            } else return result;
          }, 0)
        : 0;
    updateWorkflowState({
      listTotal: count,
      listCountsByLayer,
      filteredListTotal
    });
    return listCountsByLayer;
  };

  featureListLimit = () => {
    const {
      location,
      workflowState: { listLimit, ignorePagination },
      selectedOrganisation
    } = this.props;
    if (ignorePagination) return ALL_PAGINATION_OPTION;
    const storedLimit = getSession(LIMIT_PARAM);
    const urlLimit = getUrlSearchParamByKey(location, LIMIT_PARAM);
    const orgPreferences =
      selectedOrganisation && selectedOrganisation.preferences
        ? selectedOrganisation.preferences
        : {};

    if (urlLimit && urlLimit !== DEFAULT_PAGINATION_STRING)
      return Number(urlLimit);
    else if (storedLimit) return Number(storedLimit);
    else if (orgPreferences.featureListLimit)
      return orgPreferences.featureListLimit;
    else if (listLimit && listLimit !== DEFAULT_PAGINATION_STRING)
      return listLimit;
    else if (
      orgPreferences.paginationOptions &&
      orgPreferences.paginationOptions.length
    ) {
      return orgPreferences.paginationOptions[0];
    } else {
      return DEFAULT_PAGINATION_STRING;
    }
  };

  perPageOptions = () => {
    const { selectedOrganisation } = this.props;
    if (!selectedOrganisation) return DEFAULT_PAGINATION_OPTIONS;
    const { preferences } = selectedOrganisation;
    if (!preferences) return DEFAULT_PAGINATION_OPTIONS;
    const { paginationOptions } = preferences;
    return paginationOptions
      ? [...paginationOptions, ALL_PAGINATION_OPTION]
      : DEFAULT_PAGINATION_OPTIONS;
  };

  firstPerPageOption = () => {
    const { location } = this.props;
    const perPageOptions = this.perPageOptions();
    const urlLimit = getUrlSearchParamByKey(location, LIMIT_PARAM);
    const firstOption =
      urlLimit && perPageOptions.includes(Number(urlLimit))
        ? Number(urlLimit)
        : perPageOptions[0];
    return firstOption;
  };
  maxPage = () => {
    const { listTotal } = this.props.workflowState;
    const listLimit = this.featureListLimit();
    if (!listLimit || listLimit === DEFAULT_PAGINATION_STRING || !listTotal)
      return 0;
    return Math.ceil(listTotal / listLimit);
  };

  /**
   * Updates workflow state with page and offset for feature list pagination
   * @param {object} paginationInfo - contains page
   * @public
   */

  handlePagination = async ({ page, limit }) => {
    const {
      workflowState: {
        activeListLayers,
        workflowAbortController,
        listQueryOptions = {},
        existingSearchGraphic
      }
    } = this.props;
    this.clearLabelGraphics();
    const listLimit = limit
      ? limit
      : this.featureListLimit() &&
        this.featureListLimit() !== DEFAULT_PAGINATION_STRING
      ? this.featureListLimit()
      : DEFAULT_PAGINATION_VALUE;

    const listPage = page > this.maxPage() ? 1 : page;
    this.setSearchUrlParams(
      this.getSearchText(),
      this.getSelectedSearchType(),
      listPage,
      listLimit
    );

    const signal =
      workflowAbortController && workflowAbortController.signal
        ? workflowAbortController.signal
        : null;

    if (signal && signal.aborted) return;

    const queryOptions = {};
    for (let layerTitle of activeListLayers) {
      const options = listQueryOptions[layerTitle] || {};
      if (existingSearchGraphic)
        options.geometry = existingSearchGraphic.geometry;
      let outFields = !isNullOrUndefined(options.outFields)
        ? options.outFields
        : await this.getOutFields(layerTitle);
      queryOptions[layerTitle] = {
        ...options,
        outFields
      };
    }
    const listFeatures = await this.loadFeatures(
      activeListLayers,
      queryOptions,
      signal,
      true,
      null,
      listLimit,
      listPage
    );
    return listFeatures;
  };

  getTotalListOffset = (limit = 10, page = 1) => {
    return limit * (page - 1);
  };

  calculatePageOffsets = (layerTitles, limit, countsByLayer = {}, page = 1) => {
    let layerToStart = 0;
    let offsetToStart = 0;
    const listOffset = this.getTotalListOffset(limit, page);
    for (let i = 0; i < layerTitles.length; i++) {
      const layersSoFar = layerTitles.slice(0, i + 1);
      const totals = layersSoFar.reduce((result, item) => {
        const layerTotal = countsByLayer[item] ? countsByLayer[item] : 0;
        return result + layerTotal;
      }, 0);
      if (totals > listOffset) {
        layerToStart = i;
        const previousLayers = i === 0 ? false : layerTitles.slice(0, i);
        const previousTotals = previousLayers
          ? previousLayers.reduce((result, item) => {
              const layerTotal = countsByLayer[item];
              return result + layerTotal;
            }, 0)
          : 0;
        const remainder = listOffset - previousTotals;
        offsetToStart = remainder;
        break;
      }
    }

    const layersToQuery = layerTitles.slice(layerToStart, layerTitles.length);

    const layerPaginationInfo = layerTitles.reduce((result, layer) => {
      const isStartingLayer = layer === layerTitles[layerToStart];
      const isInQueryArray = layersToQuery.find((item) => item === layer);
      if (isStartingLayer) {
        result[layer] = {
          start: offsetToStart,
          num: limit
        };
      } else {
        result[layer] = {
          start: 0,
          num: isInQueryArray ? limit : 0
        };
      }
      return result;
    }, {});
    return layerPaginationInfo;
  };
  /**
   * Called when the feature list per page value is updated
   * Calls handlePagination to requery with new pagination settings
   * @param {number} newListLimit
   * @public
   */
  handleChangePerPageValue = async (newListLimit) => {
    const { listTotal } = this.props.workflowState;
    const currentListLimit =
      this.featureListLimit() === DEFAULT_PAGINATION_STRING
        ? DEFAULT_PAGINATION_VALUE
        : this.featureListLimit();
    const shouldNotUpdate =
      (currentListLimit < newListLimit || newListLimit > listTotal) &&
      listTotal < currentListLimit;
    this.setSearchUrlParams(
      this.getSearchText(),
      this.getSelectedSearchType(),
      1,
      newListLimit
    );

    if (shouldNotUpdate) return;

    await this.handlePagination({ page: 1, limit: newListLimit });
  };

  /**
   * Calls mapView.whenLayerView for the passed layer and returns layerView if present, else returns the passed layer in object format (similar to layerView format)
   * @param {object} layer
   * @public
   */
  getLayerView = async (layer) => {
    try {
      const { mapView } = this.props;
      const layerView = await mapView.whenLayerView(layer);
      if (!layerView) return { layer };
      return layerView;
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };

  /**
   * Sets the layer visibility as the passed visibility
   * @param {object} layer
   * @param {boolean} visibility
   * @public
   */
  setLayerVisibility = (layer, visibility) => {
    if (!layer) return;
    layer.visible = visibility;
  };

  isCommunityLayer = (layerTitle) => {
    if (!layerTitle) return false;
    const { preferences } = this.props.selectedOrganisation;
    if (!preferences) return false;
    const { layers } = preferences;
    if (!layers) return false;
    const communityLayer = layers.find(
      (layer) =>
        (layer.title === layerTitle && layer.type === "community") ||
        (layer.type === CUSTOM_IMAGERY_LAYER_TYPE &&
          layerTitle.includes(layer.title))
    );

    return communityLayer !== undefined;
  };

  /**
   * Loops through feature layers and sets those with the passed layerTitles as visible, all others invisible.
   * If layer's title is present in visibleLayerLabels, sets labelsVisible as true, else false.
   * Updates layerView filter with any filterChanges present
   * @param {array} layerTitles - an array of layerTitle strings
   * @param {array} visibleLayerLabels - an array of layerTitle strings to set labels as visible for
   * @param {object} filterChanges - keyed by layerTitle
   * @public
   */
  setVisibleLayers = async (layerTitles, visibleLayerLabels, filterChanges) => {
    try {
      const { webMap, getLayerName } = this.props;
      const visibleLayerNames = layerTitles.map((title) => getLayerName(title));
      const visibleLayerLabelNames = visibleLayerLabels.map((title) =>
        getLayerName(title)
      );
      const visibleLayers = Promise.all(
        webMap.layers.items
          .filter(
            (layer) =>
              layer.title !== null &&
              !layer.title.includes(TEMP_LAYER_PREFIX) &&
              !this.isCommunityLayer(layer.title) &&
              !layer.title.includes(PROPERTY_DISPLAY_SUFFIX)
          )
          .map(async (layerItem) => {
            const visibleIndex = visibleLayerNames.indexOf(layerItem.title);
            const labelsVisible =
              visibleLayerLabelNames.indexOf(layerItem.title) !== -1;
            const layerView = await this.getLayerView(layerItem);
            let layer = layerItem;
            if (layerView) {
              const originalTitle = layerTitles[visibleIndex];
              layer = layerView.layer;
              if (filterChanges && filterChanges[originalTitle]) {
                layerView.filter = filterChanges[originalTitle];
              }
            }
            this.setLayerVisibility(layer, visibleIndex !== -1);
            if (!layer) return;
            layer.labelsVisible = labelsVisible;
            return visibleIndex !== -1 ? layer : false;
          })
      ).then((layers) => {
        return layers.filter((layer) => layer !== false);
      });

      return visibleLayers;
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };

  /**
   * Uses [react-notifications](https://github.com/minhtranite/react-notifications) to render a notification popout
   * @param {string} type - either "success", "error", "info", or "warning"
   *
   * @param message - the message to show in the notification. Can be either a string or a node/element
   *
   * @param {string} title - the title to show in the notification
   * @public
   */
  handleNotification = (type, message, title) => {
    const timeout = type === "success" ? 2000 : 0;
    const callback =
      type === "info"
        ? () => {
            this.setState({
              hasInfoNotification: false
            });
          }
        : type === "warning"
        ? () => {
            this.setState({
              hasWarningNotification: false
            });
          }
        : null;
    NotificationManager[type](message, title, timeout, callback);
  };

  customDataSchemaDetails = () => {
    const { workflowState } = this.props;
    const { activeFeatureService, dataSchemas } = workflowState;
    return activeFeatureService
      ? dataSchemas && dataSchemas[activeFeatureService]
        ? dataSchemas[activeFeatureService]
        : {}
      : false;
  };

  /**
   * Checks workflowState for featureList titleField and if present returns this. If not set, returns 'title"
   * @public
   */
  getListTitle = () => {
    const { workflowState } = this.props;
    if (!workflowState) return "title";
    const { featureList } = workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (
      customDataSchema &&
      customDataSchema.featureList &&
      customDataSchema.featureList.titleField
    )
      return customDataSchema.featureList.titleField;
    if (featureList) {
      const { titleField } = featureList;
      if (titleField) return titleField;
    }
    return "title";
  };

  /**
   * Checks workflowState for featureList subtitleFields. If set, returns these, if not returns category and eventDate as subtitle fields
   * @public
   */

  getListSubtitles = () => {
    const { workflowState } = this.props;
    if (!workflowState)
      return [
        { field: "category", label: null },
        { field: "eventDate", label: null }
      ];
    const { featureList } = workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (
      customDataSchema &&
      customDataSchema.featureList &&
      customDataSchema.featureList.subTitleFields
    )
      return customDataSchema.featureList.subTitleFields;
    if (featureList && featureList.subTitleFields) {
      return featureList.subTitleFields;
    }
    return [
      { field: "category", label: null },
      { field: "eventDate", label: null }
    ];
  };

  /**
   * Processes the passed features to find the expired features, and returns an emphasis object containing those features, emphasisIconType (CSS color string), and emphasisIconType (string)
   * @param {array} features
   * @public
   */
  getExpiredEmphasis = (features) => {
    return {
      features:
        features && Array.isArray(features)
          ? features.filter((item) => {
              return (
                item.attributes.expiryDate !== null &&
                item.attributes.expiryDate !== undefined
              );
            })
          : [],
      emphasisIconType: "expired",
      emphasisIconColor: defaultTheme.agDarkOrange
    };
  };

  /**
   * Processes the passed features to find the features that fit each geometry type (polygon, polyline, point), and returns an array with an emphasis object for each geometry type. Each object contains the features that match the geometry type, the emphasisIconColor (CSS color string), and the emphasisIconType (string)
   * @param {array} features
   * @public
   */
  getGeometryEmphasis = (features) => {
    const {
      workflowState: { displayLegendIcons = [] }
    } = this.props;
    return [
      {
        features:
          features &&
          Array.isArray(features) &&
          !displayLegendIcons.includes(GEOMETRY_TYPE_POLY)
            ? features.filter((item) => {
                return (
                  (item.geometry &&
                    item.geometry.type === GEOMETRY_TYPE_POLY) ||
                  (item.layer && item.layer.geometryType === GEOMETRY_TYPE_POLY)
                );
              })
            : [],
        emphasisIconType: GEOMETRY_TYPE_POLY,
        emphasisIconColor: defaultTheme.agLightGreen
      },
      {
        features:
          features &&
          Array.isArray(features) &&
          !displayLegendIcons.includes(GEOMETRY_TYPE_LINE)
            ? features.filter((item) => {
                return (
                  (item.geometry &&
                    item.geometry.type === GEOMETRY_TYPE_LINE) ||
                  (item.layer && item.layer.geometryType === GEOMETRY_TYPE_LINE)
                );
              })
            : [],
        emphasisIconType: GEOMETRY_TYPE_LINE,
        emphasisIconColor: defaultTheme.agLightGreen
      },
      {
        features:
          features &&
          Array.isArray(features) &&
          !displayLegendIcons.includes(GEOMETRY_TYPE_POINT)
            ? features.filter((item) => {
                return (
                  (item.geometry &&
                    item.geometry.type === GEOMETRY_TYPE_POINT) ||
                  (item.layer &&
                    item.layer.geometryType === GEOMETRY_TYPE_POINT)
                );
              })
            : [],
        emphasisIconType: GEOMETRY_TYPE_POINT,
        emphasisIconColor: defaultTheme.agLightGreen
      }
    ];
  };

  /**
   * Filters the features to return an array of features that match the geometries passed as a parameter
   * @param {array} features - array of features to filter
   * @param {array} geometries - array of geometry type strings (e.g. "polygon")
   * @public
   */
  handleFilterOutItemsByGeometries = (features, geometries) => {
    return features.filter(
      (feature) => !geometries.includes(feature.geometry.type)
    );
  };

  createDateFilterExpression = (filter) => {
    const {
      value: { startDate, endDate },
      field
    } = filter;
    const formattedStartDate = moment(startDate)
      .hour(0)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD HH:mm:ss");

    const formattedEndDate = moment(endDate)
      .hour(23)
      .minute(59)
      .second(59)
      .format("YYYY-MM-DD HH:mm:ss");

    return `(${field} >= '${formattedStartDate}' AND ${field} <= '${formattedEndDate}')`;
  };

  createFilterExpression = (filters, layer) => {
    const expressions =
      filters && filters.length > 0
        ? filters.reduce((result, filter) => {
            const {
              show,
              field,
              value,
              dateType,
              lessThan,
              greaterThan,
              customExpression
            } = filter;
            if (
              value === "-" ||
              (value &&
                Object.prototype.hasOwnProperty.call(value, "startDate") &&
                value.startDate === null) ||
              (value &&
                Object.prototype.hasOwnProperty.call(value, "endDate") &&
                value.endDate === null)
            )
              return result;
            if (dateType && !this.layerHasDateField(layer, field))
              return result;
            if (dateType)
              return [...result, this.createDateFilterExpression(filter)];
            const isExpiryFilter = field === "expiryDate" && value === null;
            const customDataExp = show
              ? `(customData LIKE '%"${filter.field}"%' AND customData LIKE '%"${filter.value}"%')`
              : `(customData NOT LIKE '%"${filter.field}"%' OR (customData LIKE '%"${filter.field}"%' AND customData NOT LIKE '%"${filter.value}"%'))`;
            const filterValueExp =
              typeof value === "string" ? `'${value}'` : value;
            const fieldExp = lessThan
              ? `${field} <= ${filterValueExp}`
              : greaterThan
              ? `${field} >= ${filterValueExp}`
              : show
              ? `${field} = ${filterValueExp}`
              : `${field} <> ${filterValueExp}`;
            const expiryDateExp = show
              ? "expiryDate IS NULL"
              : "expiryDate IS NOT NULL";
            const fieldExists = this.layerFieldExists(field, layer);
            if (
              !fieldExists &&
              !this.layerFieldExists(CUSTOM_DATA_FIELD, layer)
            )
              return result;
            const exp = customExpression
              ? customExpression
              : isExpiryFilter
              ? expiryDateExp
              : !fieldExists
              ? customDataExp
              : fieldExp;

            return [...result, exp];
          }, [])
        : null;
    return expressions && expressions.length > 0
      ? expressions.join(" AND ")
      : null;
  };
  /**
   * updates the filters in workflow state, then calls updatedFilters on complete of workflow state update
   * @param {array} newFilters
   * @param {function} onComplete
   */
  updateFilters = async (newFilters, onComplete) => {
    this.handleResetAbortController();
    this.clearLabelGraphics();
    const {
      updateWorkflowState,
      workflowState: { listLayerTitles }
    } = this.props;

    const geometryFilters = newFilters.filter(
      (item) => item.field === "geometry"
    );
    const activeListLayers =
      geometryFilters.length > 0
        ? listLayerTitles.filter((layerTitle) => {
            const layer = this.getLayerByTitle(layerTitle);
            if (!layer) return false;
            const isFiltered = geometryFilters.find(
              (filter) =>
                filter.value === layer.geometryType ||
                layer.title.includes(filter.value)
            );
            return !isFiltered;
          })
        : listLayerTitles;
    updateWorkflowState(
      {
        filters: newFilters,
        activeListLayers,
        listPage: 1
      },
      () => this.updatedFilters(onComplete)
    );
  };

  /**
   * called on complete of update of filters being updated.
   * if onComplete is passed in, this is called after features are loaded
   * @param {function} onComplete
   */
  updatedFilters = async (onComplete) => {
    try {
      const { listQueryOptions } = this.props.workflowState;
      const selectedSearchType = this.getSelectedSearchType();
      const hasGeometrySearch =
        listQueryOptions &&
        Object.keys(listQueryOptions).some(
          (key) =>
            listQueryOptions[key] &&
            !isNullOrUndefined(listQueryOptions[key].geometry)
        );
      if (selectedSearchType === FEATURE_TEXT_SEARCH || hasGeometrySearch) {
        this.setIsSearchingFeatures(true);
        await this.handleStartLoadingFeatures();
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    } finally {
      this.setIsSearchingFeatures(false);
      if (onComplete) onComplete();
    }
  };

  whenReadOnly = () => {
    return (
      !this.hasPermission(WRITE_PERMISSION) &&
      !this.hasPermission(FULL_PERMISSION)
    );
  };

  /**
   * Returns domainValues from workflowState, or an empty object if not set
   * @public
   */
  getDomainValues = () => {
    const { domainValues } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.domainValues)
      return customDataSchema.domainValues;

    return domainValues ? domainValues : {};
  };

  getAttributeDomainValues = (attribute, feature) => {
    const domainValues = this.getDomainValues();
    if (!domainValues) return [];
    const attributeDomainValues = domainValues[attribute];
    if (!attributeDomainValues) return [];
    return getApplicableItems(feature, attributeDomainValues).sort((a, b) =>
      a.order && b.order ? (a.order > b.order ? 1 : -1) : 1
    );
  };

  /**
   * Returns displayTitles from workflowState, or defaultTitles if these are not set
   * @param {object} defaultTitles - an object keyed by attribute name with default display titles
   * @public
   */
  getDisplayTitles = (defaultTitles) => {
    const { displayTitles } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.displayTitles)
      return customDataSchema.displayTitles;
    return displayTitles ? displayTitles : defaultTitles;
  };

  /**
   * Returns displayOrder from workflowState if set, or an empty array of not set
   * @public
   */
  getDisplayOrder = () => {
    const { displayOrder } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.displayOrder)
      return customDataSchema.displayOrder;
    return displayOrder ? displayOrder : [];
  };

  /**
   * Returns placeholders from workflowState if set, and an empty object if not
   * @public
   */
  getPlaceholders = () => {
    const { placeholders } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.placeholders)
      return customDataSchema.placeholders;
    return placeholders ? placeholders : {};
  };

  /**
   * Returns an array of attribute keys from validationRules object that have required: true if set. If not set, returns an empty array
   * @public
   */
  getRequiredAttributes = () => {
    const validationRules = this.explicitValidationRules();
    if (!validationRules) return [];
    const requiredAttributes = Object.keys(validationRules).filter(
      (key) => validationRules[key].required
    );
    return requiredAttributes;
  };

  /**
   * Returns hiddenAttributes from workflowState if set, else defaultHiddenAttr if passed, else an empty array
   * @param {array} [defaultHiddenAttr] - array of attribute names
   * @public
   */
  getHiddenAttributes = (defaultHiddenAttr) => {
    const { hiddenAttributes } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.hiddenAttributes)
      return customDataSchema.hiddenAttributes;
    return hiddenAttributes
      ? hiddenAttributes
      : defaultHiddenAttr
      ? defaultHiddenAttr
      : [];
  };

  /**
   * Returns nonEditableAttributes from workflowState if set, else an empty array
   * @public
   */
  getNonEditableAttributes = (useCreateViewSettings) => {
    const { nonEditableAttributes } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    let allNonEditableAttributes = nonEditableAttributes || [];
    if (customDataSchema && customDataSchema.nonEditableAttributes) {
      allNonEditableAttributes = customDataSchema.nonEditableAttributes;
    }
    if (useCreateViewSettings && this.getUseNewAddMode()) {
      const createFields = this.getCreateFields();
      return Array.isArray(allNonEditableAttributes)
        ? [...allNonEditableAttributes, ...createFields]
        : {
            ...allNonEditableAttributes,
            ...createFields.reduce(
              (result, field) => ({
                ...result,
                [field]: true
              }),
              {}
            )
          };
    }
    return allNonEditableAttributes;
  };

  /**
   * Returns contextualAttributes from workflowState if set, else an empty object
   * @public
   */
  getContextualAttributes = () => {
    const { contextualAttributes } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.contextualAttributes)
      return customDataSchema.contextualAttributes;
    return contextualAttributes ? contextualAttributes : {};
  };

  isApplicableAttribute = (key, feature) => {
    const contextualAttributes = this.getContextualAttributes();
    if (!contextualAttributes || !contextualAttributes[key]) return true;
    return getApplicableItems(feature, contextualAttributes[key]);
  };

  /**
   * Returns customAttributes from workflowState
   * @public
   */
  getCustomAttributes = () => {
    const { customAttributes } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.customAttributes)
      return customDataSchema.customAttributes;
    return customAttributes ? customAttributes : {};
  };

  /**
   * Returns customStatistics from workflowState if set, else false
   * @public
   */
  getCustomStatistics = () => {
    const { customStatistics } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.customStatistics)
      return customDataSchema.customStatistics;
    return customStatistics ? customStatistics : false;
  };

  softValidationRules = (feature) => {
    const allValidationRules = this.validationRules();
    return Object.keys(allValidationRules).reduce((result, attr) => {
      const rules = getSoftValidationRulesForField(
        feature,
        allValidationRules[attr]
      );
      if (!rules || !Object.keys(rules).length) return result;
      return {
        ...result,
        [attr]: rules
      };
    }, {});
  };

  validationRules = () => {
    const { validationRules } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    let rules = validationRules ? validationRules : {};
    if (customDataSchema && customDataSchema.validationRules)
      rules = customDataSchema.validationRules;
    return rules;
  };

  explicitValidationRules = (feature) => {
    const allValidationRules = this.validationRules();
    return Object.keys(allValidationRules).reduce((result, attr) => {
      const rules = getExplicitValidationRulesForField(
        feature,
        allValidationRules[attr]
      );
      if (!rules || !Object.keys(rules).length) return result;
      return {
        ...result,
        [attr]: rules
      };
    }, {});
  };

  /**
   * Returns helpTexts from workflowState if set, else an empty object
   * @public
   */
  helpTexts = () => {
    const { helpTexts } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.helpTexts)
      return customDataSchema.helpTexts;
    return helpTexts ? helpTexts : {};
  };

  getHighlightedFields = () => {
    const { highlightedFields } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.highlightedFields)
      return customDataSchema.highlightedFields;
    return highlightedFields ? highlightedFields : [];
  };

  getAttributeTypes = () => {
    const { attributeTypes } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.attributeTypes)
      return customDataSchema.attributeTypes;
    return attributeTypes ? attributeTypes : {};
  };

  getCompoundAttributes = () => {
    const { compoundAttributes } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.compoundAttributes)
      return customDataSchema.compoundAttributes;
    return compoundAttributes ? compoundAttributes : {};
  };

  getUnits = () => {
    const { units } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.units)
      return customDataSchema.units;
    return units ? units : {};
  };

  getOpenToDate = () => {
    const { openToDate } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.openToDate)
      return customDataSchema.openToDate;
    return openToDate ? openToDate : {};
  };

  /**
   * Checks for fields that should not be converted to locale string. If not set, returns []
   */
  getNotConvertedToLocaleString = () => {
    const { notConvertedToLocaleString } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.notConvertedToLocaleString)
      return customDataSchema.notConvertedToLocaleString;
    return notConvertedToLocaleString ? notConvertedToLocaleString : [];
  };

  getUseNewAddMode = () => {
    const { useNewAddMode } = this.props.workflowState;
    return useNewAddMode === true;
  };

  /**
   * Collates all workflowState settings for feature details into one object
   * @param {object} defaultTitles - an object keyed by attribute name, with the value being a string to render as the display title
   * @param {array} defaultHiddenAttr - an array of attribute name strings
   * @public
   */
  getDetailsSettings = (
    defaultTitles,
    defaultHiddenAttr,
    useCreateViewSettings
  ) => {
    return {
      domainValues: this.getDomainValues(),
      defaultValues: this.getDefaultValues(),
      displayTitles: this.getDisplayTitles(defaultTitles),
      displayOrder: this.getDisplayOrder(),
      placeholders: this.getPlaceholders(),
      requiredAttributes: this.getRequiredAttributes(),
      hiddenAttributes: this.getHiddenAttributes(defaultHiddenAttr),
      nonEditableAttributes: this.getNonEditableAttributes(
        useCreateViewSettings
      ),
      contextualAttributes: this.getContextualAttributes(),
      customAttributes: this.getCustomAttributes(),
      customStatistics: this.getCustomStatistics(),
      validationRules: this.explicitValidationRules(),
      helpTexts: this.helpTexts(),
      highlightedFields: this.getHighlightedFields(),
      attributeTypes: this.getAttributeTypes(),
      compoundAttributes: this.getCompoundAttributes(),
      units: this.getUnits(),
      openToDate: this.getOpenToDate(),
      notConvertedToLocaleString: this.getNotConvertedToLocaleString()
    };
  };

  /**
   * Returns the support email set in the selected organisation preferences if set. If not set, returns default agbox help email address
   * @public
   */
  supportEmail = () => {
    const { selectedOrganisation } = this.props;
    if (!selectedOrganisation) return SUPPORT_EMAIL;
    const { preferences } = selectedOrganisation;
    if (!preferences) return SUPPORT_EMAIL;
    const { supportEmail } = preferences;
    return supportEmail ? supportEmail : SUPPORT_EMAIL;
  };

  updateNullValues = (feature) => {
    const clonedFeature = feature.clone ? feature.clone() : { ...feature };
    const { attributes } = clonedFeature;
    Object.keys(attributes).forEach((key) => {
      if (attributes[key] === ":::null:::") {
        attributes[key] = null;
      } else if (
        key === CUSTOM_DATA_FIELD &&
        attributes.customData &&
        isValidJSON(attributes.customData)
      ) {
        const customData = JSON.parse(attributes.customData);
        Object.keys(customData).forEach((customKey) => {
          if (
            Object.prototype.hasOwnProperty.call(
              customData[customKey],
              "value"
            ) &&
            customData[customKey].value === ":::null:::"
          ) {
            customData[customKey].value = null;
          }
        });
        attributes.customData = JSON.stringify(customData);
      }
    });
    return clonedFeature;
  };
  /**
   * Finds the layer with the passed layerTitle and calls applyEdits on the layer with updateFeatures set as the passed feature(s).
   * On success, if showNotification is not false, show success notification.
   * On error, if showNotification is not false, show error notification.
   * @param {string} layerTitle - the title of the layer to apply the update to
   * @param {object} feature - the feature to save
   * @param {string} successMessage - the message visible in the success notification on successful save
   * @param {boolean} showNotification - whether to show the success or error notifications
   * @public
   */
  handleSaveUpdateFeature = async (
    layerTitle,
    feature,
    successMessage,
    showNotification,
    editType = null
  ) => {
    const { getLayerName, objectIdField } = this.props;
    const updatedFeatures = Array.isArray(feature)
      ? feature.map((item) => this.updateNullValues(item))
      : [this.updateNullValues(feature)];
    const actualTitle = getLayerName(layerTitle);
    try {
      const results = await this.layerApplyEdits(
        layerTitle,
        { updates: updatedFeatures },
        successMessage && (showNotification || showNotification == undefined)
          ? successMessage
          : null,
        `${this.getLanguageLabel(
          "NOTIFICATION_ERROR_LABEL_SAVE_FEATURE"
        )}: ${objectIdField} ${
          updatedFeatures[0].attributes[objectIdField]
        }, layer '${actualTitle}'`,
        editType
      );
      return results;
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      throw e;
    }
  };

  /**
   * Calls handleNotification method with a generic success title, and the passed message inside a styled component.
   * @param {string} message
   * @public
   */
  successNotification = (message) => {
    this.handleNotification(
      "success",
      <NotificationMessageText>{message}</NotificationMessageText>,
      this.getLanguageLabel("NOTIFICATION_SUCCESS_LABEL")
    );
  };

  /**
   * If state.hasInfoNotification is false, calls handleNotification method with message in styled component and the title, then sets hasInfoNotification as true. This is so that only one info notification will be visible at once (in case of multiple issues)
   * @param {string} message
   * @param {string} title
   * @public
   */
  infoNotification = (message, title) => {
    const { hasInfoNotification } = this.state;
    if (!hasInfoNotification) {
      this.handleNotification(
        "info",
        <NotificationMessageText>{message}</NotificationMessageText>,
        title
      );
      this.setState({
        hasInfoNotification: true
      });
    }
  };
  /**
   * If state.hasWarningNotification is false, calls handleNotification method with message in styled component and the title, then sets hasWarningNotification as true. This is so that only one warning notification will be visible at once (in case of multiple issues)
   * @param {string} message
   * @param {string} title
   * @public
   */
  warningNotification = (message, title) => {
    const { hasWarningNotification } = this.state;
    if (!hasWarningNotification) {
      this.handleNotification(
        "warning",
        <NotificationMessageText>{message}</NotificationMessageText>,
        title
      );
      this.setState({
        hasWarningNotification: true
      });
    }
  };

  /**
   * Returns the passed string with all whitespaces encoded with "%20"
   * @param {string} string
   * @public
   */
  encodeString = (string) => {
    return string.replace(" ", "%20");
  };

  /**
   * Calls handleNotification method with passed message turned into a styled component that links to send email with error message to support email, and passed title
   * @param {string} message
   * @param {string} title
   * @public
   */
  errorNotification = (message, title) => {
    const {
      selectedOrganisation,
      selectedProperty,
      selectedPropertyGroup,
      propId,
      groupId
    } = this.props;
    const propertyString = selectedProperty
      ? `${this.getLanguageLabel("PROPERTY_LABEL")}: ${
          selectedProperty.title
        } (${propId})`
      : selectedPropertyGroup
      ? `${this.getLanguageLabel("PROPERTY_GROUP_LABEL")}: ${
          selectedPropertyGroup.title
        } (${groupId})`
      : "";
    this.handleNotification(
      "error",
      <div>
        <NotificationMessageText>
          {`${this.getLanguageLabel("ERROR_SERVER_MSG_LABEL")}: ${message}`}
        </NotificationMessageText>
        <NotificationErrorLink
          href={`mailto:${this.supportEmail()}?subject=${this.encodeString(
            `${this.getLanguageLabel("AGBOX_ERROR_LABEL")}: ${title}`
          )}&body=${this.encodeString(
            this.getLanguageLabel("AGBOX_HAS_FOLLOWING_ERROR_LABEL")
          )}:%0A${this.encodeString(
            `${this.getLanguageLabel("MESSAGE_LABEL")}: ${message}`
          )}%0A%0A${this.encodeString(
            `${this.getLanguageLabel("ORGANISATION_LABEL")}: ${
              selectedOrganisation.title
            }`
          )}%0A${this.encodeString(propertyString)}`}
        >
          {this.getLanguageLabel("ERRORS_CONTACT_ADMIN_LABEL")}
        </NotificationErrorLink>
      </div>,
      title
    );
  };

  layerApplyEdits = async (
    layerTitle,
    { adds = [], updates = [], deletes = [] },
    successMessage,
    errorTitle,
    editType = null
  ) => {
    try {
      const {
        getLayerName,
        user: { token, userId },
        propId
      } = this.props;
      const layer = this.getLayerByTitle(layerTitle);
      if (!layer)
        throw new Error(`Cannot find layer '${getLayerName(layerTitle)}'`);

      let results = [];
      let errors = {};
      if (layer.url) {
        const timeStamp = moment();
        const { layerApplyEdits } = requests;
        const body = new URLSearchParams();
        [...adds, ...updates, ...deletes].forEach((feature) => {
          feature.attributes.lastEditDate = timeStamp;
          feature.attributes.lastEditUser = userId;
          if (!feature.attributes.propId && propId)
            feature.attributes.propId = propId;
        });
        if (adds) {
          adds.forEach((feature) => {
            feature.attributes.createdDate = timeStamp;
            feature.attributes.createdUser = userId;
          });
          body.append("adds", JSON.stringify(adds));
        }
        if (updates) {
          body.append("updates", JSON.stringify(updates));
        }
        if (deletes) {
          body.append("deletes", JSON.stringify(deletes));
        }
        const response = await layerApplyEdits(
          `${layer.url}/${layer.layerId}`,
          body,
          editType,
          token
        );

        results = [
          ...response.addResults,
          ...response.updateResults,
          ...response.deleteResults
        ];
        errors = {
          adds: response.addResults.filter((item) => item.error),
          updates: response.updateResults.filter((item) => item.error),
          deletes: response.deleteResults.filter((item) => item.error)
        };
      } else {
        const response = await layer.applyEdits({
          updateFeatures: updates,
          addFeatures: adds,
          deleteFeatures: deletes
        });
        results = [
          ...response.addFeatureResults,
          ...response.updateFeatureResults,
          ...response.deleteFeatureResults
        ];
        errors = {
          adds: response.addFeatureResults.filter((item) => item.error),
          updates: [
            ...response.updateFeatureResults,
            ...response.deleteFeatureResults
          ].filter((item) => item.error)
        };
      }
      if (Object.keys(errors).some((editType) => errors[editType].length)) {
        const errorMessages = Object.keys(errors).reduce((result, editType) => {
          const editErrors = errors[editType];
          if (!editErrors.length) return result;
          const messages = editErrors.map((item) => {
            if (Object.prototype.hasOwnProperty.call(item.error, "message"))
              return item.error.message;
            if (Object.prototype.hasOwnProperty.call(item.error, "description"))
              return item.error.description;
            // our layers have diff format: error: true, message: string
            if (item.code === LAYER_PERMISSIONS_ERROR_CODE) {
              return this.getLanguageLabel(
                editType === "adds"
                  ? "ERROR_LAYER_PERMISSIONS_ADDS_LABEL"
                  : "ERROR_LAYER_PERMISSIONS_UPDATES_LABEL"
              );
            } else return item.message || item.description;
          });
          return [...result, ...messages];
        }, []);
        throw new Error(errorMessages.join("\n"));
      } else {
        setTimeout(() => layer.refresh(), 1000);
        if (successMessage)
          this.successNotification(sentenceCase(successMessage));
        return results;
      }
    } catch (e) {
      e.message.split("\n").forEach((message) => {
        this.errorNotification(
          message,
          errorTitle
            ? errorTitle
            : this.getLanguageLabel("NOTIFICATION_ERROR_LABEL")
        );
      });
      if (process.env.NODE_ENV === "development") console.log(e);
      throw e;
    }
  };

  /**
   * Finds the layer in the webmap with the passed layerTitle, and calls applyEdits with the passed feature in 'deleteFeatures'. If successful, shows notification with the passed successMessage, if error shows error notification with the error message that comes from the catch
   * @param {string} layerTitle
   * @param {object} item
   * @param {string} successMessage
   * @public
   */
  handleDeleteFeature = async (layerTitle, item, successMessage) => {
    try {
      const { objectIdField, getLayerName } = this.props;
      const timeStamp = moment().utc();

      const features = (Array.isArray(item) ? item : [item]).map((feature) => {
        const cloned = feature.clone ? feature.clone() : { ...feature };
        cloned.attributes.deletedDate = timeStamp;
        return cloned;
      });
      if (!features.length) return;

      await this.layerApplyEdits(
        layerTitle,
        { updates: features },
        successMessage,
        `${this.getLanguageLabel(
          "NOTIFICATION_ERROR_LABEL_ARCHIVE_FEATURE"
        )}: ${objectIdField} ${features
          .map((feature) => feature.attributes[objectIdField])
          .join(", ")}, layer '${getLayerName(layerTitle)}'`
      );
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);

      throw e;
    }
  };

  /**
   * Gets the layer with the passed layerTitle. Gets the layerView for the layer, and sets the layerView filter as the passed filter
   * @param {string} layerTitle
   * @param {object} filter
   * @public
   */
  setLayerFilterByTitle = async (layerTitle, filter) => {
    try {
      const layer = this.getLayerByTitle(layerTitle);
      const layerView = await this.getLayerView(layer);
      if (!layerView) return;
      layerView.filter = filter;
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };

  /**
   * Finds the layer in the webmap where title = the passed layerTitle
   * @param {string} layerTitle
   * @public
   */
  getLayerByTitle = (layerTitle) => {
    const { webMap, getLayerName } = this.props;
    const title = getLayerName(layerTitle);
    const layer = webMap.layers.items.find(
      (layer) => layer.title && layer.title === title
    );
    return layer;
  };

  /**
   * Finds the layer in the webmap with title = layerTitle and sets the visible and labelsVisible as the passed values.
   * @param {string} layerTitle
   * @param {boolean} layerVisible
   * @param {boolean} labelsVisible
   * @public
   */
  setLayerVisibilityByTitle = (layerTitle, layerVisible, labelsVisible) => {
    const layer = this.getLayerByTitle(layerTitle);
    if (!layer) return;
    layer.visible = layerVisible;
    layer.labelsVisible = labelsVisible != undefined ? labelsVisible : false;
  };

  /**
   * Finds the titleField in workflowState.featureList if present and returns this field from the feature if it is not laoding. If not set, returns feature.attributes.title, and if loading returns "-"
   * @param {object} feature
   * @param {boolean} isLoading
   * @public
   */
  getFeatureTitle = (feature, isLoading) => {
    let returnField;
    if (isLoading) return "-";

    const { featureList } = this.props.workflowState;
    if (featureList) {
      const { titleField } = featureList;
      returnField = titleField ? titleField : TITLE_FIELD;
    } else {
      returnField = TITLE_FIELD;
    }
    const notConvertedToLocaleString = this.getNotConvertedToLocaleString();
    const value =
      feature && feature.attributes ? feature.attributes[returnField] : "-";
    return notConvertedToLocaleString.includes(returnField)
      ? value
      : toLocale(value);
  };

  /**
   * Filters and returns webmap layers with layer.title that matches any of the array of layerTitles
   * @param {array} layerTitles - array of layer title strings
   * @public
   */
  getMultipleLayersByTitles = (layerTitles) => {
    const { webMap, getLayerName } = this.props;
    const layerNames = layerTitles.map((title) => getLayerName(title));
    const layers = webMap.layers.items.filter(
      (layer) => layer.title && layerNames.indexOf(layer.title) !== -1
    );
    return layers;
  };
  /**
   * Finds and removes layer with title that matches layerTitle using webMap.remove
   * @param {string} layerTitle
   * @public
   */
  removeLayerByTitle = (layerTitle) => {
    const { webMap, getLayerName } = this.props;
    const layer = webMap.layers.items.find(
      (layer) => layer.title && layer.title === getLayerName(layerTitle)
    );
    if (!layer) return;
    webMap.remove(layer);
  };

  /**
   * Finds and removes any layers from the webmap that have a title that matches any in the array of layerTitles
   * @param {array} layerTitles - array of layer title strings
   * @public
   */
  removeManyLayersByTitles = (layerTitles) => {
    const { webMap, getLayerName } = this.props;
    const layerNames = layerTitles.map((title) => getLayerName(title));
    const layers = webMap.layers.items.filter(
      (layer) => layerNames.indexOf(layer.title) !== -1
    );
    if (layers.length === 0) return;
    webMap.removeMany(layers);
  };

  /**
   * Creates a new GraphicsLayer and returns the newly created layer
   * @param {string} title
   * @param {boolean} visible
   * @param {array} [graphics] - array of graphics to add to the layer
   * @public
   */
  createGraphicsLayer = (title, visible, graphics) => {
    const layerExists = this.getLayerByTitle(title);
    if (layerExists) console.warn(`Layer ${title} already exists`);
    const layer = new GraphicsLayer({
      title,
      visible: visible !== undefined ? visible : true,
      graphics: graphics ? graphics : []
    });

    return layer;
  };

  /**
   * Creates a new FeatureLayer and returns the newly created layer
   * @param {object} options - object of all feature layer properties
   * @public
   */
  createFeatureLayer = (options) => {
    const layerExists = this.getLayerByTitle(options.title);
    if (layerExists) console.warn(`Layer ${options.title} already exists`);
    const layer = new FeatureLayer(options);

    return layer;
  };

  addLayersToWebMap = (layers) => {
    const { webMap } = this.props;
    webMap.addMany(layers);
  };

  addGraphicsToGraphicsLayer = (layerTitle, graphics) => {
    const layer = this.getLayerByTitle(layerTitle);
    layer.addMany(graphics);
  };

  featureIsDonut = (geometry) => {
    if (!geometry || !geometry.rings || geometry.rings.length === 1)
      return false;
    return (
      geometry.rings.some((ringSet) => {
        return !geometry.isClockwise(ringSet);
      }) &&
      geometry.rings.some((ringSet) => {
        return geometry.isClockwise(ringSet);
      })
    );
  };

  createGraphicsLabels = async (
    labelingInfos = [],
    labelSymbol = GENERIC_LABEL_SYMBOL
  ) => {
    let labelsLayer = this.getLayerByTitle(
      `${TEMP_LAYER_PREFIX}_featureLabels`
    );
    if (!labelsLayer) {
      labelsLayer = this.createGraphicsLayer(
        `${TEMP_LAYER_PREFIX}_featureLabels`,
        true
      );
      this.addLayersToWebMap([labelsLayer]);
    }
    labelsLayer.minScale = 20000;
    labelsLayer.removeAll();

    const featuresToLabel = [];

    for (let featureInfo of labelingInfos) {
      const {
        features,
        explodeFeatures,
        labelFields = ["title"]
      } = featureInfo;

      const featuresWithGeometry = features.filter((feature) =>
        feature.geometry ? true : false
      );
      if (!featuresWithGeometry.length) continue;
      await Promise.all(
        featuresWithGeometry.map(async (feature) => {
          const clonedFeature = feature.clone();
          const label = await Promise.all(
            labelFields.map((field) =>
              field === "size"
                ? this.totalAreaString(clonedFeature.geometry)
                : clonedFeature.attributes[field]
            )
          ).then((results) => results.filter((item) => item).join("\n"));
          clonedFeature.attributes.label = label;
          if (
            !explodeFeatures ||
            !clonedFeature.geometry.rings ||
            clonedFeature.geometry.rings.length < 2 ||
            this.featureIsDonut(clonedFeature.geometry)
          ) {
            featuresToLabel.push(clonedFeature);
            return;
          }

          const explodedFeature = clonedFeature.geometry.rings.map(
            (ringSet) => {
              const poly = new Polygon({
                rings: [ringSet],
                spatialReference: clonedFeature.geometry.spatialReference
              });
              return new Graphic({
                geometry: poly,
                attributes: clonedFeature.attributes
              });
            }
          );
          featuresToLabel.push(...explodedFeature);
        })
      );
    }

    if (!featuresToLabel.length) return;

    const labelGeometries = await labelPoints(
      GEOMETRY_SERVICE_URL,
      featuresToLabel.map((feature) => feature.geometry)
    );
    const labels = featuresToLabel.map((feature, i) => {
      const labelPoint = labelGeometries[i];
      return new Graphic({
        geometry: labelPoint,
        attributes: feature.attributes,
        symbol: {
          ...labelSymbol,
          text: feature.attributes.label
        },
        visible: true
      });
    });
    labelsLayer.addMany(labels);
  };

  clearLabelGraphics = () => {
    const labelsLayer = this.getLayerByTitle(
      `${TEMP_LAYER_PREFIX}_featureLabels`
    );
    if (labelsLayer) labelsLayer.removeAll();
  };

  deleteAttachmentById = async (objectId, layerId, dataSetName, attachment) => {
    try {
      const {
        user: { token },
        orgId,
        propId
      } = this.props;
      const result = await fetch(
        `${AGBOX_API_URL}/dataSets/${dataSetName}/organisations/${orgId}/properties/${propId}/FeatureServer/${layerId}/objectId/${objectId}/attachments/${attachment.attachmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": AGBOX_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: attachment.name
          }),
          method: "DELETE"
        }
      );
      const parsedResult = await result.json();
      if (parsedResult.error)
        this.errorNotification(
          parsedResult.message,
          this.getLanguageLabel("NOTIFICATION_ERROR_LABEL")
        );
      return parsedResult;
    } catch (e) {
      throw e;
    }
  };

  deleteAttachments = async (feature, layerTitle, dataSetName) => {
    try {
      const {
        updateWorkflowState,
        workflowState: { filesToDelete }
      } = this.props;
      const layer = this.getLayerByTitle(layerTitle);

      const results = await Promise.all(
        filesToDelete.map(async (file) => {
          const result = await this.deleteAttachmentById(
            feature.attributes.objectId,
            layer.layerId,
            dataSetName,
            file
          );
          if (!result.error) {
            updateWorkflowState({
              filesToDelete: filesToDelete.filter(
                (item) => file.attachmentId !== item.attachmentId
              )
            });
          } else {
            updateWorkflowState({
              attachments: this.props.workflowState.attachments
                ? [...this.props.workflowState.attachments, file]
                : [file]
            });
          }
          return result;
        })
      );
      const errors = results.filter((result) => result.error);
      return {
        error: errors.length > 0
      };
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      throw e;
    }
  };

  getAttachmentById = async (objectId, layerId, dataSetName, id, altPropId) => {
    try {
      const {
        user: { token },
        orgId,
        propId
      } = this.props;
      const queryForAttachment = await fetch(
        `${AGBOX_API_URL}/dataSets/${dataSetName}/organisations/${orgId}/properties/${
          altPropId ? altPropId : propId
        }/FeatureServer/${layerId}/objectId/${objectId}/attachments/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": AGBOX_API_KEY,
            "Content-Type": "application/json"
          },
          method: "GET"
        }
      );
      const queryResult = await queryForAttachment.json();
      return queryResult;
    } catch (e) {
      return false;
    }
  };

  getAllFeatureAttachments = async (
    objectId,
    layerId,
    dataSetName,
    altPropId
  ) => {
    try {
      const {
        user: { token },
        orgId,
        propId
      } = this.props;

      const queryForAttachment = await fetch(
        `${AGBOX_API_URL}/dataSets/${dataSetName}/organisations/${orgId}/properties/${
          altPropId ? altPropId : propId
        }/FeatureServer/${layerId}/objectId/${objectId}/attachments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": AGBOX_API_KEY,
            "Content-Type": "application/json"
          },
          method: "GET"
        }
      );
      const queryResult = await queryForAttachment.json();
      return queryResult.items;
    } catch (e) {
      return [];
    }
  };

  addDatasetsAttachment = async (objectId, layerId, dataSetName, file) => {
    try {
      const {
        user: { token },
        orgId,
        propId
      } = this.props;
      const response = await fetch(
        `${AGBOX_API_URL}/dataSets/${dataSetName}/organisations/${orgId}/properties/${propId}/FeatureServer/${layerId}/objectId/${objectId}/attachments`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          method: "POST",
          redirect: "follow",
          body: JSON.stringify({
            name: file.name.replace(/\s/g, "_")
          })
        }
      );
      const result = await response.json();
      if (result.error) throw result.error;
      const { url, fields, attachmentId } = result;
      let formData = new FormData();
      Object.keys(fields).forEach((key) => {
        const field = fields[key];
        formData.append(key, field);
      });
      formData.append("file", file, file.name);
      const upload = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          "x-amz-server-side-encryption": `AES256`
        }
      });
      return {
        file,
        attachmentId,
        error: !upload.ok
      };
    } catch (e) {
      this.errorNotification(
        e.message,
        this.getLanguageLabel("ERROR_SAVING_ATTACHMENT_LABEL", {
          fileName: file.name
        })
      );
    }
  };

  addAttachmentsToFeature = async (
    feature,
    layerTitle,
    dataSetName,
    altPropId
  ) => {
    try {
      const layer = this.getLayerByTitle(layerTitle);
      const { updateWorkflowState, workflowState } = this.props;
      const { importFiles } = workflowState;
      const attachmentResults = await Promise.all(
        importFiles.map(async (file) => {
          const result = await this.addDatasetsAttachment(
            feature.attributes.objectId,
            layer.layerId,
            dataSetName,
            file
          );
          const { attachmentId, error } = result;
          if (!error) {
            const attachment = await this.getAttachmentById(
              feature.attributes.objectId,
              layer.layerId,
              dataSetName,
              attachmentId,
              altPropId
            );
            if (!attachment || attachment.error) result.error = true;
            if (!error)
              updateWorkflowState({
                importFiles: this.props.workflowState.importFiles.filter(
                  (importFile) => file != importFile
                ),
                attachments: this.props.workflowState.attachments
                  ? [...this.props.workflowState.attachments, attachment]
                  : [attachment]
              });
          }

          return result;
        })
      );
      return attachmentResults;
    } catch (e) {
      throw e;
    }
  };

  handleClearFile = (file) => {
    const { updateWorkflowState, workflowState } = this.props;
    const { importFiles } = workflowState;
    updateWorkflowState({
      importFiles: importFiles.filter((item) => item != file)
    });
  };

  handleSelectFile = (file) => {
    if (!file) return;
    const { updateWorkflowState, workflowState } = this.props;
    const { importFiles } = workflowState;

    updateWorkflowState({
      importFiles: importFiles ? [...importFiles, file] : [file]
    });
  };

  getAttachmentsByFeatureId = async (
    featureId,
    layerTitle,
    dataSetName,
    altPropId
  ) => {
    const { updateWorkflowState, webMap, getLayerName } = this.props;
    const layer = webMap.layers.items.find(
      (layer) => layer.title === getLayerName(layerTitle)
    );
    let attachments;
    const attachmentResults = await this.getAllFeatureAttachments(
      featureId,
      layer.layerId,
      dataSetName,
      altPropId
    );

    attachments = await Promise.all(
      attachmentResults.map(async (result) => {
        const info = await this.getAttachmentById(
          featureId,
          layer.layerId,
          dataSetName,
          result.attachmentId,
          altPropId
        );
        return info;
      })
    );

    updateWorkflowState({
      attachments
    });
  };

  handleClearExistingAttachment = (file) => {
    const { updateWorkflowState, workflowState } = this.props;
    const { filesToDelete, attachments } = workflowState;
    updateWorkflowState({
      filesToDelete: filesToDelete ? [...filesToDelete, file] : [file],
      attachments: attachments.filter((item) =>
        item.id && file.id
          ? item.id !== file.id
          : item.attachmentId !== file.attachmentId
      )
    });
  };

  getHandleSelectedActiveFile = (handleSelectedActiveFile) => {
    if (handleSelectedActiveFile) {
      return (e) => {
        handleSelectedActiveFile(e);
      };
    }
    return null;
  };

  attachmentInfos = (handleSelectedActiveFile) => {
    const {
      labels: {
        ADD_ATTACHMENTS_HEADING_LABEL,
        CLEAR_ATTACHMENT_LABEL,
        DROPZONE_LABEL
      },
      workflowState: { importFiles, attachments, filesToDelete }
    } = this.props;
    return {
      handleSelectFile: this.handleSelectFile,
      handleClearFile: this.handleClearFile,
      handleClearExistingFile: this.handleClearExistingAttachment,
      handleSelectedActiveFile: this.getHandleSelectedActiveFile(
        handleSelectedActiveFile
      ),
      importFiles,
      attachments,
      filesToDelete,
      labels: {
        add: ADD_ATTACHMENTS_HEADING_LABEL,
        clear: CLEAR_ATTACHMENT_LABEL,
        drop: DROPZONE_LABEL
      }
    };
  };

  newFeatureAttachmentInfos = () => {
    const {
      labels: {
        ADD_ATTACHMENTS_HEADING_LABEL,
        CLEAR_ATTACHMENT_LABEL,
        DROPZONE_LABEL
      },
      workflowState: { importFiles }
    } = this.props;
    return {
      handleSelectFile: this.handleSelectFile,
      handleClearFile: this.handleClearFile,
      importFiles,
      labels: {
        add: ADD_ATTACHMENTS_HEADING_LABEL,
        clear: CLEAR_ATTACHMENT_LABEL,
        drop: DROPZONE_LABEL
      }
    };
  };

  resetAttachmentWorkflowState = () => {
    const { updateWorkflowState } = this.props;
    updateWorkflowState({
      importFiles: [],
      attachments: [],
      filesToDelete: []
    });
  };

  getLayerByTitleAndGeometry = (title, geometry) => {
    const { webMap } = this.props;
    const layer = webMap.layers.items.find(
      (layer) =>
        layer.title &&
        layer.title.includes(title) &&
        layer.geometryType === geometry
    );
    return layer;
  };

  selectWorkflow = () => {
    const { workflowId, propId, groupId, orgId, selectWorkflow } = this.props;
    selectWorkflow(workflowId, propId, groupId, orgId);
  };

  isGraphicSelected = (objectId) => {
    const {
      workflowState: { graphics },
      objectIdField
    } = this.props;
    if (!graphics || graphics.length === 0) return false;
    const isSelected = graphics.find(
      (graphic) => graphic.attributes[objectIdField] === objectId
    );
    if (isSelected) return true;
    return false;
  };

  getSelectedGraphic = (objectId) => {
    const {
      workflowState: { graphics },
      objectIdField
    } = this.props;
    return graphics.find(
      (graphic) => graphic.attributes[objectIdField] === objectId
    );
  };

  whenNoSelectedGraphics = () => {
    const { graphics, sketchGraphics } = this.props.workflowState;
    if (!sketchGraphics && !graphics) return true;
    else if (!sketchGraphics && graphics) return graphics.length === 0;
    else if (!graphics && sketchGraphics) return sketchGraphics.length === 0;
    else return sketchGraphics.length === 0 && graphics.length === 0;
  };

  whenAnyGraphicsSelected = () => {
    const { workflowState } = this.props;
    const { graphics } = workflowState;
    if (!graphics) return false;
    return graphics.length > 0;
  };

  whenAllGraphicsSelected = (selectableFeatures) => {
    const { workflowState } = this.props;
    const { graphics } = workflowState;
    if (!graphics) return false;
    return graphics.length === selectableFeatures.length;
  };

  hasSketchGraphic = () => {
    const { sketchGraphics } = this.props.workflowState;
    return sketchGraphics && sketchGraphics.length > 0;
  };

  handleSelectAllFeatures = (features) => {
    const { updateWorkflowState } = this.props;

    const selectedGraphics = features.map((feature) => {
      const selectedFeature = feature.clone();
      selectedFeature.symbol = CROP_MERGE_SELECTED_SYMBOL;
      return selectedFeature;
    });

    updateWorkflowState({ graphics: selectedGraphics });

    return selectedGraphics;
  };

  resetGraphicsWorkflowState = () => {
    const { updateWorkflowState } = this.props;
    updateWorkflowState({ graphics: [], sketchGraphics: [] });
  };

  handleSelectGraphic = async (graphic, canSelectMultiple = true) => {
    const {
      removeGraphicFromWorkflowState,
      addGraphicToWorkflowState,
      workflowState,
      objectIdField,
      updateWorkflowState
    } = this.props;
    let graphics = [...workflowState.graphics];
    if (this.isGraphicSelected(graphic.attributes[objectIdField])) {
      const selectedGraphic = this.getSelectedGraphic(
        graphic.attributes[objectIdField]
      );

      removeGraphicFromWorkflowState(selectedGraphic.uid);
      graphics = graphics.filter(
        (item) =>
          item.attributes[objectIdField] !==
          selectedGraphic.attributes[objectIdField]
      );
      return graphics;
    }
    const selectionSymbol =
      graphic.geometry.type === GEOMETRY_TYPE_POINT
        ? CROP_POINT_EVENT_SELECTION_SYMBOL
        : CROP_SELECTION_SYMBOL;
    const selectedGraphic = graphic.clone();
    selectedGraphic.symbol = selectionSymbol;

    if (canSelectMultiple) {
      addGraphicToWorkflowState(selectedGraphic);
      graphics = [...graphics, selectedGraphic];
    } else {
      updateWorkflowState({
        graphics: [selectedGraphic]
      });
      graphics = [selectedGraphic];
    }

    return graphics;
  };

  loadGraphic = async (graphic) => {
    try {
      const { objectIdField } = this.props;
      const { attributes, sourceLayer } = graphic;
      const queryParams = sourceLayer.createQuery();
      queryParams.where = `${objectIdField} = ${attributes[objectIdField]}`;
      queryParams.outFields = "*";
      const { features } = await sourceLayer.queryFeatures(queryParams, {
        signal: this.controller.signal
      });
      return features[0];
    } catch (e) {
      if (e.name !== ABORT_ERROR_NAME) {
        console.log(e);
      }
      return null;
    }
  };

  createSelectableGraphics = (features) => {
    const graphics = features.map((feature) => {
      return new Graphic({
        attributes: feature.attributes,
        geometry: feature.geometry,
        symbol: CROP_BLOCK_SELECTABLE_SYMBOL,
        sourceLayer: feature.layer,
        visible: true
      });
    });

    return graphics;
  };

  handleAddNewFeature = async (
    layerTitle,
    feature,
    successMessage,
    showNotification,
    editType = null
  ) => {
    const { getLayerName } = this.props;
    const newFeatures = Array.isArray(feature)
      ? feature.map((item) => this.updateNullValues(item))
      : [this.updateNullValues(feature)];

    const results = await this.layerApplyEdits(
      layerTitle,
      { adds: newFeatures },
      successMessage &&
        (isNullOrUndefined(showNotification) || showNotification === true)
        ? successMessage
        : null,
      `${this.getLanguageLabel(
        "NOTIFICATION_ERROR_LABEL_ADD_FEATURE"
      )}: title '${newFeatures[0].attributes.title}', layer '${getLayerName(
        layerTitle
      )}'`,
      editType
    );
    return results;
  };

  getRequiredHiddenAttributes = (selectedGraphics) => {
    const hiddenAttributes = this.getHiddenAttributes();
    const requiredAttributes = this.getRequiredAttributes();
    const defaultValues = Object.keys(this.getDefaultValues());
    if (!hiddenAttributes) return {};
    const hiddenRequiredAttributes = hiddenAttributes.filter(
      (attr) =>
        requiredAttributes.indexOf(attr) !== -1 &&
        defaultValues.indexOf(attr) !== -1
    );
    if (hiddenRequiredAttributes.length === 0) return {};

    const newAttributes = hiddenRequiredAttributes.reduce(
      (result, attribute) => ({
        ...result,
        [attribute]: null
      }),
      {}
    );

    return newAttributes;
  };

  createNewFeature = (
    layerTitle,
    selectedGraphics,
    existingAttributes = {}
  ) => {
    const layer = this.getLayerByTitle(layerTitle);
    const { propId } = this.props;
    const setId = this.generateSetId();
    const feature = {
      layer,
      attributes: {
        propId,
        setId,
        ...this.getRequiredHiddenAttributes(selectedGraphics),
        ...this.setupAttributes(layer, existingAttributes),
        customData: this.setUpCustomAttributes(existingAttributes)
      }
    };

    return this.applyDefaultValues(feature, selectedGraphics);
  };

  setUpCustomAttributes = (existingAttributes = {}) => {
    const customAttributes = this.getCustomAttributes();
    const parsedCustomData =
      existingAttributes && existingAttributes.customData
        ? JSON.parse(existingAttributes.customData)
        : {};
    const useNewAddMode = this.getUseNewAddMode();
    const createFields = this.getCreateFields();
    const customDataDefault = customAttributes
      ? Object.keys(customAttributes).reduce((result, attribute) => {
          let value = null;
          if (
            useNewAddMode &&
            createFields.includes(attribute) &&
            !isNullOrUndefined(parsedCustomData[attribute]) &&
            !isNullOrUndefined(parsedCustomData[attribute].value)
          ) {
            value = parsedCustomData[attribute].value;
          }
          return {
            ...result,
            [attribute]: {
              ...customAttributes[attribute],
              value
            }
          };
        }, {})
      : {};
    return JSON.stringify(customDataDefault);
  };

  getCreateFields = () => {
    const { createFields } = this.props.workflowState;
    return createFields || [CATEGORY_FIELD];
  };

  setupAttributes = (layer, existingAttributes = {}) => {
    if (!layer) return {};
    const { objectIdField } = this.props;
    const { fields } = layer;
    if (!fields) return {};
    const hiddenFields = [
      objectIdField,
      "createdDate",
      "createdUser",
      "propId",
      "lastEditDate",
      "lastEditUser",
      CUSTOM_DATA_FIELD,
      "deletedDate",
      "removedBy"
    ];

    const useNewAddMode = this.getUseNewAddMode();
    const createFields = this.getCreateFields();
    const newAttributes = fields
      .filter((field) => hiddenFields.indexOf(field.name) === -1)
      .reduce((result, { name }) => {
        let value = null;
        if (
          useNewAddMode &&
          createFields.includes(name) &&
          !isNullOrUndefined(existingAttributes[name])
        ) {
          value = existingAttributes[name];
        }
        return {
          ...result,
          [name]: value
        };
      }, {});
    return newAttributes;
  };

  getDefaultValues = () => {
    const { defaultValues } = this.props.workflowState;
    const customDataSchema = this.customDataSchemaDetails();
    if (customDataSchema && customDataSchema.defaultValues)
      return customDataSchema.defaultValues;
    return defaultValues ? defaultValues : {};
  };

  applyDefaultValues = (feature, selectedGraphics) => {
    const defaultValues = this.getDefaultValues();
    const graphicsSelected =
      selectedGraphics && selectedGraphics.length
        ? selectedGraphics[0].layer.renderer.uniqueValueInfos
            .map((graphic) => graphic.label)
            .filter((items) => items !== null)
            .join(", ")
        : [];
    if (!defaultValues) return feature;

    const customData =
      feature.attributes.customData &&
      isValidJSON(feature.attributes.customData)
        ? JSON.parse(feature.attributes.customData)
        : {};

    const featureCopy = feature.clone ? feature.clone() : { ...feature };
    featureCopy.geometry =
      selectedGraphics && selectedGraphics.length
        ? selectedGraphics[0].geometry
        : null;

    Object.keys(defaultValues).forEach((key) => {
      const isCustomField = Object.prototype.hasOwnProperty.call(
        customData,
        key
      );
      if (
        !isNullOrUndefined(feature.attributes[key]) ||
        (!isNullOrUndefined(customData[key]) &&
          !isNullOrUndefined(customData[key].value))
      )
        return;
      if (
        Object.prototype.hasOwnProperty.call(feature.attributes, key) ||
        isCustomField
      ) {
        const attributeDomainValues = this.getAttributeDomainValues(
          key,
          featureCopy
        );
        const defaultValue = getFormattedDefaultValue(
          feature,
          defaultValues[key],
          null,
          attributeDomainValues,
          graphicsSelected
        );
        if (isCustomField)
          customData[key] = {
            ...(customData[key] || {}),
            value: defaultValue
          };
        else feature.attributes[key] = defaultValue;
      }
    });

    feature.attributes.customData = JSON.stringify(customData);
    return feature;
  };

  getPropertyFeatures = async () => {
    const { propId, webMap } = this.props;
    const propertyLayer = this.getLayerByTitle("property");
    try {
      const query = propertyLayer.createQuery();
      query.where = `propId = '${propId}'`;
      query.returnGeometry = true;
      query.outFields = "*";
      const queryResults = await propertyLayer.queryFeatures(query, {
        signal: this.controller.signal
      });
      const { features } = queryResults;
      if (!features) return;

      return features;
    } catch (e) {
      if (e.name !== ABORT_ERROR_NAME) {
        if (process.env.NODE_ENV === "development") console.log(e);
      }
    }
  };

  isField = (feature, attr) => {
    const customAttributes = this.getCustomAttributes();
    if (Object.prototype.hasOwnProperty.call(feature.attributes, attr))
      return true;
    else if (Object.prototype.hasOwnProperty.call(customAttributes, attr))
      return true;
    else if (feature.layer && feature.layer.fields) {
      return feature.layer.fields.find((field) => field.name === attr);
    } else if (
      feature.attributes.customData &&
      isValidJSON(feature.attributes.customData) &&
      Object.prototype.hasOwnProperty.call(
        JSON.parse(feature.attributes.customData),
        attr
      )
    )
      return true;
    return false;
  };

  whenMissingValidation = (feature, altValidationRules) => {
    const validationRules = altValidationRules
      ? altValidationRules
      : this.explicitValidationRules(feature);
    if (!validationRules || !feature) return false;
    const customAttributes = this.getCustomAttributes();
    return !Object.keys(validationRules).every((attr) => {
      const rules = validationRules[attr];
      if (!this.isApplicableAttribute(attr, feature)) return true;
      if (
        !Object.prototype.hasOwnProperty.call(customAttributes, attr) &&
        (!feature.layer ||
          !feature.layer.fields ||
          !feature.layer.fields.find((field) => field.name === attr))
      )
        return true;
      let value;
      let type = "string";
      if (rules.max != null || rules.min != null) {
        type = "integer";
      } else if (this.getAttributeDomainValues(attr, feature).length) {
        type = "select";
      }
      if (Object.prototype.hasOwnProperty.call(feature.attributes, attr)) {
        value = feature.attributes[attr];
      } else {
        const customData =
          feature.attributes.customData &&
          isValidJSON(feature.attributes.customData)
            ? JSON.parse(escapeJSONNewLine(feature.attributes.customData))
            : {};
        if (customData && customData[attr]) {
          value = customData[attr].value;
          if (customData[attr].type) type = customData[attr].type;
        }
      }
      return isValid(value, rules, type);
    });
  };

  getSearchTypes = () => {
    const { searchTypes } = this.props.workflowState;
    return searchTypes && searchTypes.length > 0
      ? searchTypes
      : [FEATURE_TEXT_SEARCH];
  };

  graphState = () => {
    const { graph } = this.props.workflowState;
    return graph && graph !== {} ? graph : false;
  };

  selectedGraph = (selectedOption) => {
    const graph = this.graphState();
    if (!graph) return false;
    return graph[selectedOption];
  };

  getChartType = (selectedOption) => {
    const selectedGraph = this.selectedGraph(selectedOption);
    if (!selectedGraph) return null;
    const { type } = selectedGraph;
    return type ? type : "pie";
  };

  getGraphData = async (features, field, displayBy, areaUnit) => {
    if (!field || !features) return [];

    let results = [];
    if (displayBy && displayBy === "area") {
      results = await this.sortResultsByArea(features, field, areaUnit);
    } else {
      results = this.sortResultsByFeatureCount(features, field);
    }

    return results;
  };

  sortResultsByArea = async (features, field, areaUnit) => {
    const unit = areaUnit ? areaUnit : this.areaUnit();
    const domainValues = this.getDomainValues();
    const fieldDomainValues =
      domainValues && domainValues[field] ? domainValues[field] : false;
    //sort features by field

    let featuresByField = {};
    for (let feature of features) {
      let fieldValue;
      const { attributes } = feature;
      const customData = attributes.customData
        ? JSON.parse(attributes.customData)
        : false;
      if (attributes[field]) {
        fieldValue = attributes[field];
      } else if (customData && customData[field] && customData[field].value) {
        fieldValue = customData[field].value;
      } else fieldValue = this.noValueTitle(field);

      const areaOfFeature = feature.geometry
        ? await this.calculateTotalArea(feature.geometry, unit)
        : 0;

      const value = Object.prototype.hasOwnProperty.call(
        featuresByField,
        fieldValue
      )
        ? featuresByField[fieldValue] + areaOfFeature
        : areaOfFeature;
      featuresByField[fieldValue] = value;
    }

    const data = Object.keys(featuresByField).map((key) => {
      const domainValue =
        fieldDomainValues &&
        fieldDomainValues.find((domain) => domain.value === key);
      return {
        x: key,
        xTitle: `${unit} ${
          domainValue && domainValue.title ? domainValue.title : key
        }`,
        y: roundNumber(featuresByField[key])
      };
    });
    return data;
  };

  sortResultsByFeatureCount = (features, field) => {
    const domainValues = this.getDomainValues();
    const fieldDomainValues =
      domainValues && domainValues[field] ? domainValues[field] : false;
    const values = features.map((feature) => {
      const { attributes } = feature;
      const customData = attributes.customData
        ? JSON.parse(attributes.customData)
        : false;
      if (attributes[field]) {
        return attributes[field];
      } else if (customData && customData[field] && customData[field].value) {
        return customData[field].value;
      } else return this.noValueTitle(field);
    });
    return [...new Set([...values])].map((x) => {
      const domainValue =
        fieldDomainValues &&
        fieldDomainValues.find((domain) => domain.value === x);

      return {
        x,
        xTitle: domainValue && domainValue.title ? domainValue.title : x,
        y: values.join().split(x).length - 1
      };
    });
  };

  noValueTitle = (selectedOption) => {
    const selectedGraph = this.selectedGraph(selectedOption);
    if (!selectedGraph) return "";
    const { defaultValue } = selectedGraph;
    return defaultValue;
  };

  graphColorScale = async (features, selectedOption) => {
    const selectedGraph = this.selectedGraph(selectedOption);
    if (!selectedGraph) return [];
    const { dataValues, defaultValue } = selectedGraph;
    const data = await this.getGraphData(features, selectedOption);

    const colorScale = data.map((item) => {
      const colorValue = dataValues[item.x]
        ? dataValues[item.x]
        : dataValues[defaultValue]
        ? dataValues[defaultValue]
        : [128, 128, 128];

      return `rgb(${colorValue.toString()})`;
    });

    return colorScale;
  };

  graphTitle = (selectedOption) => {
    const selectedGraph = this.selectedGraph(selectedOption);
    if (!selectedGraph) return "";
    const { title } = selectedGraph;
    return title;
  };

  graphDesc = (selectedOption) => {
    const selectedGraph = this.selectedGraph(selectedOption);
    if (!selectedGraph) return "";
    const { description } = selectedGraph;
    return description;
  };

  graphDisplayOptions = () => {
    const graph = this.graphState();
    if (!graph) return [];
    const options = Object.keys(graph).map((key) => ({
      value: key,
      title: graph[key].title
    }));
    return options;
  };

  updateRenderer = (layerTitle, selectedOption) => {
    const selectedGraph = this.selectedGraph(selectedOption);
    const layer = this.getLayerByTitle(layerTitle);
    if (!selectedGraph || !layer) return;
    const { dataValues, defaultValue } = selectedGraph;
    if (!dataValues) return;
    const fieldValue = selectedOption;
    const symbolType =
      layer.geometryType === "point"
        ? "simple-marker"
        : layer.geometryType === "polyline"
        ? "simple-line"
        : "simple-fill";
    const renderer = {
      type: "unique-value",
      field: fieldValue,
      defaultSymbol:
        dataValues && defaultValue && dataValues[defaultValue]
          ? {
              type: symbolType,
              color: [...dataValues[defaultValue], 0.6],
              outline:
                layer.geometryType === "polyline"
                  ? null
                  : {
                      color: [...dataValues[defaultValue], 1],
                      width: 2
                    }
            }
          : layer.renderer.defaultSymbol,
      uniqueValueInfos: Object.keys(dataValues).map((key) => ({
        value: key,
        symbol: {
          type: symbolType,
          color: [...dataValues[key], 0.6],
          outline:
            layer.geometryType === "polyline"
              ? null
              : {
                  color: [...dataValues[key], 1],
                  width: 2
                }
        }
      }))
    };
    layer.renderer = renderer;
  };

  getMatchingUnit = () => {
    const { selectedOrganisation } = this.props;
    const { units } = selectedOrganisation.preferences;
    const { preferredAreaUnit, areaUnits } = units;
    const matchingUnit =
      preferredAreaUnit !== ""
        ? areaUnits.find((unit) => unit.unit === preferredAreaUnit)
        : null;
    return matchingUnit;
  };

  areaUnit = () => {
    const { selectedOrganisation } = this.props;
    if (!selectedOrganisation || !selectedOrganisation.preferences)
      return UNIT_HECTARES;
    const { units } = selectedOrganisation.preferences;
    if (!units) return UNIT_HECTARES;
    return units && units.preferredAreaUnit && this.getMatchingUnit()
      ? this.getMatchingUnit().unit
      : units.areaUnits && units.areaUnits.length > 0
      ? units.areaUnits[0].unit
      : UNIT_HECTARES;
  };

  getAreaDisplayUnit = () => {
    const { selectedOrganisation } = this.props;
    const { units } = selectedOrganisation.preferences;
    if (!units) return DEFAULT_UNIT_HECTARES;
    const areaUnit = this.areaUnit();
    const unitItem = units.areaUnits
      ? units.areaUnits.find((item) => item.unit === areaUnit)
      : null;
    if (!unitItem || !unitItem.shortname) return areaUnit;
    return unitItem.shortname;
  };

  totalAreaString = async (geometry, unit) => {
    const totalArea = await this.calculateTotalArea(geometry, unit);
    const areaUnit = unit ? unit : this.areaUnit();
    if (totalArea) {
      return `${totalArea} ${areaUnit}`;
    } else return this.getLanguageLabel("TOTAL_AREA_ERROR_MESSAGE_LABEL");
  };

  calculateTotalArea = async (geometry, unit) => {
    if (!geometry) return 0;
    const areaUnit = unit ? unit : this.areaUnit();
    const projectedGeometry = await this.projectToSpatialReference([geometry]);
    if (!projectedGeometry || !projectedGeometry.length) return 0;
    const area = planarArea(projectedGeometry[0], areaUnit);
    return roundNumber(area);
  };

  calculateLength = async (geometry, unit) => {
    const projectedGeometry = await this.projectToSpatialReference([geometry]);
    if (!projectedGeometry || !projectedGeometry.length) return 0;
    return roundNumber(planarLength(projectedGeometry[0], unit));
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  slugInformation = (featureSlug) => {
    const [objectId, layerTitle] = featureSlug.split("-");
    return { objectId: objectId ? Number(objectId) : null, layerTitle };
  };

  noGraphicsModal = (isOpen, redirectPath, buttonTitle, message, docTitle) => (
    <Modal
      body={this.getErrorModalBody(
        message ? message : this.getLanguageLabel("NO_GRAPHICS_ERROR_MESSAGE")
      )}
      isOpen={isOpen}
      title={this.getLanguageLabel("ERROR_SOMETHING_WRONG_LABEL")}
      primaryButtonTitle={
        buttonTitle
          ? buttonTitle
          : this.getLanguageLabel("DRAW_NEW_FEATURE_LABEL")
      }
      primaryButtonLink={{
        url: redirectPath ? redirectPath : `../`,
        docTitle: docTitle
          ? docTitle
          : this.getLanguageLabel("CREATE_FEATURE_DOC_TITLE")
      }}
      showCloseButton={false}
    />
  );

  defaultRootUrl = () => {
    const { uri } = this.props;
    return uri;
  };

  getDefaultGeometryFilters = (filterFunction, labels) => [
    {
      title:
        labels && labels.polygon
          ? labels.polygon
          : this.getLanguageLabel("FILTER_POLYGON_DEFAULT_LABEL"),
      type: "checkbox",
      filterType: "geometry",
      field: "geometry",
      value: "polygon",
      disabled: false,
      onClick: filterFunction
    },
    {
      title:
        labels && labels.polyline
          ? labels.polyline
          : this.getLanguageLabel("FILTER_POLYLINE_DEFAULT_LABEL"),
      type: "checkbox",
      filterType: "geometry",
      field: "geometry",
      value: "polyline",
      disabled: false,
      onClick: filterFunction
    },
    {
      title:
        labels && labels.point
          ? labels.point
          : this.getLanguageLabel("FILTER_POINT_DEFAULT_LABEL"),
      type: "checkbox",
      filterType: "geometry",
      field: "geometry",
      value: "point",
      disabled: false,
      onClick: filterFunction
    }
  ];

  getFilteringOptionFields = (field, customDomainValues) => {
    const domainValues = customDomainValues || this.getDomainValues();
    return domainValues &&
      Object.prototype.hasOwnProperty.call(domainValues, field)
      ? domainValues[field]
      : false;
  };

  getFilterFieldTitle = (field) => {
    const displayTitles = this.getDisplayTitles();
    return displayTitles &&
      Object.prototype.hasOwnProperty.call(displayTitles, field)
      ? displayTitles[field]
      : false;
  };

  getActiveFilterValue = (field) => {
    const { filters } = this.props.workflowState;
    if (!field || !filters || filters.length === 0) return null;
    const fieldFilter = filters.find((filter) => filter.field === field);
    if (!fieldFilter) return null;
    return fieldFilter && fieldFilter.value != null ? fieldFilter.value : null;
  };

  isSelectedFieldFilter = (field) => {
    const { filters } = this.props.workflowState;
    if (!field || !filters || filters.length === 0) return false;
    return filters.find((filter) => filter.field === field) !== undefined;
  };

  layerHasDateField = (layer, fieldName) => {
    if (!layer || !fieldName || !layer.fields) return false;
    const field = layer.fields.find((field) => field.name === fieldName);
    if (!field) return false;
    return field.type === "date";
  };

  workflowDateFilters = (filterFunction, layers) => {
    const { filteringOptions } = this.props.workflowState;
    const dateFilterFields =
      filteringOptions && filteringOptions.date ? filteringOptions.date : false;
    if (!dateFilterFields || dateFilterFields.length === 0) return [];

    const dateFilters = dateFilterFields.reduce((result, field) => {
      const title = this.getFilterFieldTitle(field);
      const value = this.getActiveFilterValue(field);
      const anyLayerHasDateField = layers
        ? layers.some((layer) => this.layerHasDateField(layer, field) === true)
        : true;
      if (!anyLayerHasDateField) return result;
      return [
        ...result,
        {
          title: this.getLanguageLabel("FILTER_BY_FIELD_LABEL", {
            title
          }),
          type: "checkbox",
          filterType: "date",
          dateType: DATE_FILTER_TYPE_SINGLE,
          field,
          value: value
            ? value
            : {
                startDate: null,
                endDate: null
              },
          show: true,
          disabled: false,
          onClick: filterFunction,
          checked: this.isSelectedFieldFilter(field)
        }
      ];
    }, []);

    return dateFilters;
  };

  workflowDateRangeFilters = (filterFunction, layers) => {
    const { filteringOptions } = this.props.workflowState;
    const dateRangeFilterFields =
      filteringOptions && filteringOptions.dateRange
        ? filteringOptions.dateRange
        : [];
    if (!dateRangeFilterFields || dateRangeFilterFields.length === 0) return [];

    const dateRangeFilters = dateRangeFilterFields.reduce((result, field) => {
      const title = this.getFilterFieldTitle(field);
      const value = this.getActiveFilterValue(field);
      const anyLayerHasDateField = layers
        ? layers.some((layer) => this.layerHasDateField(layer, field) === true)
        : true;
      if (!anyLayerHasDateField) return result;
      return [
        ...result,
        {
          title: this.getLanguageLabel("FILTER_BY_FIELD_LABEL", {
            title
          }),
          type: "checkbox",
          filterType: "dateRange",
          dateType: DATE_FILTER_TYPE_RANGE,
          field,
          value: value
            ? value
            : {
                startDate: null,
                endDate: null
              },
          show: true,
          disabled: false,
          onClick: filterFunction,
          checked: this.isSelectedFieldFilter(field)
        }
      ];
    }, []);

    return dateRangeFilters;
  };

  workflowFieldFilters = (filterFunction, customDomainValues, layers) => {
    const {
      updateWorkflowState,
      workflowState: { filteringOptions, uniqueDomainValues }
    } = this.props;

    const fields =
      filteringOptions && filteringOptions.fields
        ? filteringOptions.fields
        : false;
    if (!fields) return [];

    const filterFields =
      fields && fields.length > 0
        ? fields.reduce((result, field) => {
            if (
              filteringOptions.uniqueValues?.includes(field) &&
              layers?.length > 0 &&
              !uniqueDomainValues
            ) {
              updateWorkflowState({
                uniqueDomainValues: { [field]: null }
              });
              this.loadUniqueDomainValues(layers, field);
            }

            const domainValues = this.getFilteringOptionFields(
              field,
              uniqueDomainValues || customDomainValues
            );

            if (!domainValues?.length) return result;
            const title = this.getFilterFieldTitle(field);
            const value = this.getActiveFilterValue(field);
            return [
              ...result,
              {
                title: this.getLanguageLabel("FILTER_BY_FIELD_LABEL", {
                  title
                }),
                filterType: "field",
                type: "checkbox",
                field,
                value: value ? value : "-",
                show: true,
                disabled: false,
                onClick: filterFunction,
                domainValues,
                checked: this.isSelectedFieldFilter(field)
              }
            ];
          }, [])
        : [];

    return filterFields;
  };

  geometryFilters = (filterFunction, labels) => {
    const { filteringOptions } = this.props.workflowState;
    const hasGeometryOptions = filteringOptions
      ? filteringOptions.geometry != null
      : false;
    if (!hasGeometryOptions) return [];
    const defaultGeometryOptions = this.getDefaultGeometryFilters(
      filterFunction,
      labels
    );
    const geometryOptions = defaultGeometryOptions.filter(
      (option) => filteringOptions.geometry.indexOf(option.value) !== -1
    );
    return geometryOptions;
  };

  workflowFeatureServicesLayersFilter = (filterFunction, layers) => {
    const { filteringOptions } = this.props.workflowState;

    const featureFiltersFields =
      filteringOptions && filteringOptions.featureServicesLayers
        ? filteringOptions.featureServicesLayers
        : false;
    if (!featureFiltersFields || featureFiltersFields.length === 0) return [];

    const featureFilters = featureFiltersFields.reduce((result, field) => {
      return [
        ...result,
        {
          title: this.getLanguageLabel("HIDE_BY_FIELD_LABEL", {
            title: field.title
          }),
          type: "checkbox",
          filterType: "geometry",
          show: true,
          value: field.type,
          field: "geometry",
          disabled: false,
          onClick: filterFunction
        }
      ];
    }, []);
    return featureFilters;
  };

  workflowSpatialFilters = (filterFunction) => {
    const { filteringOptions } = this.props.workflowState;

    const spatialLayerFilters =
      filteringOptions && filteringOptions.spatialLayers
        ? filteringOptions.spatialLayers
        : false;
    if (!spatialLayerFilters || spatialLayerFilters.length === 0) return [];

    return spatialLayerFilters.reduce((result, filter) => {
      const { field, title } = filter;
      const domainValues = this.getFilteringOptionFields(field);
      if (!domainValues || domainValues.length === 0) return result;
      const filterTitle = title ? title : this.getFilterFieldTitle(field);
      const value = this.getActiveFilterValue(field);
      return [
        ...result,
        {
          title: this.getLanguageLabel("FILTER_BY_FIELD_LABEL", {
            title: filterTitle
          }),
          type: "checkbox",
          filterType: "spatial",
          field,
          value: value ? value : "-",
          show: true,
          disabled: false,
          onClick: filterFunction,
          domainValues,
          checked: this.isSelectedFieldFilter(field)
        }
      ];
    }, []);
  };

  workflowFilters = (filterFunction, layers, labels, customDomainValues) => {
    return [
      ...this.workflowFieldFilters(filterFunction, customDomainValues, layers),
      ...this.workflowDateFilters(filterFunction, layers),
      ...this.workflowDateRangeFilters(filterFunction, layers),
      ...this.geometryFilters(filterFunction, labels),
      ...this.workflowFeatureServicesLayersFilter(filterFunction, layers),
      ...this.workflowSpatialFilters(filterFunction)
    ];
  };
  getSelectedSearchType = () => {
    const {
      workflowState: { selectedSearchType, searchTypes },
      location
    } = this.props;
    const urlSearchType = getUrlSearchParamByKey(
      location,
      SEARCH_TYPE_URL_PARAM
    );
    if (urlSearchType) return urlSearchType;
    else if (selectedSearchType) return selectedSearchType;
    else if (searchTypes && searchTypes.length) return searchTypes[0];
    else return FEATURE_TEXT_SEARCH;
  };

  handleResetAbortController = () => {
    const { updateWorkflowState, workflowState } = this.props;
    if (!workflowState) return;
    const { workflowAbortController } = workflowState;
    if (workflowAbortController) {
      workflowAbortController.abort();
    }
    updateWorkflowState({
      workflowAbortController: new AbortController()
    });
  };

  handleResetPagination = () => {
    this.setSearchUrlParams(
      this.getSearchText(),
      this.getSelectedSearchType(),
      1
    );
  };

  handleUpdateSearchType = (searchType) => {
    this.handleResetAbortController();
    const { clearFeatureListItems, updateWorkflowState, workflowState } =
      this.props;
    if (searchType !== FEATURE_TEXT_SEARCH) {
      clearFeatureListItems();
    }
    updateWorkflowState({
      listTotal:
        searchType !== FEATURE_TEXT_SEARCH ? 0 : workflowState.listTotal
    });
    this.setSearchUrlParams("", searchType, 1);
  };

  setSearchUrlParams = (
    textSearch = "",
    searchType,
    page = 1,
    limit = null
  ) => {
    const {
      location,
      navigate,
      updateWorkflowState,
      workflowState = {}
    } = this.props;
    const searchTypeParam =
      (searchType === FEATURE_TEXT_SEARCH && textSearch) ||
      searchType !== FEATURE_TEXT_SEARCH
        ? searchType
        : null;
    const pageLimit = limit ? limit : this.featureListLimit();
    setMultipleUrlSearchParamByKeys(location, navigate, [
      {
        key: SEARCH_TYPE_URL_PARAM,
        value: searchTypeParam
      },
      {
        key: SEARCH_TEXT_URL_PARAM,
        value: searchType === FEATURE_TEXT_SEARCH ? textSearch : null
      },
      {
        key: PAGE_PARAM,
        value: workflowState.ignorePagination ? null : page
      },
      {
        key: LIMIT_PARAM,
        value: workflowState.ignorePagination ? null : pageLimit
      }
    ]);
    updateWorkflowState({
      listPage: page,
      listLimit: pageLimit,
      textSearch,
      selectedSearchType: searchType
    });
    if (
      !workflowState.ignorePagination &&
      this.featureListLimit() !== DEFAULT_PAGINATION_STRING
    ) {
      storeSession(LIMIT_PARAM, pageLimit);
    }
  };

  handleUpdateTextSearch = (textSearch) => {
    this.handleResetAbortController();
    this.setSearchUrlParams(textSearch, this.getSelectedSearchType(), 1);
  };

  getSearchText = () => {
    const {
      workflowState: { textSearch },
      location
    } = this.props;
    const urlSearchText = getUrlSearchParamByKey(
      location,
      SEARCH_TEXT_URL_PARAM
    );
    return urlSearchText ? urlSearchText : textSearch ? textSearch : "";
  };

  getFilterExpiredOption = () => {
    const {
      workflowState: { filterExpiredFeatures }
    } = this.props;
    return filterExpiredFeatures === true;
  };

  searchIsActive = () => {
    const selectedSearchType = this.getSelectedSearchType();
    const textSearch = this.getSearchText();
    return selectedSearchType &&
      !(selectedSearchType === FEATURE_TEXT_SEARCH && !textSearch)
      ? true
      : false;
  };

  getOutFields = async (layerTitle) => {
    const {
      workflowState: { filteringOptions, customAttributes }
    } = this.props;
    const layer = this.getLayerByTitle(layerTitle);
    const title = this.getListTitle();
    const subtitleFields = this.getListSubtitles();
    const filterFields =
      filteringOptions && filteringOptions.fields
        ? filteringOptions.fields
        : [];
    if (!layer) return [];
    return await layer.when(() => {
      const startingFields = ["objectId", "expiryDate"].filter(
        (field) =>
          layer.fields &&
          layer.fields.find((layerField) => layerField.name === field)
      );
      return [
        title,
        ...filterFields,
        ...subtitleFields.map(({ field }) => field)
      ].reduce((result, fieldName) => {
        let attribute = fieldName;
        if (attribute.includes("totalArea")) return result;
        if (
          (layer.fields &&
            !layer.fields.find((field) => field.name === fieldName)) ||
          (customAttributes &&
            Object.prototype.hasOwnProperty.call(customAttributes, fieldName))
        ) {
          if (!layer.fields.find((field) => field.name === "customData"))
            return result;
          attribute = "customData";
        }

        if (result.includes(attribute)) return result;
        return [...result, attribute];
        //include expiryDate because when search is enabled expired features should be returned, and some workflows use expiry date for emphasis. This is not yet handled in workflow state so we just have to assume that we need to return expiry date
      }, startingFields);
    });
  };

  loadFeatureArea = async (layerTitle, features) => {
    const { workflowAbortController } = this.props.workflowState;
    const batchSize = 500;
    const batchCount = Math.ceil(features.length / batchSize);
    const array = Array(batchCount)
      .fill(0)
      .map((item, i) => i);
    const results = await Promise.all(
      array.map((i) => {
        const startingIndex = i * batchSize;
        const objectIds = features
          .slice(startingIndex, startingIndex + batchSize)
          .map((feature) => feature.attributes.objectId);
        return this.queryFeaturesByLayerTitle(
          layerTitle,
          {
            where: `objectId IN (${objectIds.join(",")})`,
            outFields: ["objectId"],
            returnGeometry: false
          },
          workflowAbortController.signal
        );
      })
    )
      .then((results) => [].concat(...results))
      .catch((e) => {
        return [];
      });
    return results;
  };

  updateListItemsWithArea = async () => {
    const { featureListItems, updateFeatureListItem } = this.props;
    if (!featureListItems || !featureListItems.length) return;
    const listItemsByLayerTitle = featureListItems.reduce((result, feature) => {
      const { layer } = feature;
      if (!layer || !layer.title) return result;
      return {
        ...result,
        [layer.title]: [...(result[layer.title] || []), feature]
      };
    }, {});

    await Promise.all(
      Object.keys(listItemsByLayerTitle).map((layerTitle) =>
        this.loadFeatureArea(layerTitle, listItemsByLayerTitle[layerTitle])
      )
    ).then((results) => {
      [].concat(...results).forEach((result) => {
        const actualFeature = featureListItems.find(
          (feature) =>
            feature.attributes.objectId === result.attributes.objectId &&
            feature.layer.title === result.layer.title
        );
        const cloned = actualFeature.clone();
        cloned.attributes.featureArea = result.attributes.featureArea;
        updateFeatureListItem(actualFeature);
      });
    });
  };

  getIsSearchingFeatures = () => {
    const { isSearchingFeatures } = this.state;
    return isSearchingFeatures;
  };

  setIsSearchingFeatures = (isSearchingFeatures) => {
    this.setState({
      isSearchingFeatures
    });
  };

  setWorkflowReady = (workflowReady) => {
    this.setState({
      workflowReady
    });
  };

  getWorkflowReady = () => {
    const { workflowReady } = this.state;
    return workflowReady;
  };

  setInitialQuerySettings = (settings = {}) => {
    this.setWorkflowReady(false);
    const { updateWorkflowState } = this.props;
    const page = getUrlSearchParamByKey(window.location, PAGE_PARAM);
    const textSearch =
      getUrlSearchParamByKey(window.location, SEARCH_TEXT_URL_PARAM) || "";
    updateWorkflowState(
      {
        graphics: [],
        filters: [],
        textSearch,
        listPage: page ? Number(page) : 1,
        activeDateSearchValue: new Date(),
        sketchGraphics: [],
        listTotal: 0,
        ...settings
      },
      () => this.setWorkflowReady(true)
    );
  };

  initialiseListView = (onComplete) => {
    if (!this.getWorkflowReady())
      return this.setState({
        onWorkflowReady: onComplete
      });
    this.onListViewReady(onComplete);
  };

  onListViewReady = (onComplete) => {
    const {
      updateWorkflowState,
      workflowState: { listQueryOptions }
    } = this.props;
    const selectedSearchType = this.getSelectedSearchType();
    if (
      !selectedSearchType ||
      !listQueryOptions ||
      !Object.keys(listQueryOptions).some(
        (layerTitle) => listQueryOptions[layerTitle].geometry
      )
    )
      updateWorkflowState(
        {
          workflowAbortController: new AbortController()
        },
        onComplete
      );
    else this.resetSearchQuery(onComplete);
    this.setState({
      onWorkflowReady: null
    });
  };

  getSelectedPropertyUsers = () => {
    const { selectedPropertyUsersLoading, selectedPropertyUsers } = this.props;
    return selectedPropertyUsersLoading ? [] : selectedPropertyUsers;
  };

  getAvailablePropertyGroups = () => {
    const { availablePropertyGroups } = this.props;
    return availablePropertyGroups;
  };

  getListSortOrder = () => {
    const { workflowState } = this.props;
    if (
      workflowState &&
      workflowState.featureList &&
      workflowState.featureList.sort
    ) {
      return workflowState.featureList.sort;
    }
    return false;
  };

  getPropsForPlugin = () => {
    const {
      workflowState,
      workflowCommand,
      webMap,
      mapView,
      selectedOrganisation,
      selectedProperty,
      user,
      updateWorkflowState,
      addGraphicToWorkflowState,
      featureListItems,
      setWorkflowSize,
      clearFeatureListItems,
      updateFeatureListItem,
      basemapId,
      setBasemap,
      selectedPropertyGroup,
      renderers,
      labels,
      updateSelectedPropertyDetails,
      updateAvailableProperty,
      getLayerName,
      objectIdField,
      has3D,
      startRequest,
      requestInfo,
      selectedWorkflow,
      propertySpatialReference,
      orgSpatialReference,
      sendActionLog,
      orgId,
      pdfResults,
      updatePrintResults,
      getPropertyGroupsData,
      getImageryDatesForProperty
    } = this.props;

    return {
      workflowState,
      workflowCommand,
      core: {
        webMap,
        mapView,
        updateWorkflowState,
        notification: this.handleNotification,
        addGraphicToWorkflowState,
        makeLayerInteractive: this.makeLayerInteractive,
        makeMultipleLayersInteractive: this.makeMultipleLayersInteractive,
        zoomToFeatures: this.zoomToFeatures,
        zoomToPropertyBoundary: this.zoomToPropertyBoundary,
        selectedWorkflow,
        queryFeaturesByLayerTitle: this.queryFeaturesByLayerTitle,
        loadFeatures: this.loadFeatures,
        setVisibleLayers: this.setVisibleLayers,
        listTitle: this.getListTitle,
        listSubTitles: this.getListSubtitles,
        getGeometryEmphasis: this.getGeometryEmphasis,
        getExpiredEmphasis: this.getExpiredEmphasis,
        getDetailsSettings: this.getDetailsSettings,
        handleSaveUpdateFeature: this.handleSaveUpdateFeature,
        handleDeleteFeature: this.handleDeleteFeature,
        setLayerFilterByTitle: this.setLayerFilterByTitle,
        setLayerVisibilityByTitle: this.setLayerVisibilityByTitle,
        getFeatureTitle: this.getFeatureTitle,
        getLayerByTitle: this.getLayerByTitle,
        getMultipleLayersByTitles: this.getMultipleLayersByTitles,
        removeLayerByTitle: this.removeLayerByTitle,
        createGraphicsLayer: this.createGraphicsLayer,
        createFeatureLayer: this.createFeatureLayer,
        addLayersToWebMap: this.addLayersToWebMap,
        addGraphicsToGraphicsLayer: this.addGraphicsToGraphicsLayer,
        addAttachmentsToFeature: this.addAttachmentsToFeature,
        getAttachmentsByFeatureId: this.getAttachmentsByFeatureId,
        deleteAttachments: this.deleteAttachments,
        attachmentInfos: this.attachmentInfos,
        resetAttachmentWorkflowState: this.resetAttachmentWorkflowState,
        getLayerByTitleAndGeometry: this.getLayerByTitleAndGeometry,
        isGraphicSelected: this.isGraphicSelected,
        getSelectedGraphic: this.getSelectedGraphic,
        whenNoSelectedGraphics: this.whenNoSelectedGraphics,
        whenAnyGraphicsSelected: this.whenAnyGraphicsSelected,
        whenAllGraphicsSelected: this.whenAllGraphicsSelected,
        hasSketchGraphic: this.hasSketchGraphic,
        handleSelectAllFeatures: this.handleSelectAllFeatures,
        resetGraphicsWorkflowState: this.resetGraphicsWorkflowState,
        createSelectableGraphics: this.createSelectableGraphics,
        handleSelectGraphic: this.handleSelectGraphic,
        handleAddNewFeature: this.handleAddNewFeature,
        createNewFeature: this.createNewFeature,
        removeManyLayersByTitles: this.removeManyLayersByTitles,
        getPropertyFeatures: this.getPropertyFeatures,
        newFeatureAttachmentInfos: this.newFeatureAttachmentInfos,
        whenMissingValidation: this.whenMissingValidation,
        getSearchTypes: this.getSearchTypes,
        featureListItems,
        setWorkflowSize,
        clearFeatureListItems,
        errorNotification: this.errorNotification,
        successNotification: this.successNotification,
        warningNotification: this.warningNotification,
        calculateTotalArea: this.calculateTotalArea,
        areaUnit: this.areaUnit,
        getAreaDisplayUnit: this.getAreaDisplayUnit,
        updateFeatureListItem,
        calculateLength: this.calculateLength,
        errorModalBody: this.getErrorModalBody,
        totalAreaString: this.totalAreaString,
        basemapId,
        setBasemap,
        resetPagination: this.handleResetPagination,
        handleChangePerPageValue: this.handleChangePerPageValue,
        handlePagination: this.handlePagination,
        getListFeatureCounts: this.getListFeatureCounts,
        handleStartLoadingFeatures: this.handleStartLoadingFeatures,
        handleUpdateSearchQueries: this.handleUpdateSearchQueries,
        resetSearchQuery: this.resetSearchQuery,
        updateFilters: this.updateFilters,
        featureListLimit: this.featureListLimit,
        perPageOptions: this.perPageOptions,
        firstPerPageOption: this.firstPerPageOption,
        getLanguageLabel: this.getLanguageLabel,
        updateSelectedPropertyDetails,
        updateAvailableProperty,
        getDomainValues: this.getDomainValues,
        getDefaultValues: this.getDefaultValues,
        getDisplayTitles: this.getDisplayTitles,
        getDisplayOrder: this.getDisplayOrder,
        getPlaceholders: this.getPlaceholders,
        getRequiredAttributes: this.getRequiredAttributes,
        getHiddenAttributes: this.getHiddenAttributes,
        getNonEditableAttributes: this.getNonEditableAttributes,
        getContextualAttributes: this.getContextualAttributes,
        getCustomAttributes: this.getCustomAttributes,
        getCustomStatistics: this.getCustomStatistics,
        validationRules: this.explicitValidationRules,
        helpTexts: this.helpTexts,
        getAttributeDomainValues: this.getAttributeDomainValues,
        setUpCustomAttributes: this.setUpCustomAttributes,
        slugInformation: this.slugInformation,
        noGraphicsModal: this.noGraphicsModal,
        defaultRootUrl: this.defaultRootUrl,
        getLayerName,
        objectIdField,
        has3D,
        getMatchingUnit: this.getMatchingUnit,
        getDefaultGeometryFilters: this.getDefaultGeometryFilters,
        workflowFieldFilters: this.workflowFieldFilters,
        workflowFilters: this.workflowFilters,
        queryFeatureDetails: this.queryFeatureDetails,
        softValidationRules: this.softValidationRules,
        getSelectedSearchType: this.getSelectedSearchType,
        handleUpdateSearchType: this.handleUpdateSearchType,
        getSearchText: this.getSearchText,
        handleUpdateTextSearch: this.handleUpdateTextSearch,
        getQueryExpression: this.getQueryExpression,
        getQueryGeometry: this.getQueryGeometry,
        searchIsActive: this.searchIsActive,
        createGraphicsLabels: this.createGraphicsLabels,
        clearLabelGraphics: this.clearLabelGraphics,
        getNotConvertedToLocaleString: this.getNotConvertedToLocaleString,
        getListPage: this.getListPage,
        getUnits: this.getUnits,
        sendActionLog,
        getNextFeature: this.getNextFeature,
        pdfResults,
        updatePrintResults,
        updateListItemsWithArea: this.updateListItemsWithArea,
        isSearching: this.getIsSearchingFeatures(),
        setIsSearchingFeatures: this.setIsSearchingFeatures,
        setWorkflowReady: this.setWorkflowReady,
        workflowReady: this.getWorkflowReady(),
        setInitialQuerySettings: this.setInitialQuerySettings,
        initialiseListView: this.initialiseListView,
        updateLayerRendererWithColors: this.updateLayerRendererWithColors,
        getSelectedPropertyUsers: this.getSelectedPropertyUsers,
        availablePropertyGroups: this.getAvailablePropertyGroups(),
        getPropertyGroupsData,
        getImageryDatesForProperty,
        projectToSpatialReference: this.projectToSpatialReference,
        getListSortOrder: this.getListSortOrder
      },
      context: {
        organisation: {
          orgId,
          ...selectedOrganisation,
          renderers
        },
        property: selectedProperty,
        propertyGroup: selectedPropertyGroup,
        user,
        spatialReference: propertySpatialReference || orgSpatialReference
      },
      permissions: {
        whenReadOnly: this.whenReadOnly,
        hasPermission: this.hasPermission
      },
      graphs: {
        graphColorScale: this.graphColorScale,
        graphDesc: this.graphDesc,
        graphTitle: this.graphTitle,
        selectedGraph: this.selectedGraph,
        graphData: this.getGraphData,
        graphState: this.graphState,
        graphChartType: this.getChartType,
        noValueTitle: this.noValueTitle,
        graphDisplayOptions: this.graphDisplayOptions,
        updateRenderer: this.updateRenderer
      },
      getLabel: labels,
      requests: {
        startRequest,
        ...requestInfo
      }
    };
  };

  hasPermission = (accessKey, defaultValue = false) => {
    if (!accessKey) return defaultValue;
    const { workflowState } = this.props;
    const { permissions } = workflowState;
    if (!permissions) return defaultValue;

    const permissionObject = permissions[accessKey];
    if (!permissionObject) return defaultValue;
    //with new permissions on the role, only the user's role is returned in the permissions arrays, if they have permission, so we can assume that if there is anything in the array then the user has permission
    return permissionObject.length ? true : false;
  };

  makeMultipleLayersInteractive = async (layers, clickHandler) => {
    const allInteractiveLayers = await Promise.all(
      layers.map((layer) => {
        return {
          [layer]: this.makeLayerInteractive(layer, clickHandler)
        };
      })
    );
    this.setState({
      interactiveLayers: Object.assign({}, ...allInteractiveLayers)
    });

    return allInteractiveLayers;
  };
  /**
   *
   * @param {string} layerTitle - the title of the layer to make interactive
   * @param {function} clickHandler - called on click of map view
   * @param {boolean} returnTopFeatureOnly - determines whether to return only the topmost feature of tyhe click results. Defaults to false.
   * @returns
   */

  makeLayerInteractive = (
    layerTitle,
    clickHandler,
    returnTopFeatureOnly = false
  ) => {
    const { getLayerName } = this.props;
    const layerName = getLayerName(layerTitle);
    const interactiveLayer = {
      onClick: clickHandler,
      returnTopFeatureOnly,
      remove: () => this.handleRemoveInteractiveLayer(layerName)
    };
    const { interactiveLayers } = this.state;
    this.setState({
      interactiveLayers: {
        ...interactiveLayers,
        [layerName]: interactiveLayer
      }
    });
    return interactiveLayer;
  };

  zoomToFeatures = async (features) => {
    const { selectedOrganisation, mapView } = this.props;
    const emptyTest =
      (Array.isArray(features) && features.length === 0) || !features;
    if (emptyTest) return;
    const featuresArray = Array.isArray(features) ? features : [features];
    const geometries = featuresArray
      .filter((feature) => feature && feature.geometry)
      .map((feature) => feature.geometry);
    try {
      if (geometries.length) {
        const unionedGeometry = union(
          geometries.map((geometry) => {
            if (geometry.type === GEOMETRY_TYPE_POINT) {
              return buffer(geometry, 200, UNIT_METERS);
            }
            return geometry;
          })
        );

        if (!unionedGeometry) return;

        const { extentBuffer } = selectedOrganisation.preferences;

        const extent = unionedGeometry.extent.clone();
        const bufferValue = extentBuffer ? extentBuffer : 1;
        extent.expand(bufferValue);

        const zoomTarget = {
          target: extent
        };
        if (mapView.width <= 400 && mapView.width < extent.width) {
          zoomTarget.zoom = 16;
        }
        await mapView.goTo(zoomTarget, {
          signal: this.controller.signal,
          pickClosestTarget: false
        });
      }
    } catch (e) {
      if (e.name !== ABORT_ERROR_NAME) {
        if (process.env.NODE_ENV === "development") console.log(e);
      }
    }
  };

  zoomToPropertyBoundary = async () => {
    try {
      let { selectedProperty } = this.props;
      let extent =
        selectedProperty && selectedProperty.extent
          ? selectedProperty.extent
          : null;
      if (!extent) {
        const propertyLayer = await this.getLayerByTitle("propertyPoly");
        if (!propertyLayer) return;
        const extentResult = await propertyLayer.queryExtent(
          {},
          {
            signal: this.controller.signal
          }
        );
        if (!extentResult || !extentResult.extent) return;
        extent = extentResult.extent;
      }
      this.zoomToFeatures([{ geometry: extent }]);
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };

  generateSetId = () => {
    return shortid.generate();
  };

  handleRemoveInteractiveLayer = (layerTitle) => {
    const { interactiveLayers, featureHighlights } = this.state;
    let newFeatureHighlights = { ...featureHighlights };
    const { [layerTitle]: layerToRemove, ...remainingLayer } =
      interactiveLayers;
    if (featureHighlights[layerTitle]) {
      this.handleRemoveSingleLayerHighlights(featureHighlights[layerTitle]);
      delete newFeatureHighlights[layerTitle];
    }
    this.setState({
      interactiveLayers: remainingLayer,
      featureHighlights: newFeatureHighlights
    });
  };

  handleRemoveSingleLayerHighlights = async (items) => {
    const { mapView, webMap } = this.props;
    const { layer } = items[0];
    if (layer.type === "graphics") {
      items.forEach((item) => {
        item.remove();
      });
    } else {
      const actualLayer = webMap.layers.items.find(
        (item) => item.title === layer.title
      );
      if (!actualLayer) return;
      const layerView = await mapView.whenLayerView(actualLayer);
      layerView.featureEffect = null;
    }
  };

  projectToSpatialReference = async (geometries) => {
    if (!geometries || geometries.length === 0) return [];

    const { orgSpatialReference, propertySpatialReference } = this.props;

    const { outGeometries, warning, error } = await projectGeometries(
      geometries,
      propertySpatialReference,
      orgSpatialReference
    );

    if (warning) {
      this.warningNotification(
        `${this.getLanguageLabel(warning.message)}.\n${this.getLanguageLabel(
          "SPATIAL_REFERENCE_CALC_MESSAGE_LABEL",
          warning
        )}`,
        this.getLanguageLabel("UNABLE_TO_PROJECT_LABEL")
      );
    } else if (error) {
      this.errorNotification(
        this.getLanguageLabel(error.message),
        this.getLanguageLabel("ERROR_PROJECTING_SPATIAL_REFERENCE_LABEL")
      );
    }

    return outGeometries;
  };

  isSelectingWorkflow = () => {
    const { selectingWorkflow } = this.props;
    return selectingWorkflow;
  };

  renderLoader = () => {
    return (
      <Loader
        visible={true}
        loadingText={this.getLanguageLabel("LOADING_LABEL")}
      />
    );
  };

  togglePanelButton = () => {
    const { setWorkflowPanelOpen } = this.props;
    const workflowPanelOpen = this.workflowPanelIsOpen();
    setWorkflowPanelOpen(!workflowPanelOpen);
  };

  workflowPanelIsOpen = () => {
    const { workflowPanelOpen } = this.props;
    return workflowPanelOpen === true;
  };

  workflowIsFullWidth = () => {
    const workflowSize = this.getWorkflowSize();
    return workflowSize === PLUGIN_SIZE_FULL;
  };

  getLabelText = () => {
    const isOpen = this.workflowPanelIsOpen();
    return !isOpen
      ? this.getLanguageLabel("SHOW_WORKFLOW_PANEL_LABEL")
      : this.getLanguageLabel("HIDE_WORKFLOW_PANEL_LABEL");
  };

  getUseNextFeatureButton = () => {
    const { useNextFeatureButton } = this.props.workflowState;
    return isNullOrUndefined(useNextFeatureButton)
      ? false
      : useNextFeatureButton;
  };

  loadNextFeatures = async (objectId, layerTitle, signal) => {
    const { pathname } = window.location;
    const {
      workflowState: {
        nextFeatures,
        listQueryOptions,
        activeListLayers = [],
        filters,
        searchParams,
        useSortMethod,
        actualLayerTitles
      },
      getLayerName,
      updateWorkflowState
    } = this.props;
    const layersToUse = actualLayerTitles || activeListLayers;
    const layerTitles = layersToUse.map((layer) => getLayerName(layer));
    if (
      !this.getUseNextFeatureButton() ||
      pathname.includes(MERGE_FEATURES_URL_DELIMITER) ||
      pathname.includes(SPLIT_FEATURES_URL_DELIMITER) ||
      (nextFeatures &&
        nextFeatures.find(
          (feature) =>
            feature.attributes.objectId === objectId &&
            feature.layer.title === layerTitle
        )) ||
      !layerTitles.includes(getLayerName(layerTitle))
    )
      return;

    const updatedNextFeatures = await Promise.all(
      layersToUse.map(async (layerTitle) => {
        const queryOptions = listQueryOptions[layerTitle];
        const queryGeometry = await this.getQueryGeometry(
          queryOptions,
          filters,
          signal
        );
        const queryExpression = this.getQueryExpression(
          layerTitle,
          queryOptions,
          searchParams ? searchParams[layerTitle] : null,
          filters,
          queryGeometry
        );
        return this.queryFeaturesByLayerTitle(
          layerTitle,
          {
            ...queryOptions,
            num: null,
            start: null,
            where: queryExpression,
            geometry: queryGeometry,
            outFields: ["objectId", "title", "category"],
            returnGeometry: false
          },
          signal
        );
      })
    )
      .then((results) => [].concat(...results).filter((result) => result))
      .catch((e) => {
        return [];
      });

    updateWorkflowState({
      nextFeatures: useSortMethod
        ? updatedNextFeatures.sort(sortMethod)
        : updatedNextFeatures
    });
  };

  getNextFeature = (objectId, layerTitles) => {
    const layerTitle = Array.isArray(layerTitles)
      ? layerTitles[0]
      : layerTitles;
    const { nextFeatures } = this.props.workflowState;

    const sortedNextFeatures =
      nextFeatures && nextFeatures.length
        ? nextFeatures.sort((a, b) => {
            return a.layer.title.localeCompare(b.layer.title);
          })
        : [];

    if (!sortedNextFeatures || !sortedNextFeatures.length) return null;
    const currentFeatureIndex = sortedNextFeatures.findIndex(
      (feature) =>
        feature.attributes.objectId === Number(objectId) &&
        feature.layer.title === layerTitle
    );
    if (currentFeatureIndex === -1) return null;
    return sortedNextFeatures[currentFeatureIndex + 1] || null;
  };

  updateLayerRendererWithColors = (inputColors, layerName) => {
    if (!inputColors || inputColors.length === 0 || !layerName) return;
    const layer = this.getLayerByTitle(layerName);
    if (layer) {
      const renderer = layer.renderer;
      if (renderer.type.includes("unique")) {
        const updatedColors = inputColors
          .filter((color) => color)
          .map((color) => {
            return {
              label: color,
              symbol: {
                type: "simple-fill",
                color: hexToRgba(color),
                outline: {
                  color: [26, 26, 26, 80],
                  width: 1
                },
                style: "solid"
              },
              value: color
            };
          });
        renderer.uniqueValueInfos = [
          ...updatedColors,
          ...renderer.uniqueValueInfos
        ];
        layer.refresh();
      }
    }
  };

  loadUniqueDomainValues = async (layers, attribute) => {
    if (
      !layers ||
      !attribute ||
      layers.length === 0 ||
      attribute.trim() === ""
    ) {
      return [];
    }
    const {
      workflowState: { domainValues },
      updateWorkflowState
    } = this.props;
    const queries = layers.map((layer) => {
      const query = {
        outFields: [attribute],
        orderByFields: [attribute],
        returnDistinctValues: true,
        where: QUERY_EXPRESSION
      };
      return layer.queryFeatures(query);
    });
    const results = await Promise.allSettled(queries);
    const successfulResults = [];
    const seenAttribute = new Set();
    results
      .filter(
        (result) => result.status === "fulfilled" && result.value.features
      )
      .flatMap((result) => result.value.features)
      .forEach((feature) => {
        const featureAttribute = feature.attributes[attribute];
        if (!seenAttribute.has(featureAttribute)) {
          seenAttribute.add(featureAttribute);
          successfulResults.push(feature);
        }
      });
    if (successfulResults.length === 0) {
      return [];
    }
    const updatedDomainValues = successfulResults.flat().map((feature) => {
      const value = feature.attributes[attribute];
      const existingDomainValue = domainValues[attribute]?.find(
        (domainValue) => domainValue.value === value
      );
      return {
        title: existingDomainValue ? existingDomainValue.title : value,
        value: value
      };
    });

    const updatedWorkflowStateDomainValues = {
      [attribute]: updatedDomainValues
    };

    updateWorkflowState({
      uniqueDomainValues: updatedWorkflowStateDomainValues
    });
    return domainValues;
  };

  render() {
    const { error } = this.props;
    return (
      <PackagePluginsWrapper data-name={"PackagePluginsWrapper"}>
        {!error && this.hasSelectedWorkflow() && !this.isSelectingWorkflow()
          ? this.whenLeftPlugins()
            ? this.getPluginsForContainer(PLUGIN_LEFT).map(
                ({ Component, name }) =>
                  Component ? (
                    <PluginWrapper
                      data-name="PluginWrapper"
                      key={name}
                      size={this.getWorkflowSize()}
                      isOpen={this.workflowPanelIsOpen()}
                    >
                      <Suspense fallback={this.renderLoader()}>
                        <Component key={name} {...this.getPropsForPlugin()} />
                        <PanelControlButton
                          onClickButton={this.togglePanelButton}
                          isOpen={this.workflowPanelIsOpen()}
                          isFullWidth={this.workflowIsFullWidth()}
                          labelText={this.getLabelText()}
                        />
                      </Suspense>
                    </PluginWrapper>
                  ) : null
              )
            : this.handleErrorPlugins()
          : null}
        {this.featureErrorModal()}
      </PackagePluginsWrapper>
    );
  }
}
