import {
  ADDRESS_CANDIDATES_URL,
  ADDRESS_SUGGESTIONS_URL,
  EDIT_TYPE_PARAM,
  ESRI_API_KEY
} from "../../constants";
import { storeSession } from "../../App/utils.js";

export default (API_URL, API_KEY) => ({
  requestUser: async (userId, token, signal) => {
    try {
      const response = await fetch(`${API_URL}/users/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      });

      const result = await response.json();
      storeSession(`user-${userId}`, result);

      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },

  requestOrganisations: async (userId, token, signal) => {
    try {
      const response = await fetch(`${API_URL}/users/${userId}/organisations`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      });

      const result = await response.json();
      storeSession(`user-${userId}-organisations`, result);

      if (result.error) throw new Error(result.message);
      return result.items;
    } catch (e) {
      throw e;
    }
  },

  requestOrganisation: async (orgId, token, signal) => {
    try {
      const response = await fetch(`${API_URL}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      });

      const result = await response.json();

      if (result.error) throw new Error(result.message);
      storeSession(`org-${orgId}`, result);
      return result;
    } catch (e) {
      throw e;
    }
  },

  requestPropertyWorkflows: async (orgId, propId, userId, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/properties/${propId}/organisations/${orgId}/role/workflows`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      );

      const result = await response.json();
      storeSession(
        `user-${userId}-org-${orgId}-property-${propId}-workflows`,
        result
      );

      if (result.error) throw new Error(result.message);
      return result.items;
    } catch (e) {
      throw e;
    }
  },

  requestProperties: async (
    orgId,
    userId,
    pagination,
    search,
    token,
    signal
  ) => {
    let url = `${API_URL}/users/${userId}/organisations/${orgId}/properties`;

    try {
      if (search) {
        url = `${url}?search=${search}`;
      }

      if (pagination) {
        const { offset, limit } = pagination;

        url = `${url}${
          url.includes("?") ? "&" : "?"
        }offset=${offset}&limit=${limit}`;
      }

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      });

      const result = await response.json();

      if (result.error) throw new Error(result.message);
      if (!search && (!pagination || !pagination.offset)) {
        storeSession(`user-${userId}-org-${orgId}-properties`, result);
      }
      return result;
    } catch (e) {
      throw e;
    }
  },

  requestProperty: async (orgId, propId, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/properties/${propId}/organisations/${orgId}/role`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      );

      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },

  requestPropertyWorkflow: async (
    orgId,
    propId,
    workflowId,
    userId,
    token,
    signal
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/workflows/${workflowId}/organisations/${orgId}/properties/${propId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      );

      const result = await response.json();
      storeSession(
        `user-${userId}-org-${orgId}-property-${propId}-workflows-${workflowId}`,
        result
      );

      if (result.error) throw new Error(result.message);

      return result;
    } catch (e) {
      throw e;
    }
  },

  requestPropertyGroups: async (
    userId,
    orgId,
    token,
    requestProperties = false,
    signal
  ) => {
    let url = `${API_URL}/users/${userId}/organisations/${orgId}/propertyGroups`;

    try {
      url = `${url}?returnProperties=${requestProperties}`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "GET",
        signal
      });

      const result = await response.json();
      storeSession(`user-${userId}-org-${orgId}-propertyGroups`, result);

      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestPropertyGroup: async (
    orgId,
    propertyGroupId,
    token,
    requestProperties = false,
    signal
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/propertyGroups/${propertyGroupId}/organisations/${orgId}/role?returnProperties=${requestProperties}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "GET",
          signal
        }
      );

      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestPropertyGroupWorkflows: async (
    orgId,
    propertyGroupId,
    userId,
    token,
    signal
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/propertyGroups/${propertyGroupId}/organisations/${orgId}/role/workflows`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "GET",
          signal
        }
      );

      const result = await response.json();
      storeSession(
        `user-${userId}-org-${orgId}-propertyGroup-${propertyGroupId}-workflows`,
        result
      );

      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestPropertyGroupWorkflow: async (
    orgId,
    propertyGroupId,
    workflowId,
    userId,
    token,
    signal
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/workflows/${workflowId}/organisations/${orgId}/propertyGroups/${propertyGroupId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "GET",
          signal
        }
      );

      const result = await response.json();
      storeSession(
        `user-${userId}-org-${orgId}-propertyGroup-${propertyGroupId}-workflows-${workflowId}`,
        result
      );

      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestAddressCandidates: async (suggestion, magicKey, signal) => {
    try {
      const response = await fetch(
        `${ADDRESS_CANDIDATES_URL}SingleLine=${suggestion}&magicKey=${magicKey}&f=pjson&token=${ESRI_API_KEY}`,
        {
          signal
        }
      );
      const result = await response.json();
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestAddressSuggestions: async (searchQuery, signal) => {
    try {
      const response = await fetch(
        `${ADDRESS_SUGGESTIONS_URL}text=${searchQuery}&countryCode=NZ&f=pjson`,
        {
          signal
        }
      );

      const result = await response.json();
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestUserFeaturesByGeometry: async (
    dataSetName,
    orgId,
    userId,
    layerId,
    geometrySearch,
    token,
    signal,
    pagination,
    returnCountOnly
  ) => {
    try {
      let url = `${API_URL}/users/${userId}/organisations/${orgId}/dataSets/${dataSetName}/FeatureServer/${layerId}`;

      if (pagination) {
        const { offset, limit } = pagination;
        url = `${url}?offset=${offset}&limit=${limit}`;
      }

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("geometrySearch", JSON.stringify(geometrySearch));
      if (returnCountOnly) {
        urlSearchParams.append(
          "returnCountOnly",
          JSON.stringify(returnCountOnly)
        );
      }
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "POST",
        body: urlSearchParams,
        redirect: "follow",
        signal
      });

      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  queryLayer: async (layerUrl, body, token, signal) => {
    try {
      const response = await fetch(`${layerUrl}/query`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "POST",
        body,
        signal
      });

      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  layerApplyEdits: async (layerUrl, body, editType, token, signal) => {
    try {
      let url = `${layerUrl}/applyEdits`;

      if (editType) {
        url = `${url}?${EDIT_TYPE_PARAM}=${editType}`;
      }
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "POST",
        body,
        signal
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestPrintPresets: async (orgId, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/printing/organisations/${orgId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key": API_KEY
          },
          method: "GET",
          signal
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestPrintPreset: async (orgId, printPresetId, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/printing/${printPresetId}/organisations/${orgId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key": API_KEY
          },
          method: "GET",
          signal
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  createPrintPreset: async (orgId, body, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/printing/organisations/${orgId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key": API_KEY
          },
          method: "POST",
          body,
          signal
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  updatePrintPreset: async (orgId, printPresetId, body, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/printing/${printPresetId}/organisations/${orgId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key": API_KEY
          },
          method: "PATCH",
          body,
          signal
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },

  removePrintPreset: async (orgId, printPresetId, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/printing/${printPresetId}/organisations/${orgId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key": API_KEY
          },
          method: "DELETE",
          signal
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestUserInvitations: async (userId, token, signal, status) => {
    try {
      let url = `${API_URL}/invites/users/${userId}`;
      if (status) {
        url = `${url}?status=${status}`;
      }
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "GET",
        signal
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  acceptUserInvitation: async (orgId, inviteId, body, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/invites/${inviteId}/organisations/${orgId}/accept`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key": API_KEY
          },
          method: "POST",
          signal,
          body
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestInvitationPublic: async (
    inviteId,
    orgId,
    inviteToken,
    emailAddress,
    signal
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/invites/${inviteId}/organisations/${orgId}/inviteToken/${inviteToken}?emailAddress=${emailAddress}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY
          },
          method: "GET",
          signal
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  acceptInvitationPublic: async (
    inviteId,
    orgId,
    inviteToken,
    body,
    password,
    signal
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/invites/${inviteId}/organisations/${orgId}/inviteToken/${inviteToken}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            password
          },
          method: "POST",
          signal,
          body
        }
      );

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestOrganisationUser: async (userId, orgId, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/organisations/${orgId}/users/${userId}/user`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      );

      const result = await response.json();

      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestSharedMaps: async (orgId, propId, search, token, signal) => {
    let url = `${API_URL}/shareMaps/organisations/${orgId}/property/${propId}`;

    try {
      let searchQuery = search ? search : "";
      url = `${url}?search=${searchQuery}`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "GET",
        signal
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestShareMap: async (shareId, orgId, propId, token, signal) => {
    let url = `${API_URL}/shareMaps/${shareId}/organisations/${orgId}/property/${propId}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "GET",
        signal
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestShareMapLink: async (orgId, propId, body, password, token, signal) => {
    let url = `${API_URL}/shareMaps/organisations/${orgId}/property/${propId}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY,
          password
        },
        method: "POST",
        signal,
        body
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  updateShareMapForQuote: async (
    orgId,
    shareId,
    quoteId,
    body,
    token,
    signal
  ) => {
    let url = `${API_URL}/shareMaps/${shareId}/organisations/${orgId}/quotes/${quoteId}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "PATCH",
        signal,
        body
      });
      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  updateShareMap: async (
    orgId,
    propId,
    shareId,
    body,
    password,
    token,
    signal
  ) => {
    let url = `${API_URL}/shareMaps/${shareId}/organisations/${orgId}/property/${propId}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY,
          password
        },
        method: "PATCH",
        signal,
        body
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  deleteShareMap: async (orgId, shareId, token, signal) => {
    let url = `${API_URL}/shareMaps/${shareId}/organisations/${orgId}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "DELETE",
        signal
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestImageryDates: async (orgId, propId, token, signal) => {
    let url = `${API_URL}/imageryAuth/organisations/${orgId}/properties/${propId}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY
        },
        method: "GET",
        signal
      });

      const result = await response.json();
      const { error, message } = result;
      if (error) throw new Error(message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestPropertiesForGroup: async (
    orgId,
    groupId,
    pagination,
    search,
    token,
    signal
  ) => {
    let url = `${API_URL}/propertyGroups/${groupId}/organisations/${orgId}/role?returnGeometry=false`;

    try {
      if (search) {
        url = `${url}&search=${search}`;
      }

      if (pagination) {
        const { offset, limit } = pagination;
        url = `${url}&offset=${offset}&limit=${limit}`;
      }

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      });

      const result = await response.json();

      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestPropertyGroupData: async (
    dataSet,
    orgId,
    groupId,
    layerId,
    search,
    outFields = ["*"],
    returnGeometry = false,
    returnDistinctValues = true,
    pagination = null,
    token,
    signal
  ) => {
    let url = `${API_URL}/dataSets/${dataSet}/organisations/${orgId}/propertyGroups/${groupId}/FeatureServer/${layerId}/query?returnGeometry=${returnGeometry}&returnDistinctValues=${returnDistinctValues}&rdsFiltering=true`;

    try {
      if (search) {
        url = `${url}&rdsWhere=${search}`;
      }

      if (pagination) {
        const { offset, limit } = pagination;
        url = `${url}${
          url.includes("?") ? "&" : "?"
        }offset=${offset}&limit=${limit}`;
      }

      let outFieldsString = outFields.join(",");
      url = `${url}${
        url.includes("?") ? "&" : "?"
      }outFields=${outFieldsString}`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "GET",
        signal
      });

      const result = await response.json();

      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  updateUser: async (userId, body, token, signal) => {
    try {
      const response = await fetch(`${API_URL}/users/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body,
        signal
      });

      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  createActionLog: async (body, token, signal) => {
    try {
      const response = await fetch(`${API_URL}/logs`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body,
        signal
      });

      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      throw e;
    }
  },
  requestDataSetLayerDetails: async (dataSetName, layerName, token, orgId) => {
    let url = `${API_URL}/dataSets/${dataSetName}/organisations/${orgId}/layers/${layerName}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "GET"
      });
      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  requestDataSetFeaturesByGeometry: async (
    dataSetName,
    layerId,
    geometry,
    geometryType,
    token,
    orgId,
    search
  ) => {
    let url = `${API_URL}/dataSets/${dataSetName}/organisations/${orgId}/FeatureServer/${layerId}/query`;
    try {
      if (search) {
        url = `${url}?where=${search}`;
      }
      const body = JSON.stringify({
        geometry,
        geometryType
      });
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body
      });
      const result = await response.json();
      if (result.error) throw new Error(result.message);
      return result;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  requestPropertyUsers: async (orgId, propId, userId, token, signal) => {
    try {
      const response = await fetch(
        `${API_URL}/properties/${propId}/organisations/${orgId}/users`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      );
      const result = await response.json();
      if (result.error) throw new Error(result.message);
      storeSession(
        `user-${userId}-org-${orgId}-property-${propId}-users`,
        result
      );
      return result;
    } catch (e) {
      return false;
    }
  }
});
