import { createGlobalStyle } from "styled-components";
import { LANDSCAPE, PORTRAIT, SQUARE } from "../constants";

export const GlobalStyle = createGlobalStyle`
  body {
    margin:0;
    padding:0;
    font-family: "Poppins", sans-serif;
    color: #333333;
    font-weight: 400;
    line-height: 1.3em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin:0;
    padding:0;
    font-family: "Poppins", sans-serif;
  }

  a {
    color: #4f57a6;
    &:hover {
      cursor: pointer;
    }
  }

  ul {
    list-style: none;
    margin:0;
    padding:0;
  }

  button {
    border: none;
    background-color: transparent;
    color: #4f57a6;
    font-family: "Poppins", sans-serif;

    &:hover {
      color: @ag-button-hover;
      cursor: pointer;
      opacity: 0.8;
    }
  }

  button:focus{
    outline-color: #0079c1;
    outline-offset: -4px;
    outline-width: 1px;
    outline-style: solid;
  }

  .ReactNavigationController {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .ReactNavigationControllerView {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
  }

  .esri-ui-top-left {
    right:0;
    left:unset;
  }

  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fadeIn {
      0% { opacity: 0;  }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
      0% { opacity: 1;  }
    100% { opacity: 0; }
  }

  .rbc-event {
    background-color: transparent;
    padding: 0;
  }
  .rbc-month-row {
    overflow: visible;
  }
  .rbc-event p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .line-graph-wrapper {
    padding-bottom: 2em;
    padding-left: 0.3em;
    box-sizing: border-box;
  }
  .line-graph-wrapper svg {
    overflow: visible;
  }
`;
export const defaultTheme = {
  agWhite: "#ffffff",
  agTransparentWhite: "rgba(255, 255, 255, 0.8)",
  agOffWhite: "#f8f8f8",
  agLightestGray: "#efefef",
  agLighterGray: "#ccc",
  agLightGray: "#a9a9a9",
  agGray: "#959595",
  agTransparentGray: "#95959569",
  agDarkGray: "#828282",
  agDarkerGray: "#6e6e6e",
  agDarkestGray: "#595959",
  agOffBlack: "#4c4c4c",
  agTransparentBlack: "rgba(0, 0,0 , 0.75)",
  agTransparentLightBlack: "rgba(41, 43, 53, 0.6)",
  agBlack: "#323232",
  agTrueBlack: "#000000",

  agLightestBlue: "#d2e9f9",
  agLighterBlue: "#bcdaed",
  agLightBlue: "#56a5d8",
  agTransparentLightBlue: "rgba(0, 0, 200, 0.12)",
  agBlue: "#0079c1",
  agDarkBlue: "#005e95",
  agLightGreen: "#9bc19c",
  agGreen: "#00AB7A",
  agLightRed: "#e4a793",
  agOffRed: "#f3ded7",
  agErrorRed: "#c86a4a",
  agRed: "#de2900",
  agDarkRed: "#ab3c16",
  agLightOrange: "#e8912e",
  agOrange: "#d17e21",
  agDarkOrange: "#ad6511",
  agLightYellow: "#efe397",
  agYellow: "#e4d154",
  agDarkYellow: "#d9bf2b",
  aagLightPurple: "#c4bcdc",
  agPurple: "#9081bc",
  agDarkPurple: "#7461a8",
  agLightBrown: "#d2b38f",
  agBrown: "#a57946",
  agDarkBrown: "#8c5e2a",
  agLink: "#4f57a6",
  agHighlightedField: "#dae4d9",
  agDarkSelectedPurple: "#9D93E5",
  agLightSelectedPurple: "#F1EFFF",
  agBurntOrange: "#D8A07B",

  fontSizeExtraSmall: "0.6em",
  fontSizeSmallest: "0.8125em",
  fontSizeSmaller: "0.875em",
  fontSizeSmall: " 0.9375em",
  fontSizeBase: "1em",
  fontSizeOne: "1.2019em",
  fontSizeTwo: "1.414em",
  fontSizeThree: "1.69949em",
  fontSizeFour: "1.9994em",
  fontSizeFive: "2.40307em",
  fontSizeSix: "2.82715em",
  fontSizeSeven: "3.39795em;",
  fontSizeEight: "3.99758em",
  fontSizeNine: "5em",
  fontSizeResponsiveHeading: "1.8em",

  lineHeightBase: "1.5em",
  lineHeightSmaller: "1.4em",
  lineHeightSmallest: "1.25em",
  lineHeightLarger: "1.6em",
  lineHeightLargest: "1.8em",

  baseBorderRadiusStandard: "0.3rem",

  baseFontWeight: "300",
  baseFontWeightRegular: "400",
  baseFontWeightHeavy: "600",
  baseFont: '"Poppins", sans-serif',
  baseFontAlternative: '"Poppins", sans-serif',

  basePadding: 5,

  agPluginSize: 250,
  agPluginSizeDouble: 450,

  iconLocation: "../../icons/",
  iconWidth: "40px",
  iconHeight: "40px",
  imgWidth: "60px",
  imgPadding: "8px",

  menuWidthOpen: "18.75em",
  menuWidthClose: "3.75em",

  landscape: LANDSCAPE,
  square: SQUARE,
  portrait: PORTRAIT
};

export const mediaQueryWidth = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px"
};

export const mediaQueryHeight = {
  short: "500px",
  medium: "800px",
  tall: "1080px"
};

export const deviceWidth = {
  mobileS: `(max-width: ${mediaQueryWidth.mobileS})`,
  mobileM: `(max-width: ${mediaQueryWidth.mobileM})`,
  mobileL: `(max-width: ${mediaQueryWidth.mobileL})`,
  tablet: `(max-width: ${mediaQueryWidth.tablet})`,
  laptop: `(max-width: ${mediaQueryWidth.laptop})`,
  laptopL: `(max-width: ${mediaQueryWidth.laptopL})`,
  desktop: `(max-width: ${mediaQueryWidth.desktop})`,
  desktopL: `(max-width: ${mediaQueryWidth.desktop})`
};

export const deviceHeight = {
  short: `(max-height: ${mediaQueryHeight.short})`,
  medium: `(max-height: ${mediaQueryHeight.medium})`,
  tall: `(max-height: ${mediaQueryHeight.tall})`
};

export const loaderSizeRegular = "LOADER_SIZE_REGULAR";
export const loaderSizeSmall = "LOADER_SIZE_SMALL";
