import { createActions } from "redux-actions";
import {
  UNLOAD_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUPS,
  LOADED_PROPERTY_GROUPS,
  ERROR_PROPERTY_GROUPS,
  LOAD_PROPERTY_GROUP,
  LOADED_PROPERTY_GROUP,
  ERROR_PROPERTY_GROUP,
  SELECT_PROPERTY_GROUP
} from "../constants";

export const {
  unloadPropertyGroup,
  loadPropertyGroups,
  loadedPropertyGroups,
  errorPropertyGroups,
  loadPropertyGroup,
  loadedPropertyGroup,
  errorPropertyGroup,
  selectPropertyGroup
} = createActions({
  [UNLOAD_PROPERTY_GROUP]: () => ({}),
  [LOAD_PROPERTY_GROUPS]: (orgId) => ({ orgId }),
  [LOADED_PROPERTY_GROUPS]: (properties, meta) => ({ properties, meta }),
  [ERROR_PROPERTY_GROUPS]: (error) => error,
  [LOAD_PROPERTY_GROUP]: (orgId, groupId) => ({
    orgId,
    groupId
  }),
  [LOADED_PROPERTY_GROUP]: (propertyGroup) => propertyGroup,
  [ERROR_PROPERTY_GROUP]: (error) => error,
  [SELECT_PROPERTY_GROUP]: (orgId, groupId) => ({ orgId, groupId })
});
