import { createLogic } from "redux-logic";
import {
  LOAD_WORKFLOW_PLUGINS,
  UPDATE_WORKFLOW_STATE,
  ADD_WORKFLOW_EVENT_HANDLER,
  REMOVE_WORKFLOW_EVENT_HANDLER,
  ADD_GRAPHIC_TO_WORKFLOW_STATE,
  UPDATE_GRAPHIC_IN_WORKFLOW_STATE,
  REMOVE_GRAPHIC_FROM_WORKFLOW_STATE,
  LOAD_WORKFLOW,
  LOADED_WORKFLOW,
  ADD_FEATURE_LIST_ITEMS,
  UPDATE_FEATURE_LIST_ITEM,
  ABORT_ERROR_NAME,
  LOAD_WORKFLOWS,
  LOAD_PROPERTY_GROUP_WORKFLOWS,
  SELECT_WORKFLOW,
  LOADED_WORKFLOWS,
  URL_DELIMITER_ORG,
  URL_DELIMITER_WORKFLOW,
  DESELECT_WORKFLOW,
  LOADED_PROPERTY_GROUP_WORKFLOWS,
  ERROR_WORKFLOW,
  GEOMETRY_TYPE_POLY,
  GEOMETRY_TYPE_LINE,
  GEOMETRY_TYPE_POINT,
  SEND_RESPONSE,
  SET_WORKFLOW_STATE_RESPONSE_MESSAGE_TYPE,
  GET_WORKFLOW_STATE_VALUE,
  GET_WORKFLOW_STATE_RESPONSE_MESSAGE_TYPE
} from "../constants";
import { navigate } from "@reach/router";
import { getSession } from "../App/utils";

const addFeatureListItemsLogic = createLogic({
  type: ADD_FEATURE_LIST_ITEMS,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const { setFeatureListItems } = globalActions;
    const { featureListItems, signal } = action.payload;
    const { getFeatureListItems } = selectors;
    const existingFeatureListItems = getFeatureListItems(getState());
    const polygons = [];
    const polylines = [];
    const points = [];
    const other = [];
    [...existingFeatureListItems, ...featureListItems].forEach((feature) => {
      const { geometry } = feature;
      if (!geometry || !geometry.type) other.push(feature);
      else if (geometry.type === GEOMETRY_TYPE_POLY) polygons.push(feature);
      else if (geometry.type === GEOMETRY_TYPE_LINE) polylines.push(feature);
      else if (geometry.type === GEOMETRY_TYPE_POINT) points.push(feature);
      else other.push(feature);
    });
    if (signal && signal.aborted) {
      dispatch(setFeatureListItems([]));
    } else {
      dispatch(
        setFeatureListItems([...polygons, ...polylines, ...points, ...other])
      );
    }
    done();
  }
});

const updateFeatureListItemLogic = createLogic({
  type: UPDATE_FEATURE_LIST_ITEM,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const { feature } = action.payload;
    const { getFeatureListItems } = selectors;
    const { setFeatureListItems } = globalActions;
    const featureListItems = getFeatureListItems(getState());
    const newFeatureListItems = featureListItems.map((item) =>
      item.uid === feature.uid ? feature : item
    );
    dispatch(setFeatureListItems(newFeatureListItems));
    done();
  }
});

const loadWorkflowPluginsLogic = createLogic({
  type: LOAD_WORKFLOW_PLUGINS,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const { getAvailablePlugins } = selectors;
    const availablePlugins = getAvailablePlugins(getState());

    const { state, plugins } = action.payload;

    //if using the old way of having an array of plugins + plugin in workflow state, use the ones from workflow state. Otherwise use the higher level ones
    let loadablePlugins = plugins;
    if (Array.isArray(plugins)) {
      loadablePlugins = state.plugins || {};
    }

    const { loadedWorkflowPlugins } = globalActions;
    dispatch(
      loadedWorkflowPlugins(
        Object.keys(loadablePlugins).reduce((result, workflowName) => {
          return [
            ...result,
            {
              ...loadablePlugins[workflowName],
              name: workflowName,
              Component: availablePlugins[workflowName]
            }
          ];
        }, [])
      )
    );
    done();
  }
});

const updateWorkflowStateLogic = createLogic({
  type: UPDATE_WORKFLOW_STATE,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const state = getState();

    const { getWorkflowState, getMapView, getSelectedWorkflow } = selectors;
    const workflow = getSelectedWorkflow(state);
    const workflowState = getWorkflowState(state);

    const { stateUpdate, callback } = action.payload;

    const graphicsHaveBeenUpdated = stateUpdate.graphics;
    if (graphicsHaveBeenUpdated) {
      const mapView = getMapView(state);

      mapView.graphics.removeMany(workflowState.graphics);
      mapView.graphics.addMany(stateUpdate.graphics);
    }

    const { setWorkflowState, sendResponse } = globalActions;
    const { workflowId } = workflow;
    const updatedWorkflowState = {
      ...workflowState,
      ...stateUpdate
    };
    dispatch(setWorkflowState(updatedWorkflowState, workflowId));

    dispatch(
      sendResponse(
        SET_WORKFLOW_STATE_RESPONSE_MESSAGE_TYPE,
        JSON.stringify({
          workflowId,
          updates: Object.keys(stateUpdate)
        })
      )
    );

    if (callback) {
      callback(updatedWorkflowState);
    }
    done();
  }
});

const addGraphicToWorkflowStateLogic = createLogic({
  type: ADD_GRAPHIC_TO_WORKFLOW_STATE,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const state = getState();

    const { getWorkflowState } = selectors;
    const workflowState = getWorkflowState(state);
    const { graphics } = workflowState;

    const { newGraphic } = action.payload;
    const updateGraphics = [...graphics, newGraphic];

    const { updateWorkflowState } = globalActions;
    dispatch(updateWorkflowState({ graphics: updateGraphics }));

    const { getWorkflowEventHandlers } = selectors;
    const eventHandlers = getWorkflowEventHandlers(state);
    const addGraphicEventHandlers = eventHandlers.filter(
      (eventHandler) => eventHandler.handle === "add-graphic"
    );
    addGraphicEventHandlers.forEach((eventHandler) =>
      eventHandler.method(newGraphic)
    );
    done();
  }
});

const updateGraphicInWorkflowStateLogic = createLogic({
  type: UPDATE_GRAPHIC_IN_WORKFLOW_STATE,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const state = getState();

    const { getWorkflowState } = selectors;
    const workflowState = getWorkflowState(state);
    const { graphics } = workflowState;

    const { graphicId, updatedGraphic } = action.payload;
    const updateGraphics = graphics.map((graphic) => {
      if (graphic.uid === graphicId) return updatedGraphic;
      return graphic;
    });

    const { updateWorkflowState } = globalActions;
    dispatch(updateWorkflowState({ graphics: updateGraphics }));

    const { getWorkflowEventHandlers } = selectors;
    const eventHandlers = getWorkflowEventHandlers(state);
    const updateGraphicEventHandlers = eventHandlers.filter(
      (eventHandler) => eventHandler.handle === "update-graphic"
    );
    updateGraphicEventHandlers.forEach((eventHandler) =>
      eventHandler.method(updatedGraphic)
    );
    done();
  }
});

const removeGraphicFromWorkflowStateLogic = createLogic({
  type: REMOVE_GRAPHIC_FROM_WORKFLOW_STATE,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const state = getState();

    const { getWorkflowState } = selectors;
    const workflowState = getWorkflowState(state);
    const { graphics } = workflowState;

    const { graphicId } = action.payload;
    const updateGraphics = graphics.filter(
      (graphic) => graphic.uid !== graphicId
    );

    const { updateWorkflowState } = globalActions;
    dispatch(updateWorkflowState({ graphics: updateGraphics }));

    const { getWorkflowEventHandlers } = selectors;
    const eventHandlers = getWorkflowEventHandlers(state);
    const removeGraphicEventHandlers = eventHandlers.filter(
      (eventHandler) => eventHandler.handle === "remove-graphic"
    );
    const removedGraphic = graphics.find(
      (graphic) => graphic.uid === graphicId
    );
    removeGraphicEventHandlers.forEach((eventHandler) =>
      eventHandler.method(removedGraphic)
    );
    done();
  }
});

const addWorkflowEventHandlerLogic = createLogic({
  type: ADD_WORKFLOW_EVENT_HANDLER,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const state = getState();
    const { getWorkflowEventHandlers } = selectors;
    const eventHandlers = getWorkflowEventHandlers(state);
    const newEventHandler = action.payload;
    const updatedEventHandlers = [...eventHandlers, newEventHandler];

    const { updateWorkflowEventHandlers } = globalActions;
    dispatch(updateWorkflowEventHandlers(updatedEventHandlers));
    done();
  }
});

const removeWorkflowEventHandlerLogic = createLogic({
  type: REMOVE_WORKFLOW_EVENT_HANDLER,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const state = getState();
    const { getWorkflowEventHandlers } = selectors;
    const eventHandlers = getWorkflowEventHandlers(state);
    const { eventHandlerId } = action.payload;
    const updatedEventHandlers = eventHandlers.filter(
      (eventHandler) => eventHandler.id !== eventHandlerId
    );

    const { updateWorkflowEventHandlers } = globalActions;
    dispatch(updateWorkflowEventHandlers(updatedEventHandlers));
    done();
  }
});

const removeImportFilesOnUnselectWorkflow = createLogic({
  type: LOADED_WORKFLOW,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const { updateWorkflowState } = globalActions;
    dispatch(
      updateWorkflowState({
        importFiles: [],
        attachments: [],
        filesToDelete: []
      })
    );
    done();
  }
});

const loadWorkflowsLogic = createLogic({
  type: LOAD_WORKFLOWS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const { getToken, getAbortSignal, getUserId } = selectors;
      const token = getToken(state);
      const userId = getUserId(state);
      const { orgId, propId } = action.payload;

      const { requestPropertyWorkflows } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const sessionWorkflows = getSession(
        `user-${userId}-org-${orgId}-property-${propId}-workflows`
      );
      const workflows =
        sessionWorkflows && sessionWorkflows.items.length !== 0
          ? sessionWorkflows.items
          : await requestPropertyWorkflows(
              orgId,
              propId,
              userId,
              token,
              signal
            );
      const { loadedWorkflows } = globalActions;
      dispatch(loadedWorkflows(workflows));
      done();
    } catch (e) {
      if (!e.name || e.name !== ABORT_ERROR_NAME) {
        const { errorWorkflows } = globalActions;
        dispatch(errorWorkflows(e));
      }
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadWorkflowLogic = createLogic({
  type: LOAD_WORKFLOW,
  async process(
    { globalActions, agBoxApiRequests, action, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const { getToken, getAbortSignal, getUserId } = selectors;
      const { loadedWorkflow, setWorkflowPanelOpen, loadWorkflowPlugins } =
        globalActions;
      const token = getToken(state);
      const userId = getUserId(state);
      const { workflowId, propId, groupId, orgId } = action.payload;
      const { requestPropertyWorkflow, requestPropertyGroupWorkflow } =
        agBoxApiRequests;
      dispatch(setWorkflowPanelOpen(true));
      const signal = getAbortSignal(getState());
      const request = groupId
        ? requestPropertyGroupWorkflow
        : requestPropertyWorkflow;
      const id = groupId ? groupId : propId;
      const sessionWorkflow = getSession(
        groupId
          ? `user-${userId}-org-${orgId}-propertyGroup-${groupId}-workflows-${workflowId}`
          : `user-${userId}-org-${orgId}-property-${propId}-workflows-${workflowId}`
      );
      const workflow = sessionWorkflow
        ? sessionWorkflow
        : await request(orgId, id, workflowId, userId, token, signal);
      dispatch(loadedWorkflow(workflow));
      dispatch(loadWorkflowPlugins(workflow));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      const { errorWorkflow } = globalActions;

      if (e.name !== ABORT_ERROR_NAME) {
        dispatch(errorWorkflow(e));
      }

      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadPropertyGroupWorkflowsLogic = createLogic({
  type: LOAD_PROPERTY_GROUP_WORKFLOWS,
  async process(
    { globalActions, agBoxApiRequests, action, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const { getToken, getAbortSignal, getUserId } = selectors;

      const { loadedPropertyGroupWorkflows } = globalActions;
      const token = getToken(state);
      const userId = getUserId(state);
      const { orgId, groupId } = action.payload;

      const { requestPropertyGroupWorkflows } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const sessionGroupWorkflows = getSession(
        `user-${userId}-org-${orgId}-propertyGroup-${groupId}-workflows`
      );
      const propertyGroupWorkflows =
        sessionGroupWorkflows && sessionGroupWorkflows.items.length !== 0
          ? sessionGroupWorkflows
          : await requestPropertyGroupWorkflows(
              orgId,
              groupId,
              userId,
              token,
              signal
            );

      dispatch(loadedPropertyGroupWorkflows(propertyGroupWorkflows.items));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      const { errorWorkflows } = globalActions;

      dispatch(errorWorkflows(e));
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadedWorkflowLogic = createLogic({
  type: LOADED_WORKFLOW,
  async process({ globalActions, action }, dispatch, done) {
    const { layerUrls, title } = action.payload;
    const { setFeatureLayers, setDocTitle } = globalActions;
    dispatch(setDocTitle(title));
    dispatch(setFeatureLayers(layerUrls));

    done();
  }
});

const selectWorkflowLogic = createLogic({
  type: SELECT_WORKFLOW,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const {
      loadWorkflow,
      abortRequest,
      clearWorkflowPlugins,
      clearWorkflow,
      cancelPropertyDisplay,
      closeWidget
    } = globalActions;
    const { getSelectedWorkflow, getMapView } = selectors;
    const workflowSelected = getSelectedWorkflow(getState());
    dispatch(closeWidget());
    if (workflowSelected) {
      dispatch(clearWorkflowPlugins());
      dispatch(clearWorkflow());
      dispatch(abortRequest());
    }
    const mapView = getMapView(getState());
    mapView.graphics.removeAll();

    const { workflowId, propId, groupId, orgId } = action.payload;
    if (groupId) {
      dispatch(cancelPropertyDisplay());
    }

    dispatch(loadWorkflow(workflowId, propId, groupId, orgId));

    done();
  }
});

const loadedWorkflowsLogic = createLogic({
  type: LOADED_WORKFLOWS,
  process({ globalActions, action, getState, selectors }, dispatch, done) {
    const availableWorkflows = action.payload;
    if (!availableWorkflows || !availableWorkflows.length) return done();
    const orgIdRegExp = new RegExp(
      `(?<=${URL_DELIMITER_ORG}\/).*?(?=\/)`,
      "gi"
    );
    const orgId = window.location.pathname.match(orgIdRegExp)[0];
    const { getUserDefaultPropertyWorkflow } = selectors;
    const defaultUserWorkflowId = getUserDefaultPropertyWorkflow(
      getState(),
      orgId
    );
    const defaultWorkflow = defaultUserWorkflowId
      ? availableWorkflows.find(
          (workflow) => workflow.workflowId === defaultUserWorkflowId
        )
      : availableWorkflows.find((workflow) => workflow.isDefault);
    const paths = window.location.pathname
      .replace("#error", "")
      .split(`/${URL_DELIMITER_WORKFLOW}`);
    const selectedWorkflowId = paths[1] && paths[1] !== "/";
    if (availableWorkflows.length === 1) {
      const url = `${paths[0]}${
        paths[0].charAt(paths[0].length - 1) === "/" ? "" : "/"
      }${URL_DELIMITER_WORKFLOW}/${availableWorkflows[0].workflowId}`;
      if (
        !window.location.pathname.includes(URL_DELIMITER_WORKFLOW) ||
        !selectedWorkflowId
      ) {
        navigate(url, {
          state: {
            docTitle: availableWorkflows[0].title
          }
        });
      }
    } else if (
      defaultWorkflow &&
      (!window.location.pathname.includes(URL_DELIMITER_WORKFLOW) ||
        !selectedWorkflowId)
    ) {
      const url = `${paths[0]}${
        paths[0].charAt(paths[0].length - 1) === "/" ? "" : "/"
      }${URL_DELIMITER_WORKFLOW}/${defaultWorkflow.workflowId}`;
      navigate(url, {
        state: {
          docTitle: defaultWorkflow.title
        }
      });
    }

    done();
  }
});

const deselectWorkflowLogic = createLogic({
  type: DESELECT_WORKFLOW,
  process({ globalActions, action, getState, selectors }, dispatch, done) {
    const {
      clearWorkflow,
      clearWorkflowPlugins,
      setMapPosition,
      startPropertyDisplay,
      setWorkflowPanelOpen
    } = globalActions;
    const { getSelectedProperty, getSelectedPropertyGroup } = selectors;
    const selectedProperty = getSelectedProperty(getState());
    const selectedPropertyGroup = getSelectedPropertyGroup(getState());
    if (selectedProperty) {
      dispatch(setMapPosition(selectedProperty.extent));
    }
    if (
      selectedPropertyGroup &&
      !window.location.pathname.includes(URL_DELIMITER_WORKFLOW)
    ) {
      dispatch(setMapPosition(selectedPropertyGroup.extent));
      dispatch(startPropertyDisplay());
    }
    dispatch(clearWorkflow());

    dispatch(clearWorkflowPlugins());
    dispatch(setWorkflowPanelOpen(false));
    done();
  }
});

const loadedPropertyGroupWorkflowsLogic = createLogic({
  type: LOADED_PROPERTY_GROUP_WORKFLOWS,
  process({ selectors, action, getState }, dispatch, done) {
    const availableWorkflows = action.payload;
    if (!availableWorkflows || !availableWorkflows.length) return done();
    const orgIdRegExp = new RegExp(
      `(?<=${URL_DELIMITER_ORG}\/).*?(?=\/)`,
      "gi"
    );
    const orgId = window.location.pathname.match(orgIdRegExp)[0];
    const { getUserDefaultPropertyGroupWorkflow } = selectors;
    const defaultUserWorkflowId = getUserDefaultPropertyGroupWorkflow(
      getState(),
      orgId
    );
    const defaultWorkflow = defaultUserWorkflowId
      ? availableWorkflows.find(
          (workflow) => workflow.workflowId === defaultUserWorkflowId
        )
      : availableWorkflows.find((workflow) => workflow.isDefault);
    const paths = window.location.pathname
      .replace("#error", "")
      .split(`/${URL_DELIMITER_WORKFLOW}`);
    const selectedWorkflowId = paths[1] && paths[1] !== "/";
    if (availableWorkflows.length === 1) {
      const url = `${paths[0]}${
        paths[0].charAt(paths[0].length - 1) === "/" ? "" : "/"
      }${URL_DELIMITER_WORKFLOW}/${availableWorkflows[0].workflowId}`;
      if (
        !window.location.pathname.includes(URL_DELIMITER_WORKFLOW) ||
        !selectedWorkflowId
      ) {
        navigate(url, {
          state: {
            docTitle: availableWorkflows[0].title
          }
        });
      }
    } else if (
      defaultWorkflow &&
      (!window.location.pathname.includes(URL_DELIMITER_WORKFLOW) ||
        !selectedWorkflowId)
    ) {
      const url = `${paths[0]}${
        paths[0].charAt(paths[0].length - 1) === "/" ? "" : "/"
      }${URL_DELIMITER_WORKFLOW}/${defaultWorkflow.workflowId}`;
      navigate(url, {
        state: {
          docTitle: defaultWorkflow.title
        }
      });
    }
    done();
  }
});

const errorWorkflowLogic = createLogic({
  type: ERROR_WORKFLOW,
  process({ globalActions, action, getState, selectors }, dispatch, done) {
    const { setMapPosition } = globalActions;
    const { getSelectedOrg, getSelectedProperty, getSelectedPropertyGroup } =
      selectors;
    if (
      action.payload === false &&
      getSelectedOrg(getState()) &&
      (getSelectedProperty(getState()) || getSelectedPropertyGroup(getState()))
    ) {
      dispatch(setMapPosition());
    }
    done();
  }
});

const sendResponseLogic = createLogic({
  type: SEND_RESPONSE,
  process({ action }, dispatch, done) {
    const { messageType, message } = action.payload;
    window.postMessage({ messageType, data: message }, window.location.origin);
    done();
  }
});

const getWorkflowStateLogic = createLogic({
  type: GET_WORKFLOW_STATE_VALUE,
  process({ action, globalActions, selectors, getState }, dispatch, done) {
    const { getWorkflowState } = selectors;
    const { sendResponse } = globalActions;
    const workflowState = getWorkflowState(getState());
    const key = action.payload;
    let value = null;
    if (
      workflowState &&
      Object.prototype.hasOwnProperty.call(workflowState, key)
    ) {
      value = workflowState[key];
    }

    dispatch(
      sendResponse(
        GET_WORKFLOW_STATE_RESPONSE_MESSAGE_TYPE,
        JSON.stringify({
          key,
          value
        })
      )
    );

    done();
  }
});

export default [
  addFeatureListItemsLogic,
  updateFeatureListItemLogic,
  loadWorkflowPluginsLogic,
  updateWorkflowStateLogic,
  updateGraphicInWorkflowStateLogic,
  addWorkflowEventHandlerLogic,
  addGraphicToWorkflowStateLogic,
  removeWorkflowEventHandlerLogic,
  removeGraphicFromWorkflowStateLogic,
  removeImportFilesOnUnselectWorkflow,
  loadWorkflowsLogic,
  loadWorkflowLogic,
  loadPropertyGroupWorkflowsLogic,
  loadedWorkflowLogic,
  selectWorkflowLogic,
  loadedWorkflowsLogic,
  deselectWorkflowLogic,
  loadedPropertyGroupWorkflowsLogic,
  errorWorkflowLogic,
  sendResponseLogic,
  getWorkflowStateLogic
];
