import { createLogic } from "redux-logic";
import { navigate } from "@reach/router";
import {
  clearSession,
  getSession,
  isNullOrUndefined,
  isValidJSON,
  storeSession
} from "../App/utils.js";

import {
  SELECT_PROPERTY,
  LOADED_PROPERTY,
  SELECT_PROPERTY_HOME,
  LOADED_PROPERTIES,
  SETUP_LAYER_LABELS,
  UPDATE_PROPERTIES_PAGINATION,
  UPDATED_PROPERTIES,
  UPDATE_PROPERTIES_SEARCH,
  URL_DELIMITER_ORG,
  URL_DELIMITER_PROP,
  URL_DELIMITER_WORKFLOW,
  URL_DELIMITER_ROLE,
  UPDATE_AVAILABLE_PROPERTY,
  ERROR_PROPERTY,
  LOAD_PROPERTY,
  UPDATE_PROPERTIES,
  LOAD_PROPERTIES,
  UNLOAD_PROPERTY,
  ABORT_ERROR_NAME,
  SEARCH_BY_LOCATION,
  START_SEARCH_BY_LOCATION,
  SET_SELECTED_PROPERTY_SEARCH_SUGGESTION,
  LOAD_ADDRESS_CANDIDATE,
  SEARCH_BY_NAME,
  SET_ADDRESS_CANDIDATE,
  SET_PROPERTY_GEOMETRY_SEARCH,
  SEARCH_PROPERTIES_BY_NAME,
  SEARCH_PROPERTIES_BY_LOCATION,
  ZOOM_TO_SEARCH_RESULTS,
  UNIT_METERS,
  SET_ZOOM_TO_SEARCH_RESULTS,
  ZOOM_TO_ORIGINAL_POSITION,
  GEOMETRY_SERVICE_URL,
  DEFAULT_ADDRESS_SEARCH_RADIUS,
  ADD_LOCATION_SEARCH_MARKER,
  LOCATION_SEARCH_MARKER_SYMBOL,
  LOCATION_SEARCH_MARKER_TITLE,
  CLEAR_LOCATION_SEARCH_GRAPHICS,
  URL_DELIMITER_SELECT_ORG,
  SET_SEARCH_PROPERTIES_BY,
  START_SEARCH_BY_COORDINATES,
  UPDATE_FAVOURITE_PROPERTIES,
  UPDATED_FAVOURITE_PROPERTIES,
  LOAD_PROPERTY_USERS
} from "../constants";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import { project } from "@arcgis/core/rest/geometryService";
import { geodesicBuffer } from "@arcgis/core/geometry/geometryEngine";
import LabelClass from "@arcgis/core/layers/support/LabelClass";
import Circle from "@arcgis/core/geometry/Circle";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import { NotificationManager } from "react-notifications";

const selectPropertyLogic = createLogic({
  type: SELECT_PROPERTY,
  async process(
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const { getPropertyLayers, getSelectedProperty, getMapView } = selectors;
    const {
      loadProperty,
      deselectWorkflow,
      abortRequest,
      zoomToOriginalPosition,
      clearLocationSearchGraphics,
      loadWorkflows,
      cancelPropertyDisplay
    } = globalActions;
    const selectedProperty = getSelectedProperty(getState());
    const { orgId, propId } = action.payload;
    if (selectedProperty) {
      dispatch(abortRequest());
      if (selectedProperty.propId !== propId) {
        dispatch(deselectWorkflow());
      }
    }
    dispatch(loadWorkflows(orgId, propId));

    await dispatch(loadProperty(orgId, propId));
    dispatch(zoomToOriginalPosition());
    dispatch(cancelPropertyDisplay());
    const mapView = getMapView(getState());

    const propertyLayers = getPropertyLayers(getState());
    if (propertyLayers) {
      propertyLayers.forEach(async (item) => {
        item.labelingInfo = null;
        const layerView = await mapView.whenLayerView(item);
        if (!layerView) return;
        layerView.featureEffect = null;
      });
    }
    dispatch(clearLocationSearchGraphics());
    done();
  }
});

const loadedPropertyLogic = createLogic({
  type: LOADED_PROPERTY,
  process({ globalActions, action, getState, selectors }, dispatch, done) {
    const {
      setDocTitle,
      setFeatureLayers,
      setDefaultFeatureLayers,
      setMapPosition,
      loadPropertyUsers
    } = globalActions;
    const { title, layerUrls, extent } = action.payload;
    const { getSelectedOrgId } = selectors;
    const selectedOrgId = getSelectedOrgId(getState());

    const path = window.location.pathname;
    if (path.indexOf(URL_DELIMITER_WORKFLOW) === -1) {
      dispatch(setDocTitle(title));
      dispatch(setFeatureLayers(layerUrls));
    }
    dispatch(setDefaultFeatureLayers(layerUrls));
    dispatch(setMapPosition(extent));

    if (selectedOrgId) dispatch(loadPropertyUsers());
    done();
  }
});

const selectPropertyHomeLogic = createLogic({
  type: SELECT_PROPERTY_HOME,
  process({ globalActions, selectors, getState }, dispatch, done) {
    const { setMapPosition } = globalActions;
    const { getSelectedProperty } = selectors;
    const selectedProperty = getSelectedProperty(getState());
    const { extent } = selectedProperty;
    dispatch(setMapPosition(extent));
    done();
  }
});

const loadedPropertiesLogic = createLogic({
  type: LOADED_PROPERTIES,
  process({ globalActions, action, selectors, getState }, dispatch, done) {
    try {
      const state = getState();
      const { getSelectedProperty, getPropertyLayerUrl, getLabel } = selectors;
      const { properties } = action.payload;
      const propertySelected = getSelectedProperty(state);
      if (propertySelected) return done();
      const {
        setDefaultFeatureLayers,
        setFeatureLayers,
        loadedWorkflows,
        errorProperty
      } = globalActions;
      const { NO_PROPERTIES_ERROR_LABEL } = getLabel(state);
      if (!properties || properties.length === 0) {
        dispatch(loadedWorkflows([]));
        dispatch(
          errorProperty({
            message: NO_PROPERTIES_ERROR_LABEL
          })
        );
      }
      const featureLayerUrl = getPropertyLayerUrl(state);
      const layerUrls = featureLayerUrl ? [featureLayerUrl] : [];
      dispatch(setDefaultFeatureLayers(layerUrls));
      if (window.location.pathname.indexOf(URL_DELIMITER_PROP) === -1) {
        dispatch(setFeatureLayers(layerUrls));
      }

      const path = window.location.pathname;
      const selectedOrganisationId = window.location.pathname
        .split(`${URL_DELIMITER_ORG}/`)[1]
        .split("/")[0];
      if (
        properties.length === 1 &&
        path.indexOf(URL_DELIMITER_WORKFLOW) === -1 &&
        path.indexOf(URL_DELIMITER_PROP) === -1
      ) {
        const { propId, title } = properties[0];
        navigate(
          `${path}${
            path.charAt(path.length - 1) === "/" ? "" : "/"
          }${URL_DELIMITER_PROP}/${propId}`,
          {
            state: {
              docTitle: title
            }
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
    done();
  }
});

const updatedPropertiesLogic = createLogic({
  type: UPDATED_PROPERTIES,
  process({ globalActions, selectors, getState, action }, dispatch, done) {
    try {
      const state = getState();
      const {
        getSelectedProperty,
        getPropertyLayerUrl,
        getSelectedPropertyGroup,
        getUsePropertyPins
      } = selectors;
      const { setFeatureLayers, setPropertyDisplayLayerFilters } =
        globalActions;
      const propertySelected = getSelectedProperty(state);
      const propertyGroupSelected = getSelectedPropertyGroup(state);
      if (propertySelected || propertyGroupSelected) return done();
      const featureLayerUrl = getPropertyLayerUrl(state);
      dispatch(setPropertyDisplayLayerFilters());
      if (featureLayerUrl && !getUsePropertyPins(getState())) {
        dispatch(setFeatureLayers([featureLayerUrl]));
      }
    } catch (e) {
      console.log(e);
    }
    done();
  }
});

const propertyLayerLabelsLogic = createLogic({
  type: SETUP_LAYER_LABELS,
  warnTimeout: 0,
  process({ selectors, getState }, dispatch, done) {
    try {
      const state = getState();
      const { getPropertyLayers } = selectors;

      const propertyLayers = getPropertyLayers(state);

      const labelClass = new LabelClass({
        labelExpressionInfo: { expression: "$feature.title" },
        labelPlacement: "always-horizontal",
        symbol: {
          type: "text", // autocasts as new TextSymbol()
          color: "black",
          haloSize: 1,
          haloColor: "white",
          font: {
            // autocast as new Font()
            size: 16,
            family: "sans-serif",
            weight: "bold"
          }
        }
      });

      propertyLayers.items.forEach((item) => {
        item.labelingInfo = [labelClass];
      });
      done();
    } catch (e) {
      console.log(e);
    }
  }
});

const setupMapViewListenerLogic = createLogic({
  type: LOADED_PROPERTIES,
  warnTimeout: 0,
  async process(
    { selectors, globalActions, getState, action, agBoxApiRequests },
    dispatch,
    done
  ) {
    const { meta } = action.payload;
    const {
      getPropertyLayers,
      getObjectIdField,
      getMapView,
      getSelectedProperty,
      getSelectedPropertyGroup
    } = selectors;
    const state = getState();
    const objectIdField = getObjectIdField(state);
    const mapView = getMapView(state);
    if (meta.total > 1) {
      let timeout;
      const effect = {
        includedEffect: "brightness(1.5)",
        excludedLabelsVisible: true
      };
      const handleMove = async (event) => {
        const propertySelected = getSelectedProperty(getState());
        const propertyGroupSelected = getSelectedPropertyGroup(getState());
        if (propertySelected || propertyGroupSelected) return;
        const { results } = await mapView.hitTest(event);

        const layers = getPropertyLayers(getState());

        layers.forEach(async (layer) => {
          const layerView = await mapView.whenLayerView(layer);
          if (!layerView) return;
          if (results.length === 0) {
            layerView.featureEffect = null;
          }
          const applicableFeatures = results
            .filter(
              (feature) => feature.layer && feature.layer.title === layer.title
            )
            .map((feature) => feature.graphic);
          if (applicableFeatures.length > 0) {
            layerView.featureEffect = {
              ...effect,
              filter: {
                objectIds: applicableFeatures.map(
                  (feature) => feature.attributes[objectIdField]
                )
              }
            };
          } else {
            layerView.featureEffect = null;
          }
        });
      };
      let moveEvent;
      moveEvent = mapView.on("pointer-move", async (event) => {
        const propertySelected = getSelectedProperty(getState());
        if (propertySelected) {
          if (moveEvent) {
            moveEvent.remove();
            moveEvent = null;
          }
          return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleMove(event);
        }, 50);
      });
    }
    done();
  }
});

const updatePropertiesPaginationLogic = createLogic({
  type: UPDATE_PROPERTIES_PAGINATION,
  warnTimeout: 0,
  process({ globalActions, getState, selectors }, dispatch, done) {
    try {
      const { getSearchPropertiesBy } = selectors;
      const searchBy = getSearchPropertiesBy(getState());
      const { updateProperties, searchPropertiesByLocation } = globalActions;
      if (searchBy === SEARCH_BY_NAME) {
        dispatch(updateProperties());
      } else {
        dispatch(searchPropertiesByLocation());
      }

      done();
    } catch (e) {
      console.log(e);
    }
  }
});

const updatePropertiesSearchLogic = createLogic({
  type: UPDATE_PROPERTIES_SEARCH,
  warnTimeout: 0,
  process({ globalActions, selectors, getState, action }, dispatch, done) {
    try {
      const { updateProperties, clearLocationSearchGraphics } = globalActions;

      dispatch(updateProperties());
      dispatch(clearLocationSearchGraphics());

      done();
    } catch (e) {
      console.log(e);
    }
  }
});

const updateAvailablePropertyLogic = createLogic({
  type: UPDATE_AVAILABLE_PROPERTY,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const { setAvailableProperties } = globalActions;
    const { getAvailableProperties } = selectors;
    const availableProperties = getAvailableProperties(getState());
    const { newPropertyDetails } = action.payload;
    const { propId, title, officialName, address, city, postcode, country } =
      newPropertyDetails;
    const propertyInList = availableProperties.find(
      (property) => property.propId === propId
    );
    if (propertyInList) {
      const newAvailableProperties = availableProperties.map((property) => {
        if (property.propId !== propId) return property;
        return {
          ...property,
          title,
          officialName,
          address,
          city,
          postcode,
          country
        };
      });
      dispatch(setAvailableProperties(newAvailableProperties));
    }

    done();
  }
});

let propsTries = 0;

const loadPropertiesLogic = createLogic({
  type: LOAD_PROPERTIES,
  async process(
    { globalActions, action, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    const { orgId } = action.payload;
    try {
      const {
        getToken,
        getUserId,
        getPropertiesPagination,
        getPropertiesSearch,
        getAbortSignal
      } = selectors;
      const token = getToken(state);
      const userId = getUserId(state);
      const pagination = getPropertiesPagination(state);
      const search = getPropertiesSearch(state);

      const { requestProperties } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const sessionProperty = getSession(
        `user-${userId}-org-${orgId}-properties`
      );
      const { items, meta } = sessionProperty
        ? sessionProperty
        : await requestProperties(
            orgId,
            userId,
            pagination,
            search,
            token,
            signal
          );

      const { loadedProperties, setMapPosition } = globalActions;
      dispatch(loadedProperties(items, meta));
      if (!window.location.pathname.includes(URL_DELIMITER_PROP)) {
        dispatch(setMapPosition(meta.extent));
      }

      propsTries = 0;
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      propsTries++;
      if (propsTries === 3) {
        const { errorProperties } = globalActions;
        dispatch(errorProperties(e));
        propsTries = 0;
      } else if (propsTries < 3) {
        const { loadProperties } = globalActions;
        dispatch(loadProperties(orgId));
      }
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const updatePropertiesLogic = createLogic({
  type: UPDATE_PROPERTIES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    try {
      const { startSearchByLocation, searchPropertiesByName } = globalActions;
      const { getSearchPropertiesBy } = selectors;
      const searchBy = getSearchPropertiesBy(getState());
      if (searchBy === SEARCH_BY_NAME) {
        dispatch(searchPropertiesByName());
      } else if (searchBy === SEARCH_BY_LOCATION) {
        dispatch(startSearchByLocation());
      }
      done();
    } catch (e) {
      console.log(e);
    }
  }
});

const searchPropertiesByNameLogic = createLogic({
  type: SEARCH_PROPERTIES_BY_NAME,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const {
        getSelectedOrgId,
        getUserId,
        getPropertiesPagination,
        getPropertiesSearch,
        getToken,
        getPropertiesSearchController
      } = selectors;
      const token = getToken(state);
      const orgId = getSelectedOrgId(state);
      const userId = getUserId(state);
      const pagination = getPropertiesPagination(state);
      const search = getPropertiesSearch(state);

      const { updatedProperties, updatePropertySearchController } =
        globalActions;
      const { requestProperties } = agBoxApiRequests;
      const controller = getPropertiesSearchController(getState());
      if (controller) {
        controller.abort();
      }
      const newController = new AbortController();
      dispatch(updatePropertySearchController(newController));
      const { items, meta } = await requestProperties(
        orgId,
        userId,
        pagination,
        search,
        token,
        newController.signal
      );

      dispatch(updatedProperties(items, meta));

      done();
    } catch (e) {
      const { errorProperties } = globalActions;
      if ((e.name && e.name !== ABORT_ERROR_NAME) || !e.name) {
        dispatch(errorProperties(e));
      }
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

let propTries = 0;

const loadPropertyLogic = createLogic({
  type: LOAD_PROPERTY,
  async process(
    { globalActions, agBoxApiRequests, action, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(state);
      const { orgId, propId } = action.payload;

      const { requestProperty } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const property = await requestProperty(orgId, propId, token, signal);

      const { loadedProperty } = globalActions;
      dispatch(loadedProperty(property));
      propTries = 0;
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      propTries++;
      if (propTries === 3) {
        const { errorProperty } = globalActions;
        dispatch(errorProperty(e));
        propTries = 0;
      } else if (propTries < 3) {
        const { loadProperty } = globalActions;
        dispatch(loadProperty(action.payload.orgId, action.payload.propId));
      }

      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const errorPropertyLogic = createLogic({
  type: ERROR_PROPERTY,
  process({ globalActions, action, getState, selectors }, dispatch, done) {
    const { setMapPosition } = globalActions;
    const { getSelectedOrg } = selectors;
    if (action.payload === false && getSelectedOrg(getState())) {
      dispatch(setMapPosition());
    }
    done();
  }
});

const unloadPropertyLogic = createLogic({
  latest: true,
  type: UNLOAD_PROPERTY,
  async process(
    { globalActions, action, getState, selectors },
    dispatch,
    done
  ) {
    const {
      getPropertiesExtent,
      getZoomToSearchResults,
      getSearchPropertiesBy
    } = selectors;
    const {
      abortRequest,
      setMapPosition,
      startPropertyDisplay,
      zoomToSearchResults
    } = globalActions;
    const propertiesExtent = getPropertiesExtent(getState());
    const isOrgView =
      !window.location.pathname.includes(URL_DELIMITER_PROP) &&
      window.location.hash !== URL_DELIMITER_SELECT_ORG;
    if (isOrgView) {
      if (propertiesExtent) {
        dispatch(setMapPosition(propertiesExtent));
      }
      dispatch(startPropertyDisplay());
      if (
        getZoomToSearchResults(getState()) &&
        getSearchPropertiesBy(getState()) === SEARCH_BY_LOCATION
      )
        dispatch(zoomToSearchResults());
    }
    done();
  }
});

const startSearchByLocationLogic = createLogic({
  latest: true,
  type: START_SEARCH_BY_LOCATION,
  async process(
    { globalActions, getState, selectors, agBoxApiRequests },
    dispatch,
    done
  ) {
    try {
      const { getPropertiesSearch, getAbortSignal } = selectors;
      const {
        setPropertySearchSuggestions,
        zoomToOriginalPosition,
        startSearchByCoordinates
      } = globalActions;
      const search = getPropertiesSearch(getState());
      if (!search) {
        dispatch(setPropertySearchSuggestions([]));
        dispatch(zoomToOriginalPosition());
        return done();
      } else if (search.charAt(0) === "[") {
        dispatch(startSearchByCoordinates());
        return done();
      } else {
        const { requestAddressSuggestions } = agBoxApiRequests;
        const signal = getAbortSignal(getState());
        const { suggestions } = await requestAddressSuggestions(search, signal);
        dispatch(setPropertySearchSuggestions(suggestions));
        return done();
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      const { errorPropertiesSearch } = globalActions;
      dispatch(errorPropertiesSearch(e.message));
      done();
    }
  }
});

const setSelectedPropertySearchSuggestionLogic = createLogic({
  latest: true,
  type: SET_SELECTED_PROPERTY_SEARCH_SUGGESTION,
  process(
    { globalActions, getState, selectors, agBoxApiRequests },
    dispatch,
    done
  ) {
    const { loadAddressCandidate } = globalActions;
    dispatch(loadAddressCandidate());
    done();
  }
});

const loadAddressCandidateLogic = createLogic({
  latest: true,
  type: LOAD_ADDRESS_CANDIDATE,
  async process(
    { globalActions, getState, selectors, agBoxApiRequests },
    dispatch,
    done
  ) {
    try {
      const { getSelectedPropertySearchSuggestion, getAbortSignal } = selectors;
      const { setAddressCandidate, addLocationSearchMarker } = globalActions;
      const selectedSuggestion = getSelectedPropertySearchSuggestion(
        getState()
      );
      const signal = getAbortSignal(getState());
      const { requestAddressCandidates } = agBoxApiRequests;
      const { text, magicKey } = selectedSuggestion;
      const { candidates } = await requestAddressCandidates(
        text,
        magicKey,
        signal
      );
      const candidate =
        candidates && candidates.length > 0 ? candidates[0] : null;
      dispatch(setAddressCandidate(candidate));
      dispatch(addLocationSearchMarker(candidate));
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      if (e.name && e.name === ABORT_ERROR_NAME) return;
      const { errorPropertiesSearch } = globalActions;
      dispatch(errorPropertiesSearch(e.message));
    }
    done();
  }
});

const setAddressCandidateLogic = createLogic({
  latest: true,
  type: SET_ADDRESS_CANDIDATE,
  async process(
    { globalActions, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const { setPropertyDisplayLayerFilters } = globalActions;
      const addressCandidate = action.payload;
      let searchArea = null;
      if (addressCandidate) {
        const { location } = addressCandidate;
        const { getOrganisationPreferences } = selectors;
        const { setPropertyGeometrySearch } = globalActions;
        const preferences = getOrganisationPreferences(getState());
        const { addressSearchRadius } = preferences;
        const radius = addressSearchRadius
          ? addressSearchRadius
          : DEFAULT_ADDRESS_SEARCH_RADIUS;
        searchArea = new Circle({
          center: location,
          radius,
          radiusUnit: "kilometers",
          geodesic: true
        });
        dispatch(setPropertyGeometrySearch(searchArea));
      }
      dispatch(setPropertyDisplayLayerFilters(searchArea));
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }

    done();
  }
});

const setPropertyGeometrySearchLogic = createLogic({
  latest: true,
  type: SET_PROPERTY_GEOMETRY_SEARCH,
  async process(
    { globalActions, getState, selectors, agBoxApiRequests, action },
    dispatch,
    done
  ) {
    try {
      const { searchPropertiesByLocation } = globalActions;
      dispatch(searchPropertiesByLocation());
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }

    done();
  }
});

const searchPropertiesByLocationLogic = createLogic({
  type: SEARCH_PROPERTIES_BY_LOCATION,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const {
        getSelectedOrgId,
        getUserId,
        getPropertiesPagination,
        getToken,
        getPropertiesSearchController,
        getPropertiesSearchGeometry,
        getZoomToSearchResults
      } = selectors;
      const token = getToken(state);
      const orgId = getSelectedOrgId(state);
      const userId = getUserId(state);
      const searchGeometry = getPropertiesSearchGeometry(state);
      const pagination = getPropertiesPagination(state);
      const {
        updatedProperties,
        updatePropertySearchController,
        zoomToSearchResults
      } = globalActions;
      const { requestUserFeaturesByGeometry } = agBoxApiRequests;
      const controller = getPropertiesSearchController(getState());
      if (controller) {
        controller.abort();
      }
      const newController = new AbortController();
      dispatch(updatePropertySearchController(newController));
      const { items, meta } = await requestUserFeaturesByGeometry(
        "Property",
        orgId,
        userId,
        0,
        searchGeometry,
        token,
        newController.signal,
        pagination
      );

      let total = pagination.total;
      if (!total) {
        const { count } = await requestUserFeaturesByGeometry(
          "Property",
          orgId,
          userId,
          0,
          searchGeometry,
          token,
          newController.signal,
          null,
          true
        );
        total = count;
      }

      dispatch(
        updatedProperties(items, {
          ...meta,
          total
        })
      );

      if (getZoomToSearchResults(getState())) {
        dispatch(zoomToSearchResults());
      }

      done();
    } catch (e) {
      const { errorProperties } = globalActions;
      if ((e.name && e.name !== ABORT_ERROR_NAME) || !e.name) {
        dispatch(errorProperties(e));
      }
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const setZoomToSearchResultsLogic = createLogic({
  type: SET_ZOOM_TO_SEARCH_RESULTS,
  async process(
    { globalActions, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const shouldZoomToSearchResults = action.payload;

      const { zoomToSearchResults, zoomToOriginalPosition } = globalActions;

      if (shouldZoomToSearchResults) {
        dispatch(zoomToSearchResults());
      } else {
        dispatch(zoomToOriginalPosition());
      }

      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const zoomToSearchResultsLogic = createLogic({
  type: ZOOM_TO_SEARCH_RESULTS,
  async process({ globalActions, getState, selectors }, dispatch, done) {
    const state = getState();
    try {
      const {
        getMapView,
        getWebMap,
        getSearchMeta,
        getPropertiesSearchGeometry,
        getSelectedProperty,
        getSelectedPropertyGroup,
        getOrganisationPreferences
      } = selectors;
      const { setMapPosition } = globalActions;
      const mapView = getMapView(state);
      const orgPreferences = getOrganisationPreferences(state);
      const webMap = getWebMap(state);
      const existingLocationSearchLayers = webMap.layers.items.filter((layer) =>
        layer.title.includes("locationSearch")
      );
      webMap.removeMany(existingLocationSearchLayers);

      const searchGraphic = mapView.graphics.items.find(
        (graphic) => graphic.attributes.title === "propertySearchArea"
      );
      if (searchGraphic) {
        mapView.graphics.remove(searchGraphic);
      }
      const searchMeta = getSearchMeta(getState());
      const searchGeometry = getPropertiesSearchGeometry(getState());
      if ((!searchMeta || !searchMeta.layerUrls) && !searchGeometry)
        return done();
      const { layerUrls } = searchMeta;

      if (searchGeometry) {
        const bufferedSearchArea = geodesicBuffer(
          searchGeometry,
          200,
          UNIT_METERS
        );

        const projectedGeometry = await project(GEOMETRY_SERVICE_URL, {
          geometries: [bufferedSearchArea, searchGeometry],
          outSpatialReference: mapView.spatialReference
        });
        dispatch(setMapPosition(projectedGeometry[0].extent));

        const newSearchGraphic = new Graphic({
          geometry: projectedGeometry[1],
          symbol: {
            color: [230, 230, 0, 0],
            type: "simple-fill",
            outline: {
              color: [250, 250, 0, 0.9],
              width: 3
            }
          },
          attributes: {
            title: "propertySearchArea"
          },
          visible: true
        });
        mapView.graphics.add(newSearchGraphic);
      }

      if (
        searchGeometry &&
        (getSelectedProperty(getState()) ||
          getSelectedPropertyGroup(getState())) &&
        layerUrls &&
        layerUrls.length
      ) {
        const layersToAdd = layerUrls.filter(
          (layerUrl) =>
            !webMap.layers.items.find((layer) => layer.url === layerUrl)
        );
        const layers = layersToAdd.map(({ url, title }) => {
          let layerRenderer = null;
          if (
            orgPreferences.layerRenderersJSON &&
            orgPreferences.layerRenderersJSON[title]
          ) {
            const renderer = orgPreferences.layerRenderersJSON[title];
            const { type = "" } = renderer;
            if (type.includes("unique"))
              layerRenderer = UniqueValueRenderer.fromJSON(renderer);
            else if (type === "simple")
              layerRenderer = SimpleRenderer.fromJSON(renderer);
          }

          let labelingInfo = [
            {
              labelExpressionInfo: { expression: "$feature.title" },
              labelPlacement: "always-horizontal",
              symbol: {
                type: "text", // autocasts as new TextSymbol()
                color: "black",
                haloSize: 1,
                haloColor: "white",
                font: {
                  // autocast as new Font()
                  size: 16,
                  family: "sans-serif",
                  weight: "bold"
                }
              }
            }
          ];
          if (
            orgPreferences.layerLabelsJSON &&
            orgPreferences.layerLabelsJSON[title]
          )
            labelingInfo = [
              LabelClass.fromJSON(orgPreferences.layerLabelsJSON[title])
            ];
          return new FeatureLayer({
            url,
            title: `${title}-locationSearch`,
            renderer: layerRenderer,
            labelingInfo
          });
        });
        webMap.addMany(layers);
      }

      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const zoomToOriginalPositionLogic = createLogic({
  type: ZOOM_TO_ORIGINAL_POSITION,
  async process({ globalActions, getState, selectors }, dispatch, done) {
    const state = getState();
    try {
      const {
        getMapView,
        getWebMap,
        getPropertiesExtent,
        getSelectedProperty,
        getSelectedPropertyGroup
      } = selectors;
      const { setMapPosition } = globalActions;
      const mapView = getMapView(state);
      const webMap = getWebMap(state);
      const existingLocationSearchLayers = webMap.layers.items.filter((layer) =>
        layer.title.includes("locationSearch")
      );
      if (existingLocationSearchLayers.length > 0) {
        webMap.removeMany(existingLocationSearchLayers);
      }
      const searchGraphic = mapView.graphics.items.find(
        (graphic) => graphic.attributes.title === "propertySearchArea"
      );
      if (searchGraphic) {
        mapView.graphics.remove(searchGraphic);
      }
      const selectedProperty = getSelectedProperty(getState());
      const selectedPropertyGroup = getSelectedPropertyGroup(getState());
      const propertiesExtent = getPropertiesExtent(getState());
      let extent = null;
      if (window.location.pathname.includes(URL_DELIMITER_PROP)) {
        extent = selectedProperty
          ? selectedProperty.extent
          : selectedPropertyGroup
          ? selectedPropertyGroup.extent
          : null;
      } else if (propertiesExtent) {
        extent = propertiesExtent;
      }
      if (extent) {
        dispatch(setMapPosition(extent));
      }

      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const addLocationSearchMarkerLogic = createLogic({
  type: ADD_LOCATION_SEARCH_MARKER,
  async process(
    { globalActions, getState, selectors, action },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const { getMapView } = selectors;
      const mapView = getMapView(state);
      const addressCandidate = action.payload;

      const existingMarker = mapView.graphics.items.find(
        (graphic) => graphic.attributes.title === LOCATION_SEARCH_MARKER_TITLE
      );
      if (existingMarker) {
        mapView.graphics.remove(existingMarker);
      }

      if (addressCandidate && addressCandidate.location) {
        const { location } = addressCandidate;
        const marker = new Graphic({
          symbol: LOCATION_SEARCH_MARKER_SYMBOL,
          geometry: {
            ...location,
            type: "point",
            spatialReference: {
              wkid: 4326
            }
          },
          attributes: {
            title: LOCATION_SEARCH_MARKER_TITLE
          },
          visible: true
        });
        mapView.graphics.add(marker);
      }

      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const clearLocationSearchGraphics = createLogic({
  type: CLEAR_LOCATION_SEARCH_GRAPHICS,
  async process(
    { globalActions, getState, selectors, action },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const {
        getMapView,
        getWebMap,
        getSelectedProperty,
        getSelectedPropertyGroup
      } = selectors;
      const { setPropertyDisplayLayerFilters } = globalActions;
      const mapView = getMapView(state);
      const webMap = getWebMap(state);

      const searchMarker = mapView.graphics.items.find(
        (graphic) => graphic.attributes.title === LOCATION_SEARCH_MARKER_TITLE
      );
      if (searchMarker) {
        mapView.graphics.remove(searchMarker);
      }

      const searchCircle = mapView.graphics.items.find(
        (graphic) => graphic.attributes.title === "propertySearchArea"
      );
      if (searchCircle) {
        mapView.graphics.remove(searchCircle);
      }

      const propertyLocationSearchLayer = webMap.layers.items.find(
        (layer) => layer.title === "propertyPoly-locationSearch"
      );
      if (propertyLocationSearchLayer)
        webMap.remove(propertyLocationSearchLayer);

      const propertyLayer = webMap.layers.items.find(
        (layer) => layer.title === "propertyPoly"
      );
      if (
        propertyLayer &&
        !getSelectedProperty(getState()) &&
        !getSelectedPropertyGroup(getState())
      )
        propertyLayer.visible = false;

      dispatch(setPropertyDisplayLayerFilters(null));
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const setSearchPropertiesByLogic = createLogic({
  type: SET_SEARCH_PROPERTIES_BY,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const { setPropertyDisplayLayerFilters } = globalActions;
    const { getSelectedProperty } = selectors;
    if (getSelectedProperty(getState())) return done();
    dispatch(setPropertyDisplayLayerFilters(null));
    done();
  }
});

const startSearchByCoordinatesLogic = createLogic({
  type: START_SEARCH_BY_COORDINATES,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const { getPropertiesSearch } = selectors;
    const { addLocationSearchMarker, setAddressCandidate } = globalActions;
    const search = getPropertiesSearch(getState());
    if (
      !search ||
      !isValidJSON(search) ||
      !Array.isArray(JSON.parse(search)) ||
      JSON.parse(search).length !== 2
    ) {
      dispatch(setAddressCandidate(null));
      return done();
    }

    const location = JSON.parse(search);
    const candidate = {
      location: {
        x: location[0],
        y: location[1]
      }
    };
    dispatch(setAddressCandidate(candidate));
    dispatch(addLocationSearchMarker(candidate));
    done();
  }
});

const updateFavouritePropertiesLogic = createLogic({
  type: UPDATE_FAVOURITE_PROPERTIES,
  async process(
    // @ts-ignore
    { globalActions, selectors, agBoxApiRequests, action, getState },
    dispatch,
    done
  ) {
    try {
      const { getToken, getAbortSignal, getUserId, getLabel } = selectors;
      const { updatedFavouriteProperties } = globalActions;
      const { updateUser } = agBoxApiRequests;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const userId = getUserId(getState());
      const {
        PROPERTY_FAVORITE_SUCCESS_LABEL,
        PROPERTY_UNFAVORITE_SUCCESS_LABEL,
        NOTIFICATION_SUCCESS_LABEL
      } = getLabel(getState());

      const {
        orgId,
        updates: { adds, removes }
      } = action.payload;

      const body = {};
      if (adds.length) {
        body.addPropertyFavorites = {
          orgId,
          propIds: adds
        };
      }

      if (removes.length) {
        body.removePropertyFavorites = {
          orgId,
          propIds: removes
        };
      }

      const results = await updateUser(
        userId,
        JSON.stringify(body),
        token,
        signal
      );

      dispatch(
        updatedFavouriteProperties(orgId, results.preferences.propertyFavorites)
      );
      NotificationManager.success(
        adds.length
          ? PROPERTY_FAVORITE_SUCCESS_LABEL
          : PROPERTY_UNFAVORITE_SUCCESS_LABEL,
        NOTIFICATION_SUCCESS_LABEL
      );
    } catch (e) {
      if (e.name !== ABORT_ERROR_NAME) {
        if (process.env.NODE_ENV === "development") console.log(e);
        const { setGenericError } = globalActions;
        dispatch(setGenericError(e));
      }
    }
    done();
  }
});

const updatedFavouritePropertiesLogic = createLogic({
  type: UPDATED_FAVOURITE_PROPERTIES,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const { getUserId } = selectors;
    const userId = getUserId(getState());
    const { favoriteProperties, orgId } = action.payload;

    //removed the strored properties in session storage so we don't have the incorrect isFavorite flag
    clearSession(`user-${userId}-org-${orgId}-properties`);
    storeSession(
      `user-${userId}-org-${orgId}-favourite-properties`,
      favoriteProperties
    );
    done();
  }
});

const loadPropertyUsersLogic = createLogic({
  type: LOAD_PROPERTY_USERS,
  async process(
    { globalActions, action, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const {
        getToken,
        getUserId,
        getSelectedOrgId,
        getSelectedPropId,
        getAbortSignal
      } = selectors;
      const token = getToken(state);
      const userId = getUserId(state);
      const orgId = getSelectedOrgId(getState());
      const propId = getSelectedPropId(getState());

      const { requestPropertyUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const sessionProperty = getSession(
        `user-${userId}-org-${orgId}-property-${propId}-users`
      );

      const { items, meta } = sessionProperty
        ? sessionProperty
        : await requestPropertyUsers(orgId, propId, userId, token, signal);

      const { loadedPropertyUsers } = globalActions;
      dispatch(loadedPropertyUsers(items));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

export default [
  selectPropertyLogic,
  loadedPropertyLogic,
  selectPropertyHomeLogic,
  setupMapViewListenerLogic,
  loadedPropertiesLogic,
  propertyLayerLabelsLogic,
  updatePropertiesPaginationLogic,
  updatedPropertiesLogic,
  updatePropertiesSearchLogic,
  updateAvailablePropertyLogic,
  loadPropertiesLogic,
  updatePropertiesLogic,
  loadPropertyLogic,
  errorPropertyLogic,
  unloadPropertyLogic,
  startSearchByLocationLogic,
  setSelectedPropertySearchSuggestionLogic,
  loadAddressCandidateLogic,
  setAddressCandidateLogic,
  setPropertyGeometrySearchLogic,
  searchPropertiesByLocationLogic,
  searchPropertiesByNameLogic,
  setZoomToSearchResultsLogic,
  zoomToSearchResultsLogic,
  zoomToOriginalPositionLogic,
  addLocationSearchMarkerLogic,
  clearLocationSearchGraphics,
  setSearchPropertiesByLogic,
  startSearchByCoordinatesLogic,
  updateFavouritePropertiesLogic,
  updatedFavouritePropertiesLogic,
  loadPropertyUsersLogic
];
