import React, { Component } from "react";
import {
  LOADER_SIZE_REGULAR,
  LOADER_SIZE_SMALL,
  URL_DELIMITER_ORG,
  ASSETS_PROXY_SUBSTRING
} from "../constants";

import {
  Span,
  OrganisationImage,
  OrganisationSelectorList,
  ButtonLink,
  OrganisationDescription,
  OrganisationDescriptionWrap,
  OrganisationExpiredMessage
} from "@agBoxUiKit/core/components";

import { OrganisationSelectorListWrap } from "@agBoxUiKit/core/layout";
import { Button } from "../AgBoxUIKit/core/components";
import { Loader, Modal } from "../AgBoxUIKit/core";
import { parseProxyUrl } from "../App/utils";

/**
 * Opens a modal with the available organisations as buttons to select.
 * Each organisation can show a logo, a title, and a description.
 *
 * On selecting an organisation, fires off the actions to select that organisation and navigates to the organisation's URL.
 */
class OrganisationSelector extends Component {
  componentDidMount() {
    const { errorProperties, propertiesError, userId, loadInvitations } =
      this.props;
    if (propertiesError) errorProperties(false);
    if (userId) loadInvitations(userId);
  }

  checkIfSameOrg = (orgId) => {
    const { selectedOrganisation, setShowMap } = this.props;
    if (selectedOrganisation && selectedOrganisation.orgId === orgId) {
      setShowMap(true);
    }
  };

  handleSelectOrganisation = async (e) => {
    const orgId = e.currentTarget.dataset.orgid;
    if (!orgId) return;
    const { closeSidebar } = this.props;
    this.checkIfSameOrg(orgId);
    closeSidebar();
  };

  getOrgLogo = (org) => {
    const logoUrl = org.logo || org.orgLogo;
    if (!logoUrl) return "";
    return parseProxyUrl(logoUrl);
  };

  orgsLoaded = () => {
    const { availableOrganisations, loadingOrganisations } = this.props;
    return !loadingOrganisations && availableOrganisations.length > 0;
  };

  handleCancelSelection = () => {
    window.location.hash = "";
  };

  availableOrgs = () => {
    const { availableOrganisations } = this.props;
    return availableOrganisations ? availableOrganisations : [];
  };

  getSelectedOrganisation = () => {
    return this.props.selectedOrganisation;
  };

  getInvitations = () => {
    const { invitations } = this.props;
    return invitations ? invitations : [];
  };

  handleAcceptInvitation = (e) => {
    const orgId = e.currentTarget.dataset.orgid;
    const inviteId = e.currentTarget.dataset.inviteid;
    if (!orgId || !inviteId) return;
    const {
      acceptInvitation,
      setLoadingText,
      setShowMap,
      labels: { ACCEPTING_INVITATION_LABEL }
    } = this.props;
    setLoadingText(`${ACCEPTING_INVITATION_LABEL} ${orgId}`);
    setShowMap(false);
    acceptInvitation(orgId, inviteId);
    window.location.hash = "";
  };

  getInvitationDescription = (invitation) => {
    const { INVITED_BY_LABEL } = this.props.labels;
    return `${INVITED_BY_LABEL} ${invitation.invitedByFirstName}`;
  };

  showOrgList = () => {
    const { loadingInvites } = this.props;
    return !loadingInvites && this.orgsLoaded() && !this.acceptingInvitation();
  };

  acceptingInvitation = () => {
    const { acceptingInvite } = this.props;
    return acceptingInvite;
  };

  getModalBody = () => {
    const {
      labels: {
        ORGANISATION_MEMBERSHIP_EXPIRED_LABEL,
        LOADING_ORGANISATIONS_LABEL,
        LOADING_INVITATIONS_LABEL
      },
      loadingOrganisations,
      loadingInvites
    } = this.props;
    return (
      <OrganisationSelectorListWrap>
        {loadingInvites && (
          <Loader
            size={
              loadingOrganisations ? LOADER_SIZE_REGULAR : LOADER_SIZE_SMALL
            }
            loadingText={LOADING_INVITATIONS_LABEL}
          />
        )}
        <OrganisationSelectorList
          data-name="OrganisationSelectorList"
          itemLength={
            this.availableOrgs().length + this.getInvitations().length
          }
        >
          {loadingOrganisations ? (
            <Loader loadingText={LOADING_ORGANISATIONS_LABEL} />
          ) : (
            this.availableOrgs().map((org, index) => (
              <ButtonLink
                disabled={org.expired === true}
                tabIndex={index === 0 ? 0 : null}
                data-name={"org-item"}
                type={"orgItem"}
                key={org.orgId}
                to={`/${URL_DELIMITER_ORG}/${org.orgId}`}
                data-orgid={org.orgId}
                onClick={this.handleSelectOrganisation}
                state={{
                  docTitle: org.title
                }}
              >
                <OrganisationImage
                  backgroundImage={this.getOrgLogo(org)}
                  title={`Logo image for ${org.title}`}
                />

                <Span>{org.title}</Span>
                {org.description ? (
                  <OrganisationDescriptionWrap>
                    <OrganisationDescription>
                      {org.description}
                    </OrganisationDescription>
                  </OrganisationDescriptionWrap>
                ) : null}
                {org.expired ? (
                  <OrganisationExpiredMessage>
                    {ORGANISATION_MEMBERSHIP_EXPIRED_LABEL}
                  </OrganisationExpiredMessage>
                ) : null}
              </ButtonLink>
            ))
          )}
          {this.getInvitations().map((invitation, index) => (
            <Button
              tabIndex={index === 0 ? 0 : null}
              data-name={"org-item"}
              styletype={"orgItem"}
              key={invitation.orgId}
              data-orgid={invitation.orgId}
              data-inviteid={invitation.inviteId}
              onClick={this.handleAcceptInvitation}
            >
              <OrganisationImage
                backgroundImage={this.getOrgLogo(invitation)}
                title={`Logo image for ${invitation.orgTitle}`}
              />
              <Span>{invitation.orgTitle}</Span>
              <OrganisationDescriptionWrap>
                <OrganisationDescription>
                  {this.getInvitationDescription(invitation)}
                </OrganisationDescription>
              </OrganisationDescriptionWrap>
            </Button>
          ))}
        </OrganisationSelectorList>
      </OrganisationSelectorListWrap>
    );
  };

  getCloseOnClickOutside = () => {
    const { selectedOrganisation } = this.props;
    return selectedOrganisation ? true : false;
  };

  render() {
    const { SELECT_ORG_DOC_TITLE, CANCEL_ORG_SELECTION_LABEL } =
      this.props.labels;
    return (
      <Modal
        isOpen={true}
        title={SELECT_ORG_DOC_TITLE}
        body={this.getModalBody()}
        onClose={this.handleCancelSelection}
        showCloseButton={this.getSelectedOrganisation() ? true : false}
        secondaryButtonTitle={CANCEL_ORG_SELECTION_LABEL}
        secondaryButtonAction={
          this.getSelectedOrganisation() ? this.handleCancelSelection : null
        }
        closeOnEsc={this.getCloseOnClickOutside()}
        closeOnOverlayClick={this.getCloseOnClickOutside()}
      />
    );
  }
}

export default OrganisationSelector;
