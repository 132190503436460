import styled from "styled-components/macro";
import {
  Label,
  InputGroup,
  TabContentContainer,
  ToolTipWrapper,
  ButtonLabel,
  IconSymbol
} from "../../core/components";
import { loaderSizeSmall } from "../../";

import {
  Input,
  Heading3,
  Heading5,
  Heading6,
  IconBackground,
  Button
} from "../../core/components";
import {
  PackageSubHeading,
  PackageHelpDescription,
  PackageHeadingStyle,
  FeatureDetailsFormItem,
  FeatureDetailsItem
} from "../components";
import {
  WidgetPanelContainer,
  LoaderWrapper,
  PaginationContainer
} from "../../core/layout";
import {
  PLUGIN_SIZE_DOUBLE,
  PLUGIN_SIZE_FULL,
  PLUGIN_SIZE_HALF
} from "../../../constants";
import { isNullOrUndefined } from "../../../App/utils";

// @ts-ignore
export const PackageWrapper = styled.div`
  height: 100%;
  left: ${(props) => {
    return `${props.theme.menuWidthClose}`;
  }};
  position: absolute;
  overflow: hidden;
  top: 0;
  z-index: ${(props) => (props.workflowPanelOpen ? "99" : "100")};
  width: ${(props) => {
    return `calc(100vw - ${props.theme.menuWidthClose})`;
  }};
  pointer-events: none;

  & > div {
    height: 100%;
  }
`;
// @ts-ignore
export const PackagePluginsWrapper = styled.div``;

// @ts-ignore
export const PackageRightPluginsContainer = styled.div`
  right: 0px;
  top: 200px;
  height: calc(100% - 200px);
  width: ${(props) => props.theme.agPluginSize}px;
  position: absolute;
`;

// @ts-ignore
export const PackageTopPluginsContainer = styled.div`
  top: 0px;
  left: ${(props) => (props.padLeft ? props.theme.agPluginSize : "0")}px;
  height: ${(props) => props.theme.agPluginSize}px;
  width: calc(
    100% - ${(props) => (props.padLeft ? props.theme.agPluginSize : 0)}px -
      ${(props) => (props.padRight ? props.theme.agPluginSize : 50)}px
  );
  position: absolute;
`;

// @ts-ignore
export const PackageBottomPluginsContainer = styled.div`
  bottom: 0px;
  left: ${(props) => (props.padLeft ? props.theme.agPluginSize : "0")}px;
  height: ${(props) => props.theme.agPluginSize}px;
  width: calc(
    100% - ${(props) => (props.padLeft ? props.theme.agPluginSize : 0)}px -
      ${(props) => (props.padRight ? props.theme.agPluginSize : 0)}px
  );
  position: absolute;
`;

// @ts-ignore
export const PluginWrapper = styled.div`
  background-color: ${(props) => props.theme.agWhite};
  width: inherit;
  pointer-events: all;
  height: ${(props) => getSizeValue(props.size)};
  left: ${(props) => {
    if (props.isOpen || isNullOrUndefined(props.isOpen)) return "0";
    return "calc(-100% - 60px)";
  }};
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.2s left ease;
  @media all and (min-width: 500px) {
    width: ${(props) => {
      const {
        size,
        theme: { agPluginSize, agPluginSizeDouble, aspect, landscape, square }
      } = props;
      if (size === PLUGIN_SIZE_FULL) return "100%";
      else if (size === PLUGIN_SIZE_HALF) return "50%";
      else if (size === PLUGIN_SIZE_DOUBLE)
        return aspect === landscape
          ? `calc(${agPluginSizeDouble}px + 8vw)`
          : aspect === square
          ? `calc(${agPluginSizeDouble}px + 5vw)`
          : `calc(${agPluginSizeDouble}px + 5vh)`;
      else return `calc(${agPluginSize}px + 5vw)`;
    }};
    left: ${(props) => {
      const {
        isOpen,
        size,
        theme: {
          agPluginSize,
          agPluginSizeDouble,
          aspect,
          landscape,
          square,
          menuWidthClose
        }
      } = props;
      if (isOpen || isNullOrUndefined(isOpen)) return "0";
      else if (size === PLUGIN_SIZE_FULL)
        return `calc(-100% - ${menuWidthClose})`;
      else if (size === PLUGIN_SIZE_HALF)
        return `calc(-50% - ${menuWidthClose})`;
      else if (size === PLUGIN_SIZE_DOUBLE)
        return aspect === landscape
          ? `calc(-${agPluginSizeDouble}px - 8vw - ${menuWidthClose})`
          : aspect === square
          ? `calc(-${agPluginSizeDouble}px - 5vw - ${menuWidthClose})`
          : `calc(-${agPluginSizeDouble}px - 5vh - ${menuWidthClose})`;
      return `calc(-${agPluginSize}px - 5vw - ${menuWidthClose})`;
    }};
  }

  ${PackageRightPluginsContainer} & {
    height: ${(props) => getSizeValue(props.size)};
  }

  ${PackageTopPluginsContainer} & {
    width: ${(props) => getSizeValue(props.size)};
  }

  ${PackageBottomPluginsContainer} & {
    width: ${(props) => getSizeValue(props.size)}; //TEMP FOR PRESENTATION
    bottom: 80px;
    height: 70px;
    position: absolute;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

export const PluginContainer = styled.div`
  height: 100%;
  max-height: 100%;
  background-color: ${(props) => props.theme.agWhite};

  ${PackageBottomPluginsContainer} & {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem 2rem;
  }
  div[tabindex="-1"] {
    height: 100%;
  }
`;
PluginContainer.displayName = "PluginContainer";

export const PackageViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5em 1.8em 1.8em;
  overflow-y: auto;
  overflow-wrap: normal;
  height: 100vh;
  box-sizing: border-box;
  > * {
    box-sizing: border-box;
  }
`;
PackageViewWrapper.displayName = "PackageViewWrapper";

export const PackageViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-wrap: normal;
  flex-grow: 99;
  height: 100%;
`;
PackageViewContainer.displayName = "PackageViewContainer";

export const BottomWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 0.6em;
`;
BottomWrapper.displayName = "BottomWrapper";

export const BottomContainer = styled.div`
  button,
  a {
    width: ${(props) =>
      props.type === "selectOrgCancelButton" ? "auto" : "100%"};
    white-space: normal;

    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  button + button,
  button + a,
  a + a,
  a + button {
    margin-top: 0.6em;
  }
`;
BottomContainer.displayName = "BottomContainer";

export const PackageDataFormContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.agGray};
  padding-bottom: 2rem;

  display: flex;
  flex-direction: column;

  ${Heading3} {
    display: flex;
    font-size: ${(props) => props.theme.fontSizeOne};
    justify-content: space-between;
    margin-bottom: 1rem;

    button {
      font-size: ${(props) => props.theme.fontSizeSmallest};
    }
  }
  label {
    font-size: ${(props) => props.theme.fontSizeOne};
    margin-bottom: 0.5rem;
  }
  ${Label} {
    font-size: ${(props) => props.theme.fontSizeOne};
    margin-bottom: 0.5rem;
  }
  select {
    color: ${(props) => props.theme.agBlue};
    padding: 0.5rem;
    font-family: ${(props) => props.theme.baseFontAlternative};
    margin-bottom: 1.3rem;
  }

  .date-picker {
    margin-bottom: 1.3rem;
  }

  textarea {
    font-family: ${(props) => props.theme.baseFontAlternative};
    font-size: ${(props) => props.theme.fontSizeOne};
    padding: 0.8rem;
  }

  ${Input} {
    &[type="text"] {
      margin-bottom: 1.3rem;
      padding: 0.5rem;
      border-radius: 0.1rem;
    }
  }

  &:last-child {
    border: none;
  }
`;
PackageDataFormContainer.displayName = "PackageDataFormContainer";

export const PackageFilterContainer = styled.div`
  background-color: ${(props) => props.theme.agWhite};
  border-radius: 0.3rem;
  flex-shrink: 0;
  padding: 0.6rem;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  input[type="radio"] {
    margin-right: 0.25rem;
  }
`;
PackageFilterContainer.displayName = "PackageFilterContainer";

export const PackageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > div {
    display: flex;
  }
`;
PackageRow.displayName = "PackageRow";

const getSizeValue = (size) => {
  switch (size) {
    case "full":
      return "100%";
    case "half":
      return "calc(100% / 2)";
    default:
      return "inherit";
  }
};

export const DropdownWrapper = styled.div.attrs({
  className: "DropdownWrapper"
})`
  position: relative;
  display: inline-block;
  &:last-child {
    margin-left: 0.25rem;
  }
`;
DropdownWrapper.displayName = "DropdownWrapper";

export const PackageDetailsViewContainer = styled.div`
  border: 1px solid ${(props) => props.theme.agLightestGray};
  border-radius: 0.2em;
  background-color: ${(props) => props.theme.agOffWhite};
  padding: 1rem;
  margin-top: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: normal;
  flex-shrink: 0;

  & + ${PackageSubHeading} {
    margin-top: 2rem;
  }

  ${PackageHeadingStyle} + & {
    margin-top: 1rem;
  }

  & + ${DropdownWrapper} {
    right: 0;
  }
  ${FeatureDetailsItem} {
    padding: 0.5em 0.5em 0.2em 0.5em;
  }
`;
PackageDetailsViewContainer.displayName = "PackageDetailsViewContainer";

export const PackageSubHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: ${(props) => (props.align ? props.align : "center")};
  margin: 0px 0px 1vh;
  justify-content: space-between;
  & ${PackageSubHeading} {
    display: flex;
    &:nth-child(2) {
      margin-top: 1vh;
    }
  }
  ${ToolTipWrapper} {
    margin-left: 1em;
  }

  ${PackageHeadingStyle} {
    width: 100%;
    + ${DropdownWrapper} {
      margin-bottom: 0.9em;
    }
  }
`;
PackageSubHeadingContainer.displayName = "PackageSubHeadingContainer";

export const PackageViewContentWrap = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
`;
PackageViewContentWrap.displayName = "PackageViewContentWrap";
export const FeatureListInfoContainer = styled.div`
  display: flex;
  height: auto;
  min-height: 2rem;
  justify-content: ${(props) =>
    props.alignRight ? "flex-end" : "space-between"};
  align-items: center;
`;

export const FeatureListWrapper = styled.div`
  opacity: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  flex: 1;
  ${PackageSubHeading} + & {
    margin-top: 1vh;
  }
  ${PackageHelpDescription} + & {
    margin: 0;
    padding: 0;
  }
  ${PackageHeadingStyle} + & {
    margin-top: 1rem;
  }
  ${LoaderWrapper} {
    height: ${(props) => {
      if (props.loaderVisible === false) return "0px";
      else if (props.loaderSize === loaderSizeSmall) return "80px";
    }};
    justify-content: center;
    display: flex;
    visibility: ${(props) => (props.loaderVisible ? "visible" : "hidden")};
    transition: all 0.2s linear;
  }
  + ${PackageSubHeadingContainer} {
    ${PackageSubHeading} {
      margin-top: 1vh;
    }
  }
  ${PaginationContainer} {
    padding-top: 0.5em;
    flex: 0;
    margin-bottom: 0.5em;
    ${FeatureDetailsFormItem} {
      max-width: 150px;
      margin-bottom: 0.8rem;
    }
    ${Button} {
      padding: 0;
      width: 100%;
      height: 3em;
      ${ButtonLabel} {
        font-weight: 500;
      }
      :hover {
        ${ButtonLabel} {
          color: ${(props) => props.theme.agWhite};
        }
        ${IconSymbol} {
          background-color: ${(props) => props.theme.agWhite};
        }
      }
    }
  }
  @media all and (min-height: 400px) {
    min-height: 100px;
  }
`;
FeatureListWrapper.displayName = "FeatureListWrapper";

export const FeatureSearchWrapper = styled.div`
  margin-top: 0.2em;
`;
FeatureSearchWrapper.displayName = "FeatureSearchWrapper";

export const PackageViewMultiFeatureDetailsContentWrap = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
`;
PackageViewMultiFeatureDetailsContentWrap.displayName =
  "PackageViewMultiFeatureDetailsContentWrap";

export const BottomButtonBarWrapper = styled.div`
  width: ${(props) => props.widthSize};
`;
BottomButtonBarWrapper.displayName = "BottomButtonBarWrapper";

export const PackageViewContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
  ${FeatureListWrapper} {
    height: 100%;
  }
  &:nth-child(2) {
    margin-top: 1em;
    ${PackageSubHeadingContainer} + ${FeatureDetailsFormItem} {
      padding: 0;
    }
  }
  ${BottomWrapper} {
    flex-grow: 0;
  }
  > ${PackageDetailsViewContainer} {
    height: 100%;
    flex-shrink: 1;
  }
  @media all and (min-width: 600px) {
    flex: ${(props) => `1 1 ${props.widthSize || "0"}`};
    &:not(:nth-child(1)) {
      padding-left: 1em;
      padding-top: ${(props) => (props.padTop ? "0.7em" : 0)};
      margin-top: 0;
      ${PackageSubHeadingContainer} {
        + ${FeatureDetailsFormItem} {
          width: 100%;
        }
        ${FeatureDetailsFormItem} {
          width: 100%;
        }
      }
    }
    &:nth-child(1) {
      width: ${(props) => (props.widthSize ? props.widthSize : "")};
    }
  }
`;
PackageViewContentContainer.displayName = "PackageViewContentContainer";

export const FeatureListContainer = styled.ul`
  display: flex;
  opacity: 1;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  animation-delay: 0.8s;
  animation: fadeIn 0.3s ease-out;
  transition: all 0.3s ease;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
`;
FeatureListContainer.displayName = "FeatureListContainer";

export const SearchInputWrapper = styled.div.attrs({
  className: "SearchInputWrapper"
})``;
SearchInputWrapper.displayName = "SearchInputWrapper";

export const FeatureSearchContainer = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  ${SearchInputWrapper} {
    margin-right: 0.25rem;
    flex-grow: 1;
  }
`;
FeatureSearchContainer.displayName = "FeatureSearchContainer";

export const FeatureSearchButtonsWrap = styled.div`
  display: flex;
  margin-top: 0.25rem;
  height: 2.3em;
`;
FeatureSearchButtonsWrap.displayName = "FeatureSearchButtonsWrap";

export const FeatureDetailsWrapper = styled.div`
  overflow-y: auto;
  flex-grow: 99;
  ${PackageHeadingStyle} + & {
    margin-top: 1rem;
  }
  & > ${Heading5} {
    margin-top: 1rem;
  }
  ${FeatureDetailsItem} {
    padding: 0.5em 0.5em 0.2em 0.5em;
  }
`;
FeatureDetailsWrapper.displayName = "FeatureDetailsWrapper";

export const FeatureDetailsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  opacity: 1;
  animation-delay: 0.8s;
  animation: fadeIn 0.3s ease-out;
  transition: all 0.3s ease;
`;
FeatureDetailsContainer.displayName = "FeatureDetailsContainer";

export const FeatureDetailsForm = styled.form`
  display: flex;
  flex-direction: column;
  opacity: 1;
  animation-delay: 0.8s;
  animation: fadeIn 0.3s ease-out;
  transition: all 0.3s ease;
`;
FeatureDetailsForm.displayName = "FeatureDetailsForm";

export const DateFilterWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  ${Label} {
    margin-bottom: 0.25rem;
    font-weight: 600;
  }
  ${InputGroup} {
    width: 100%;
  }
`;
DateFilterWrapper.displayName = "DateFilterWrapper";

export const DateFilterResetWrapper = styled.div`
  width: 32px;
`;
DateFilterResetWrapper.displayName = "DateFilterResetWrapper";

export const SearchInputContainer = styled.div.attrs({
  className: "SearchInputContainer"
})`
  opacity: ${(props) => (props.appearsdisabled ? "0.5" : "1")};
  position: relative;
  height: 2.5em;
  width: 100%;
  max-width: 100%;
  margin: 0.25rem 0 0 0;
  padding: 0 0.5em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.fontSizeSmall};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  color: ${(props) => props.theme.agOffBlack};
  vertical-align: baseline;
  outline: none;
  outline-offset: 0;
  background-color: ${(props) => props.theme.agWhite};
  border: 1px solid ${(props) => props.theme.agLighterGray};
  border-radius: 0.15rem;
  -webkit-transition: border-color 150ms linear;
  transition: border-color 150ms linear;
  box-shadow: inset 0 1px 2px "rgba(0, 0,0 , 0.075)";

  &:focus-within {
    border-color: ${(props) => props.theme.agDarkBlue};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 5px rgba(81, 167, 232, 0.5);
  }

  & > ${Input} {
    &[type="text"] {
      height: 100%;
      padding: 0;
      margin: 0 0 0 2em;
      background-color: transparent;
      border: none;
      border-radius: 0;
      transition: none;
      box-shadow: none;
      width: ${(props) => (props.isBlank ? "100%" : "calc(100% - 4em)")};
    }

    &[type="text"]:focus {
      background-color: transparent;
      border: none;
      border-radius: 0;
      transition: none;
      box-shadow: none;
    }
  }

  & > ${IconBackground} {
    position: absolute;
    left: 2px;
    top: 3px;
  }

  & ${Button} {
    border: none;
    position: absolute;
    padding: 0px;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      background-color: transparent;
    }
  }
`;
SearchInputContainer.displayName = "SearchInputContainer";

export const SearchAreaContainer = styled.div`
  position: relative;
  height: 2.25rem;
  width: 100%;
  max-width: 100%;
  margin: 0.25rem 0 0 0;
  font-size: ${(props) => props.theme.fontSizeBase};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  display: flex;
  align-items: center;

  & p {
    margin: 0;
    font-size: ${(props) => props.theme.fontSizeBase};

    & div {
      display: inline-block;
    }
  }

  & > ${IconBackground} {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  & ${Button} {
    border: none;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & span {
      font-size: ${(props) => props.theme.fontSizeBase};
    }

    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.agDarkBlue};
    }
  }
`;
SearchAreaContainer.displayName = "SearchAreaContainer";

export const PluginToolSelectorWrapper = styled.div`
  ${Heading6} + & {
    margin-left: 1rem;
  }
`;
PluginToolSelectorWrapper.displayName = "PluginToolSelectorWrapper";

export const PluginToolSelectorContainer = styled.div`
  margin-top: ${(props) => {
    if (!props.hasContent) return 0;
    else return "1em";
  }};
  padding: ${(props) => {
    if (!props.showBackground) return 0;
    else return "1em";
  }};
  background-color: ${(props) =>
    props.showBackground ? props.theme.agLightestGray : "transparent"};
  border-radius: ${(props) => props.theme.baseBorderRadiusStandard};

  h3 {
    font-size: ${(props) => props.theme.fontSizeOne};
    font-weight: 400;
  }

  p {
    font-size: ${(props) => props.theme.fontSizeBase};
  }

  ${PackageBottomPluginsContainer} & {
    margin-top: 0;
    padding: 0.5em;
  }
  ${WidgetPanelContainer} & {
    margin-top: 0;
    padding: 0;
    background-color: transparent;
  }
`;
PluginToolSelectorContainer.displayName = "PluginToolSelectorContainer";

export const PluginToolBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: 0.5em;
  }
  & ${Button} {
    width: 100%;
    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
      cursor: initial;
    }
  }

  ${PackageBottomPluginsContainer} & {
    margin-bottom: 0rem;
  }
  @media all and (max-width: 420px) {
    ${WidgetPanelContainer} & {
      flex-direction: column;
    }
  }
`;
PluginToolBarContainer.displayName = "PluginToolBarContainer";

export const PluginToolBarHelpTextContainer = styled.div`
  padding: 1em;
  background-color: ${(props) => props.theme.agOffWhite};

  > div {
    display: flex;
    flex-direction: row;
    h3 {
      font-size: ${(props) => props.theme.fontSizeSmall};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
      margin-left: 0.2em;
    }
  }
  p {
    margin: 0;
  }
`;
PluginToolBarHelpTextContainer.displayName = "PluginToolBarHelpTextContainer";

export const DropzoneWrapper = styled.div`
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px dashed ${(props) => props.theme.agLighterGray};
`;
DropzoneWrapper.displayName = "DropzoneWrapper";

export const DropzoneContainer = styled.div`
  padding: 4rem 3rem;
  background-color: ${(props) => props.theme.agOffWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    text-align: center;
    text-shadow: 1px 1px 0px #fff;
    font-weight: 400;
    color: ${(props) => props.theme.agDarkestGray};
    font-size: ${(props) => props.theme.fontSizeBase};
  }
`;
DropzoneContainer.displayName = "DropzoneContainer";

export const AttachmentContainer = styled.div`
  padding: 0.5em 0em 0.2em 0.5em;
  & ${DropzoneWrapper} {
    width: auto;
  }
`;
AttachmentContainer.displayName = "AttachmentContainer";

export const AttachmentList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;
AttachmentList.displayName = "AttachmentList";

export const AttachmentListHeader = styled.h3`
  font-size: 1em;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
`;
AttachmentListHeader.displayName = "AttachmentListHeader";

export const AttachmentListItem = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;
AttachmentListItem.displayName = "AttachmentListItem";

export const AttachmentListItemButton = styled(Button)`
  padding: 0.5em;
  height: auto;
  margin-left: 0.5em;
`;
AttachmentListItemButton.displayName = "AttachmentListItemButton";

export const AttachmentListItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  word-break: break-word;
  gap: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
AttachmentListItemLink.displayName = "AttachmentListItemLink";

export const AttachmentListItemImageWrap = styled.div`
  width: 2em;
  height: 2em;
  border-radius: 50px;
  overflow: hidden;
  border: 2px solid #4f57a6;
`;
AttachmentListItemImageWrap.displayName = "AttachmentListItemImageWrap";

export const AttachmentListItemImage = styled.img`
  width: 100%;
  object-fit: cover;
  min-width: 2em;
  min-height: 2em;
  height: auto;
`;
AttachmentListItemImage.displayName = "AttachmentListItemImage";

export const AttachmentListItemText = styled.p`
  width: calc(100% - 4em);
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;
AttachmentListItemText.displayName = "AttachmentListItemText";

export const AttributeTextContainer = styled.div`
  background-color: ${(props) => props.theme.agTransparentWhite};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;

  & p {
    text-align: center;
    text-shadow: 1px 1px 0px #fff;
    font-weight: 200;
    color: ${(props) => props.theme.agDarkestGray};
    font-size: ${(props) => props.theme.fontSizeExtraSmall};
    margin: 1px 1px 1px 10px;
    line-height: ${(props) => props.theme.lineHeightSmallest};
    @media all and (max-width: 1100px) {
      padding-left: ${(props) => (props.workflowClosed ? "60px" : "0")};
    }
    @media all and (min-width: 900px) and (min-height: 800px) {
      font-size: ${(props) => props.theme.fontSizeSmaller};
      line-height: ${(props) => props.theme.lineHeightSmaller};
    }
  }
`;
AttributeTextContainer.displayName = "AttributeTextContainer";

export const MultiListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
`;
MultiListContainer.displayName = "MultiListContainer";

export const ContentContainerColumn = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  z-index: 100;
`;

export const AnnotationsTextAreaWrapper = styled.div`
  position: fixed;
  right: 1em;
  bottom: 4em;
  background-color: white;
  padding: 1em;
  width: 300px;
`;
AnnotationsTextAreaWrapper.displayName = "AnnotationsTextAreaWrapper";

export const CropEstimatesStatsWrapper = styled.div`
  display: flex;
`;
CropEstimatesStatsWrapper.displayName = "CropEstimatesStatsWrapper";

export const CropEstimatesStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;
CropEstimatesStatsContainer.displayName = "CropEstimatesStatsContainer";

export const CropEstimatesStatsNumber = styled.p`
  color: ${(props) => props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-family: ${(props) => props.theme.baseFont};
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;
CropEstimatesStatsNumber.displayName = "CropEstimatesStatsNumber";

export const CropEstimatesStatsTitle = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.agBlack};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => props.theme.fontSizeSmall};
  padding: 0.5rem;
`;
CropEstimatesStatsTitle.displayName = "CropEstimatesStatsTitle";

export const CropEstimatesTargetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;
CropEstimatesTargetsWrapper.displayName = "CropEstimatesTargetsWrapper";

export const CropEstimatesTargetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
`;
CropEstimatesTargetsContainer.displayName = "CropEstimatesTargetsContainer";

export const CropEstimatesTargetsNumber = styled.p`
  color: ${(props) => props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-family: ${(props) => props.theme.baseFont};
  font-size: 3rem;
  margin-bottom: 1rem;
`;
CropEstimatesTargetsNumber.displayName = "CropEstimatesTargetsNumber";

export const CropEstimatesTargetsTitle = styled.p`
  text-align: center;
  color: ${(props) => props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => props.theme.fontSizeSmall};
`;
CropEstimatesTargetsTitle.displayName = "CropEstimatesTargetsTitle";

export const CropEstimatesGraphHeader = styled.p`
  color: ${(props) => props.theme.agBlack};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => props.theme.fontSizeOne};
  padding-left: 2rem;
  margin-bottom: -1.5rem;
  margin-top: 1.5rem;
  text-align: center;
`;
CropEstimatesGraphHeader.displayName = "CropEstimatesGraphHeader";

export const CropEstimatesDensitiesWrapper = styled.div`
  display: flex;
  padding: 0 2rem;
`;
CropEstimatesDensitiesWrapper.displayName = "CropEstimatesDensitiesWrapper";

export const CropEstimatesDensitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;
CropEstimatesDensitiesContainer.displayName = "CropEstimatesDensitiesContainer";

export const CropEstimatesDensitiesNumber = styled.p`
  color: ${(props) => props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-family: ${(props) => props.theme.baseFont};
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;
CropEstimatesDensitiesNumber.displayName = "CropEstimatesDensitiesNumber";

export const CropEstimatesDensitiesTitle = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => props.theme.fontSizeSmall};
  padding: 0 1rem;
`;
CropEstimatesDensitiesTitle.displayName = "CropEstimatesDensitiesTitle";
